
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import { Box, Button, Grid2 } from "@mui/material";

import "./style/controlProp.css";

import icon from "../../assets/logo_prop.png";
import logo from "../../assets/controlprop.png";
import vignette from "../../assets/ORGAprop_bonhomme.png";

import { FOCUS_VIEW_SELECTION_ENTRY, SCREEN_SIZE_DESKTOP, SECRET_KEY } from "../../models/consts";
import { CONTROL_PROP_ALLOWED_PATHS, CONTROL_PROP_DESKTOP_URL, CONTROL_PROP_GRILLE_URL, CONTROL_PROP_OBJECTIFS_URL, CONTROL_PROP_PLANS_ACTIONS_URL, LOGIN_URL, PROFIL_URL } from "../../models/consts/aplliUrls";
import { STORE_PARAM_BACK_PAGE, STORE_PARAM_CIBLE } from "../../models/consts/store";
import { MbrType, initialMbr } from "../../models/mbr";
import { initialMapSelectionType, MapSelectionType } from "../../models/mapSelection";
import makeMbr from "../../helpers/make-mbr";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import TestConect from "../../helpers/test-conect";
import { EntryType } from "../../models/entry";
import getScreenSize from "../../helpers/getScreenSize";
import StatComponent from "../../components/statistiques/statistiques";
import ObjectifsComponent from "../../components/objectifs";
import { useNavigation } from "../../components/navigationContext";

const ControlProp: FunctionComponent = () => {
  useConditionalRedirect(CONTROL_PROP_ALLOWED_PATHS);

  const [mbr, setMbr] = useState<MbrType>({ ...initialMbr });
  const [mapSelection, setMapSelection] = useState<MapSelectionType>({ ...initialMapSelectionType });
  const [showStart, setShowStart] = useState(true);
  const [showProfil, setShowProfil] = useState(true);
  const [showObjectifs, setShowObjectifs] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [showSelectionEntry, setShowSelectionEntry] = useState(false);
  const [showGrille, setShowGrille] = useState(false);
  const [focusTo, setFocusTo] = useState(FOCUS_VIEW_SELECTION_ENTRY);
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
    if( isMounted ) {
      if( mapSelection.entry > 0 ) {
        setShowStart(false);
        setShowGrille(true);
      } else {
        setShowGrille(false);
        setShowStart(true);
      }
    }
// eslint-disable-next-line
  }, [mapSelection]);
  useEffect(() => {
    try {
      let isMounted = true;
            
      const testMbr = async () => {
        const user = await makeMbr();

        const response = await TestConect(user.pseudo, user.mdp, user.mac);

        if( isMounted ) {
          if( response === false ) {
            history.push(LOGIN_URL);
          } else {
            setMbr(prevMbr => ({
              ...prevMbr,
              ...user
            }));

            fetchData();
          }
        }
      };
            
      testMbr();

      return () => {
        isMounted = false;
      }
    } catch (error) {
      console.error('Error', error);
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handelCallFocus = ( v: string ) => {
    setFocusTo(v);
  };

  const handelStartItemClicked = ( view: string ) => {
    switch( view ) {
      case 'profil': openProfil(); break;
      case 'objectifs': openObjectifs(); break;
      case 'plans': openPlansActions(); break;
      case 'start': openGrille(); break;
    }
  };

  const handelResidenceSelected = ( entry: EntryType ) => {

  };

  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

    if( encryptedDataCible !== null ) {
      const decryptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));

      var newSelection = { ...initialMapSelectionType };

      newSelection.agc = ( decryptedDataCible.agc === null ) ? 0 : decryptedDataCible.agc;
      newSelection.grp = ( decryptedDataCible.grp === null ) ? 0 : decryptedDataCible.grp;
      newSelection.rsd = ( decryptedDataCible.rsd === null ) ? 0 : decryptedDataCible.rsd;
      newSelection.entry = ( decryptedDataCible.entry === null ) ? 0 : decryptedDataCible.entry;

			setMapSelection(newSelection);

      if( ( decryptedDataCible.entry !== null ) && ( decryptedDataCible.entry > 0 ) ) {
        openGrille();
      } else {
        openStart();
      }

      setIsMounted(true);
    }
  };

  const openStart = () => {
    if( screenSize === SCREEN_SIZE_DESKTOP ) {
      setShowProfil(false);
      setShowObjectifs(false);
      setShowPlans(false);
      setShowSelectionEntry(false);
      setShowGrille(false);
      setShowStart(true);
    } else {
      setShowProfil(false);
      setShowObjectifs(false);
      setShowPlans(false);
      setShowSelectionEntry(false);
      setShowGrille(false);
      setShowStart(true);
    }
  };
  const openProfil = () => {
    if( screenSize === SCREEN_SIZE_DESKTOP ) {
      setShowStart(false);
      setShowObjectifs(false);
      setShowPlans(false);
      setShowSelectionEntry(false);
      setShowGrille(false);
      setShowProfil(true);
    } else {
      localStorage.setItem(STORE_PARAM_BACK_PAGE, CONTROL_PROP_DESKTOP_URL);

      history.push(PROFIL_URL);
    }
  };
  const openObjectifs = () => {
    if( screenSize === SCREEN_SIZE_DESKTOP ) {
      setShowStart(false);
      setShowProfil(false);
      setShowPlans(false);
      setShowSelectionEntry(false);
      setShowGrille(false);
      setShowObjectifs(true);
    } else {
      history.push(CONTROL_PROP_OBJECTIFS_URL);
    }
  };
  const openPlansActions = () => {
    if( screenSize === SCREEN_SIZE_DESKTOP ) {
      setShowStart(false);
      setShowObjectifs(false);
      setShowProfil(false);
      setShowSelectionEntry(false);
      setShowGrille(false);
      setShowPlans(true);
    } else {
      history.push(CONTROL_PROP_PLANS_ACTIONS_URL);
    }
  };
  const openGrille = () => {
    if( screenSize === SCREEN_SIZE_DESKTOP ) {
      setShowStart(false);
      setShowProfil(false);
      setShowObjectifs(false);
      setShowPlans(false);
      setShowSelectionEntry(false);
      setShowGrille(true);
    } else {
      history.push(CONTROL_PROP_GRILLE_URL);
    }
  };

	return (
    <Box className="control-prop-screen">
      <StatComponent
        mbr={ mbr }
        mapSelection={ mapSelection }
        focusTo={ focusTo }
        callFocus={ handelCallFocus }
      >
        { ( showStart ) && ViewStart(mbr, screenSize, isPortrait, handelStartItemClicked) }
        { ( showObjectifs && ( screenSize === SCREEN_SIZE_DESKTOP ) ) && ViewObjectifs(mbr, screenSize, isPortrait, handelResidenceSelected) }
      </StatComponent>
    </Box>
  );
};

const ViewVignette = (
  mbrView: MbrType,
  isPortrait: boolean,
  onClick: ( view: string ) => void,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-evenly' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
      onClick={ e => onClick('profil') }
    >
      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img src={ vignette } alt="vignette" className="vignette"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={4}>
        { `Bonjour, ${ mbrView.txt }` }
      </Grid2>
    </Grid2>
  );
};
const ViewFooter = (
  onClickBtn: ( view: string ) => void
) => {
  return (
    <Grid2 className="field-footer"
      container
      component="div"
      direction="column"
      alignItems="center"
    >
      <Grid2 component="div" size={12} sx={{ textAlign: 'center', marginTop: 1 }}>
        <Button variant="contained" onClick={ e => onClickBtn('start') }>Démarrer un contrôle</Button>
      </Grid2>

      <Grid2 component="div" size={6} sx={{ marginTop: 2, marginBottom: 2 }}>
        <img src={ logo } alt="logo" className="logo"/>
      </Grid2>
    </Grid2>
  );
};
const ViewStart = (
  mbr: MbrType,
  screenSize: string,
  isPortrait: boolean,
  onItemClicked: ( view: string ) => void,
) => {
  if( isPortrait ) {
    return (
      <Grid2 className={ `view-start portrait-view ${ screenSize }-size` }
        container
        component="div"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid2 className="field-button-profil"
          component="div"
          size={12}
        >
          { ViewVignette(mbr, isPortrait, onItemClicked) }
        </Grid2>

        <Grid2 className="field-button-objectifs"
          component="div"
          size={10}
          onClick={ e => onItemClicked('objectifs') }
        >
          <StatComponent.MiniObjectifsProp/>
        </Grid2>

        <Grid2 className="field-button-plans-actions"
          component="div"
          size={10}
          onClick={ e => onItemClicked('plans') }
        >
          <StatComponent.MiniPlansActions/>
        </Grid2>

        <Grid2 className="field-button-start"
          component="div"
          size={12}
        >
          { ViewFooter(onItemClicked) }
        </Grid2>
      </Grid2>
    );
  } else {
    return (
      <Grid2 className={ `view-start landscape-view ${ screenSize }-size` }
        container
        component="div"
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid2 component="div" size={6}>
          <Grid2
            container
            component="div"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: '100vh',
            }}
          >
            <Grid2 className="field-button-profil"
              component="div"
              size={12}
              sx={{
                marginTop: '20px',
              }}
            >
              { ViewVignette(mbr, isPortrait, onItemClicked) }
            </Grid2>

            <Grid2 className="field-button-start"
              component="div"
              size={12}
            >
              { ViewFooter(onItemClicked) }
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 component="div" size={6}>
          <Grid2
            container
            component="div"
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              height: '100vh',
            }}
          >
            <Grid2 className="field-button-objectifs"
              component="div"
              size={10}
              onClick={ e => onItemClicked('objectifs') }
            >
              <StatComponent.MiniObjectifsProp/>
            </Grid2>

            <Grid2 className="field-button-plans-actions"
              component="div"
              size={10}
              onClick={ e => onItemClicked('plans') }
            >
              <StatComponent.MiniPlansActions/>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    );
  }
};
const ViewObjectifs = (
  mbr: MbrType,
  screenSize: string,
  isPortrait: boolean,
  onResidenceSelected: ( entry: EntryType ) => void,
) => {
  if( isPortrait ) {
    return (
      <Grid2 className={ `view-objectifs portrait-view ${ screenSize }-size` }
        container
        component="div"
        direction="column"
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid2 className="field-icon"
          component="div"
          size={2}
          offset={10}
          sx={{
            padding: '20px 20px 0 0',
          }}
        >
          <img src={ icon } alt="M" className="icon"/>
        </Grid2>

        <Grid2 className="field-stat"
          component="div"
          size={10}
          sx={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <StatComponent.MiniObjectifsProp/>
        </Grid2>

        <Grid2 className="field-search" component="div" size={10} sx={{ height: 'calc(100% - 200px)', overflow: 'hidden', }}>
          <ObjectifsComponent
            mbr={ mbr }
            onResidenceSelected={ onResidenceSelected }
          />
        </Grid2>
      </Grid2>
    );
  } else {
    return (
      <Grid2 className={ `view-objectifs landscape-view ${ screenSize }-size` }
        container
        component="div"
      >
        <Grid2 component="div" size={6} sx={{ height: '100%', }}>
          <Grid2
            container
            component="div"
            direction="column"
            justifyContent="space-evenly"
          >
            <Grid2 className="field-icon"
              component="div"
              size={2}
              offset={1}
              sx={{
                padding: '20px 20px 0 0',
              }}
            >
              <img src={ icon } alt="M" className="icon"/>
            </Grid2>

            <Grid2 className="field-stat"
              component="div"
              size={10}
              offset={1}
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <StatComponent.MiniObjectifsProp/>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 className="field-search"
          component="div"
          size={6}
          sx={{
            height: '100%',
          }}
        >
          <ObjectifsComponent
            mbr={ mbr }
            onResidenceSelected={ onResidenceSelected }
          />
        </Grid2>
      </Grid2>
    );
  }
};

export default ControlProp;
