
/* PROD */

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";

interface TextareaComponentProps {
	name?: string;
	label?: string;
	value?: string;
	disabled?: boolean;
	onComponentChange?: ( val: string ) => void;
};

const TextareaComponent: FunctionComponent<TextareaComponentProps> = ( {
	name,
	label,
	value,
	disabled,
	onComponentChange,
}) => {
	const [valueComponent, setValueComponent] = useState('');

	useEffect(() => {
		if( value !== undefined ) {
			setValueComponent(value);
		}
// eslint-disable-next-line
	}, [value]);

	const handelValueChanged = ( event: React.ChangeEvent<HTMLInputElement> ) => {
		setValueComponent(event.target.value);

		if( onComponentChange !== undefined ) {
			onComponentChange(event.target.value);
		}
	};

	return (
		<Box className="textarea-component">
			<TextField
				name={ name }
        label={ label }
        multiline
        rows={4}
        variant="filled"
				value={ valueComponent }
				disabled={ disabled }
				onChange={ handelValueChanged }
				sx={{
					width: '100%',
				}}
      />
		</Box>
	)
};

export default TextareaComponent;
