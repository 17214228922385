
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";

import "./mini.css";

import { StatType } from "../../models/statistiques";
import { STAT_ENCART_TYPE_CONTROLEUR } from "../../models/consts/statistiques";
import { Box, Grid2 } from "@mui/material";

interface MiniStatProps {
  data: StatType | null;
};

type MiniStatType = {
  title: string,
  line1: string,
  line2: string,
  line2Color?: string,
  line3: string,
};
const initialMiniStat: MiniStatType = {
  title: '',
  line1: '',
  line2: '',
  line3: '',
}

const MiniStat: FunctionComponent<MiniStatProps> = ( { data } ) => {
  const [donneeComponent, setDonneeComponent] = useState<MiniStatType>(initialMiniStat);
  const [isDefined, setIsDefined] = useState(false);
  const [isControleur, setIsControleur] = useState(false);

  useEffect(() => {
    if( data !== null ) {
      const newData: MiniStatType = {
        title: data.txt,
        line1: data.nb.toString() + ' / ' + data.tot.toString(),
        line2: data.moyen.toString() + '%',
        line3: data.objectif,
      }

      setDonneeComponent(newData);
      setIsDefined(data.key > 0);
      setIsControleur(( data.type === STAT_ENCART_TYPE_CONTROLEUR ));
    }
// eslint-disable-next-line
  }, [data]);

  return (
    <Box className="mini-stat-component">
      { ( isDefined ) &&
        <Grid2
          container
          direction="column"
          component="div"
        >
          <Grid2 component="div" size={12}>
            { ViewLine('line-nb-ctrl', 'Nb. de contrôle', donneeComponent.line1) }
          </Grid2>

          <Grid2 component="div" size={12}>
            { ViewLine('line-moy-ctrl', 'Note moyenne', donneeComponent.line2) }
          </Grid2>
          
          { ( isControleur ) &&
            <Grid2 component="div" size={12}>
              { ViewLine('line-objectif-ctrl', 'Objectif', donneeComponent.line3) }
            </Grid2> 
          }
        </Grid2>
      }
    </Box>
  );
};

const ViewLine = ( className: string, label: string, value: string, color?: string ) => {
  return (
    <Grid2 className={ `line-component ${ className }` }
      container
      component="div"
    >
      <Grid2 className="label-component"
        component="div"
        size={6}
      >
        { label }
      </Grid2>

      <Grid2 className="value-component"
        component="div"
        size={6}
        sx={{ color: ( color === undefined ) ? 'black' : color }}
      >
        { value }
      </Grid2>
    </Grid2>
  );
};

export default MiniStat;
