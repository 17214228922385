
/*	PROD	*/

import axios from "axios";
import { API_URL_SET_GRILLE_CTRLPROP, API_URL_SET_PLANS_ACTIONS, API_URL_SET_SIGNATURE } from "../models/consts/apiUrls";
import { convertPlanActionsTypeToJson, PlanActionsType } from "../models/ctrlProp";

export async function fetchSetCtrlProp( idMbr: number, mac: string, data: string ) {
  try {
    const url = API_URL_SET_GRILLE_CTRLPROP;
    const params = {
      mbr: idMbr,
      mac: mac,
      val: data,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
export async function fetchSetSignature( idMbr: number, mac: string, ctrl: number, sigs: string[] ) {
  try {
    const url = API_URL_SET_SIGNATURE;
    const params = {
      mbr: idMbr,
      mac: mac,
      val: ctrl,
      sigs: sigs,
    };
    const reponse = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return reponse.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
export async function fetchSetPlanAction( idMbr: number, mac: string, rsd: number, ctrl: number, plan: PlanActionsType ) {
  try {
    const url = API_URL_SET_PLANS_ACTIONS;
    const params = {
      mbr: idMbr,
      mac: mac,
      rsd: rsd,
      val: ctrl,
      plan: JSON.stringify(convertPlanActionsTypeToJson(plan)),
    };
    const reponse = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return reponse.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
}
