
/* PROD */

import React, { FunctionComponent, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface CheckboxComponentProps {
	name?: string,
	label?: string,
	checked: boolean,
	onComponentChange: ( isChecked: boolean ) => void;
};

const CheckboxComponent: FunctionComponent<CheckboxComponentProps> = ( {
	name,
	label,
	checked,
	onComponentChange,
}) => {
	const [componentChecked, setComponentChecked] = useState(true);

	useEffect(() => {
		setComponentChecked(checked);
// eslint-disable-next-line
	}, [checked]);

	const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    setComponentChecked(event.target.checked);
		onComponentChange(event.target.checked)
  };

	return (
		<FormGroup className="checkbox-component">
			<FormControlLabel
				control={
					<Checkbox
						name={ name }
						checked={ componentChecked }
						onChange={ handleChange }
						inputProps={{ 'aria-label': 'controlled' }}
						sx={{
							color: '#aaa',
							'&.Mui-checked': {
								color: '#45b40b',
							},
						}}
					/>
				}
				label={ label }
			/>
		</FormGroup>
	)
};

export default CheckboxComponent;
