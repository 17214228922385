
/*	PROD	*/

import axios from "axios";
import { API_URL_GET_LISTS } from "../models/consts/apiUrls";

export default async function fetchGetList( i: number, m: string, t: string, v: string ) {
  try {
    const url = API_URL_GET_LISTS;
    const params = {
      mbr: i,
      mac: m,
      type: t,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
}
