
/*	PROD	*/

import axios from "axios";
import { API_URL_GET_FICHE_RESIDENCE, API_URL_GET_GRILLE_CTRLPROP, API_URL_GET_RESIDENCE_PLAN_ACTIONS } from "../models/consts/apiUrls";

export async function fetchGetEntry( i: number, m: string, v: string ) {
  try {
    const url = API_URL_GET_FICHE_RESIDENCE;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
export async function fetchGetGrille( i: number, m: string, v: number ) {
  try {
    const url = API_URL_GET_GRILLE_CTRLPROP;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
}
export async function fetchGetRsdPlan( i: number, m: string, v: number ) {
  try{
    const url = API_URL_GET_RESIDENCE_PLAN_ACTIONS;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
}
