
/*	PROD	*/

export const STAT_ENCART_CIBLE_MINI = 'mini';
export const STAT_ENCART_CIBLE_FULL = 'full';

export const STAT_ENCART_TYPE_CONTROLEUR = 'ctrl';
export const STAT_ENCART_TYPE_PLAN_ACTIONS = 'plans';
export const STAT_ENCART_TYPE_AGENCE = 'agc';
export const STAT_ENCART_TYPE_GROUPEMENT = 'grp';
export const STAT_ENCART_TYPE_RESIDENCE = 'rsd';
export const STAT_ENCART_TYPE_ENTRY = 'entry';
