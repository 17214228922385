
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid2, Stack } from "@mui/material";

import "./styles/acceuil.css";

import logoSociete from "../assets/logo_appli.png";
import vignette from "../assets/ORGAprop_bonhomme.png";
import iconOrgaProxi from "../assets/orgaproxi.png";
import iconOrgaContrat from "../assets/orgacontrat.png";
import iconControlProp from "../assets/controlprop.png";
import iconControlSecu from "../assets/controlsecu.png";
import iconControlPsp from "../assets/controlpsp.png";
import iconControlPrest from "../assets/controlprest.png";
import { APPLI_SELECTED_CONTROL_PREST, APPLI_SELECTED_CONTROL_PROP, APPLI_SELECTED_CONTROL_PSP, APPLI_SELECTED_CONTROL_SECU, APPLI_SELECTED_ORGA_CONTRAT, APPLI_SELECTED_ORGA_PROXI, SCREEN_SIZE_DESKTOP } from "../models/consts";
import { ACCEUIL_ALLOWED_PATH, ACCEUIL_URL, CONTROL_PROP_DESKTOP_URL, CONTROL_PROP_MOBILE_URL, LOGIN_URL, PROFIL_URL } from "../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../models/mbr";
import { STORE_PARAM_APPLI_SELECTED, STORE_PARAM_BACK_PAGE } from "../models/consts/store";
import useConditionalRedirect from "../helpers/useConditionalRedirect";
import makeMbr from "../helpers/make-mbr";
import TestConect from "../helpers/test-conect";
import getScreenSize from "../helpers/getScreenSize";
import { useNavigation } from "../components/navigationContext";
import { NOTIFY_VARIANT_WARNING, useNotification } from "../components/notify";

const AcceuilScreen: FunctionComponent = () => {
  useConditionalRedirect(ACCEUIL_ALLOWED_PATH);
  
  const notify = useNotification();

  const [mbr, setMbr] = useState<MbrType>({ ...initialMbr });
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => { /* montage */
		if( !isMounted ) {
			document.title = "Acceuil";

			testMbr();
		}
// eslint-disable-next-line
  }, []);
  useEffect(() => { /* screen size */
    const handleResize = () => {
      setScreenSize(getScreenSize());
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handelSelectAppli = ( val: string | MbrType ) => {
    if( typeof val === 'string' ) {
      let m = '';
      let v = '';

      switch( val ) {
        case APPLI_SELECTED_CONTROL_PROP:
          m = ( screenSize === SCREEN_SIZE_DESKTOP ) ? CONTROL_PROP_DESKTOP_URL : CONTROL_PROP_MOBILE_URL;
          v = APPLI_SELECTED_CONTROL_PROP;
          break;
        case APPLI_SELECTED_ORGA_PROXI: 
        case APPLI_SELECTED_ORGA_CONTRAT:
        case APPLI_SELECTED_CONTROL_SECU:
        case APPLI_SELECTED_CONTROL_PSP:
        case APPLI_SELECTED_CONTROL_PREST:
        default:
          notify("Vous ne pouvez accèder à cette application !", { variant: NOTIFY_VARIANT_WARNING });
      }

      if( ( m !== '' ) && ( v !== '' ) ) {
        localStorage.setItem(STORE_PARAM_APPLI_SELECTED, v);
        history.push(m);
      }
    } else {
      updateMbr(val);
    }
  };
  const handelOpenEdit = () => {
    localStorage.setItem(STORE_PARAM_BACK_PAGE, ACCEUIL_URL);
    
    history.push(PROFIL_URL);
  };

  const updateMbr = ( m: MbrType ) => {
    var newMbr = { ...mbr };

    newMbr.pseudo = m.pseudo;
    newMbr.mail = m.mail;
    newMbr.tel = m.tel;

    if( m.mdp !== '' ) {
      newMbr.mdp = m.mdp;
    }

    setMbr(newMbr);
  };
	const testMbr = async () => {
		try {
			const user = await makeMbr();
			const response = await TestConect(user.pseudo, user.mdp, user.mac);

			if( response === false ) {
				history.push(LOGIN_URL);
			} else {
				setMbr(prevMbr => ({
					...prevMbr,
					...user
				}));
				setIsMounted(true);
			}
    } catch (error) {
      console.error(error);
    }
	};

  return (
    <Box className="acceuil-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `portrait-view ${ screenSize }-size` }
          container
          component="div"
          direction="column"
          justifyContent="space-between"
          sx={{
            height: '100%',
          }}
        >
          <Grid2 component="div" size={12}>
            { ViewVignettes(mbr, isPortrait, handelOpenEdit) }
          </Grid2>
          
          <Grid2 component="div" size={12}>
            { ViewPrincipale(mbr, isPortrait, handelSelectAppli) }
          </Grid2>

          <Grid2 component="div" flexGrow={ 0 } size={12}></Grid2>
        </Grid2>
      }
      { ( !isPortrait ) &&
        <Grid2 className={ `landscape-view ${ screenSize }-size` }
          container
          component="div"
          justifyContent="center"
          alignItems="center"
        >
          <Grid2
            component="div"
            size={{ xs: 6, xl: 4 }}
            sx={ ( screenSize === SCREEN_SIZE_DESKTOP ) ? { height: '60vh', marginTop: '10%', fontSize: '20pt' } : { height: '100vh' } }
          >
            { ViewVignettes(mbr, isPortrait, handelOpenEdit) }
          </Grid2>

          <Grid2
            component="div"
            size={{ xs: 6, xl: 4 }}
            sx={ ( screenSize === SCREEN_SIZE_DESKTOP ) ? { height: '60vh', marginTop: '10%', fontSize: '20pt' } : { height: '100vh' } }
          >
            { ViewPrincipale(mbr, isPortrait, handelSelectAppli) }
          </Grid2>
        </Grid2>
      }
    </Box>
  );
};

const ViewVignettes = (
  mbr: MbrType,
  isPortrait: boolean,
  onClick: () => void,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-evenly' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
      onClick={ onClick }
    >
      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img className="logo-appli" src={ logoSociete } alt="logo"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img className="vignette" src={ vignette } alt="logo"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={12} sx={{ textAlign: 'center', }}>
        { ( ( mbr !== undefined ) && ( mbr.txt !== undefined ) ) ? `Bonjour, ${ mbr.txt }` : '' }
      </Grid2>
    </Grid2>
  );
};
const ViewPrincipale = (
  mbr: MbrType,
  isPortrait: boolean,
  onSelectAppli: (
    val: string | MbrType,
  ) => void,
) => {
  return (
    <Grid2 className="field-principal"
      container
      component="div"
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%', } }
    >
      <Grid2 component="div" size={10}>
        <Box className="field-title" sx={{ fontWeight: 'bold', marginBottom: '5%' }}>
          <i>Mes applications</i>
        </Box>
      </Grid2>

      <Grid2 component="div" size={12}>
        { ( isPortrait ) &&
          <Stack
            spacing={2}
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 300px)',
              width: '60%',
              margin: 'auto',
            }}
          >
            { ViewButton(APPLI_SELECTED_ORGA_PROXI, mbr, true, onSelectAppli) }
            { ViewButton(APPLI_SELECTED_ORGA_CONTRAT, mbr, true, onSelectAppli) }
            { ViewButton(APPLI_SELECTED_CONTROL_PROP, mbr, true, onSelectAppli) }
            { ViewButton(APPLI_SELECTED_CONTROL_SECU, mbr, true, onSelectAppli) }
            { ViewButton(APPLI_SELECTED_CONTROL_PSP, mbr, true, onSelectAppli) }
            { ViewButton(APPLI_SELECTED_CONTROL_PREST, mbr, true, onSelectAppli) }
          </Stack>
        }
        { ( !isPortrait ) &&
          <Grid2 className="field-list-applis"
            container
            component="div"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid2 className="container-btn-applis" component="div" size={4}>
              <Stack spacing={2}>
                { ViewButton(APPLI_SELECTED_ORGA_PROXI, mbr, false, onSelectAppli) }
                { ViewButton(APPLI_SELECTED_ORGA_CONTRAT, mbr, false, onSelectAppli) }
                { ViewButton(APPLI_SELECTED_CONTROL_PREST, mbr, false, onSelectAppli) }
              </Stack>
            </Grid2>

            <Grid2 component="div" flexGrow={0} size={1}></Grid2>

            <Grid2 className="container-btn-applis" component="div" size={4}>
              <Stack spacing={2}>
                { ViewButton(APPLI_SELECTED_CONTROL_PROP, mbr, false, onSelectAppli) }
                { ViewButton(APPLI_SELECTED_CONTROL_SECU, mbr, false, onSelectAppli) }
                { ViewButton(APPLI_SELECTED_CONTROL_PSP, mbr, false, onSelectAppli) }
              </Stack>
            </Grid2>

            <Grid2 component="div" flexGrow={ 0 } size={1}></Grid2>
          </Grid2>
        }
      </Grid2>

      <Grid2 component="div" flexGrow={ 0 } size={12}></Grid2>
    </Grid2>
  );
};
const ViewButton = (
  type: string,
  mbr: MbrType,
  isPortrait: boolean,
  callBack: ( val: string ) => void
) => {
  var name = '';
  var alt = '';
  var icon = iconOrgaProxi;
  var b = false;

  switch( type ) {
    case APPLI_SELECTED_ORGA_PROXI:
      name = 'proxi';
      alt = 'ORGAproxi';
      icon = iconOrgaProxi;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.proxi );
      break;
    case APPLI_SELECTED_ORGA_CONTRAT:
      name = 'contrat';
      alt = 'ORGAcontrat';
      icon = iconOrgaContrat;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.contrat );
      break;
    case APPLI_SELECTED_CONTROL_PROP:
      name = 'prop';
      alt = 'CONTROLprop';
      icon = iconControlProp;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.prop );
      break;
    case APPLI_SELECTED_CONTROL_SECU:
      name = 'secu';
      alt = 'CONTROLsecu';
      icon = iconControlSecu;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.secu );
      break;
    case APPLI_SELECTED_CONTROL_PSP:
      name = 'psp';
      alt = 'CONTROLpsp';
      icon = iconControlPsp;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.psp );
      break;
    case APPLI_SELECTED_CONTROL_PREST:
      name = 'prest';
      alt = 'CONTROLprest';
      icon = iconControlPrest;
      b = ( ( mbr.abonnements !== undefined) && mbr.abonnements.prest );
      break;
  }

  return (
    <Box className={ `field-${ name }${ ( b ) ? '' : ' no-abonnement' }` }
      onClick={ e => callBack(( b ) ? type : 'cant') }
    >
      <img className="btn-appli" src={ icon } alt={ alt }/>
    </Box>
  );
};

export default AcceuilScreen;
