
/*	PROD	*/

import { AssociativeArrayType } from "../helpers/build-associative-array";
import { CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_VERSION_COMPLET } from "./consts/ctrlProp";
import { convertEntryTypeToJson, convertToEntryType, EntryType, initialEntry, ReceveidEntryType } from "./entry";
import { MbrType } from "./mbr";
import { LinePlanActionsStatType } from "./statistiques";

type ReceveidImgCommentPropType = {
  id: number,
  txt: string,
};
type ReceveidCommentPropType = {
  txt: string,
  img: ReceveidImgCommentPropType,
};
export type ImgCommentPropType = {
  id: number,
  txt: string,
};
export type CommentCriterGrillePropType = {
  txt: string,
  img: ImgCommentPropType,
};
const initialImgCommentType:ImgCommentPropType = {
  id: 0,
  txt: '',
};
export const initialCommentProp: CommentCriterGrillePropType = {
  txt: '',
  img: { ...initialImgCommentType },
};
export const convertToImgCommentPropType = ( img: ReceveidImgCommentPropType ): ImgCommentPropType => {
  return {
    id: img.id,
    txt: img.txt,
  };
};
export const converteToCommentPropType = ( comment: ReceveidCommentPropType | undefined ): CommentCriterGrillePropType => {
  return {
    txt: ( comment ) ? comment.txt : '',
    img: ( comment ) ? convertToImgCommentPropType(comment.img) : { id: 0, txt: '' },
  };
};
const converteImgCommentPropTypeToJson = ( img: ImgCommentPropType, forSave?: boolean ) => {
  return {
    id: img.id,
    txt: ( ( forSave !== undefined ) && forSave ) ? null : img.txt,
  };
};
export const convertCommentCriterGrillePropTypeToJson = ( comment: CommentCriterGrillePropType | undefined, forSave?: boolean ) => {
  return {
    txt: ( comment === undefined ) ? '' : comment.txt,
    img: ( comment === undefined ) ? '' : converteImgCommentPropTypeToJson(comment.img, forSave),
  }
};

type ReceivedCriterType = {
  id: number;
  txt?: string;
  coef?: string;
  val?: number;
  comment?: ReceveidCommentPropType;
};
export type CriterGrillePropType = {
  id: number,
  txt?: string,
  coef?: number,
  note?: null | number,
  val?: number | null,
  comment?: CommentCriterGrillePropType,
};
const initialCriter: CriterGrillePropType = {
  id: 0,
  txt: '',
  coef: 1,
};
export const mapToCriterGrillePropType = ( criters: { [key: string | number]: ReceivedCriterType } ): CriterGrillePropType[] => {
  return Object.values(criters).map((criter) => {
    if( ( 'id' in criter ) ) {
      return {
        id: criter.id,
        txt: criter.txt,
        coef: Number(criter.coef),
        val: criter.val,
        comment: converteToCommentPropType(criter.comment),
      };
    } else {
      console.error("Critère invalide détecté");
      throw new Error("Critère invalide détecté");
    }
  });
};
export const convertCriterGrillePropTypeToJson = ( criter: CriterGrillePropType, forSave?: boolean ) => {
  return {
    id: criter.id,
    txt: ( ( forSave !== undefined ) && forSave ) ? null : ( ( criter.txt === undefined ) ? '' : criter.txt ),
    coef: ( ( forSave !== undefined ) && forSave ) ? null : ( ( criter.coef === undefined ) ? 0 : criter.coef ),
    val: ( criter.val === undefined ) ? null : criter.val,
    comment: convertCommentCriterGrillePropTypeToJson(criter.comment, forSave),
  };
};

type ReceivedElementType = {
  id: number;
  txt?: string;
  coef?: string;
  note?: null | number;
  criters: { [key: string]: ReceivedCriterType };
};
export type ElementGrillePropType = {
  id: number,
  txt?: string;
  coef?: number,
  note?: null | number,
  criters: CriterGrillePropType[],
};
const initialElement: ElementGrillePropType = {
  id: 0,
  txt: '',
  coef: 1,
  criters: [],
};
export const mapToElementGrillePropType = ( elements: { [key: string | number]: ReceivedElementType } ): ElementGrillePropType[] => {
  return Object.values(elements).map((element) => {
    if( ( 'id' in element ) && ( 'criters' in element ) ) {
      return {
        id: element.id,
        txt: element.txt,
        coef: Number(element.coef),
        note: element.note,
        criters: mapToCriterGrillePropType(element.criters),
      };
    } else {
      console.error("Élément invalide détecté");
      throw new Error("Élément invalide détecté");
    }
  });
};
export const converteElementGrillePropTypeToJson = ( element: ElementGrillePropType, forSave?: boolean ) => {
  return {
    id: element.id,
    txt: ( ( forSave !== undefined ) && forSave ) ? null : ( ( element.txt === undefined ) ? '' : element.txt ),
    coef: ( ( forSave !== undefined ) && forSave ) ? null : ( ( element.coef === undefined ) ? 0 : element.coef ),
    note: ( element.note === undefined ) ? null : ( ( element.note === null ) ? null : Math.round(element.note) ),
    criters: element.criters.map(( criter ) => {
      return convertCriterGrillePropTypeToJson(criter, forSave);
    }),
  };
};

type ReceivedZoneType = {
  id: number;
  type?: string;
  txt?: string;
  coef?: string;
  note?: null | number;
  elements: { [key: string]: ReceivedElementType };
};
export type ZoneGrillePropType = {
  id: number,
  type?: string,
  txt?: string,
  coef?: number,
  note?: null | number,
  elements: ElementGrillePropType[],
};
const initialZone: ZoneGrillePropType = {
  id: 0,
  txt: '',
  coef: 1,
  elements: [],
};
export const mapToZoneGrillePropType = ( zones: ReceivedGrilleType ): ZoneGrillePropType[] => {
  return Object.values(zones).map(( zone ) => {
    if( ( 'id' in zone ) && ( 'elements' in zone ) ) {
      return {
        id: zone.id,
        type: zone.type,
        txt: zone.txt,
        coef: Number(zone.coef),
        note: zone.note,
        elements: mapToElementGrillePropType(zone.elements),
      };
    } else {
      console.error("Zone invalide détectée");
      throw new Error("Zone invalide détecté");
    }
  });
};
export const convertZoneGrillePropTypeToJson = ( zone: ZoneGrillePropType, forSave?: boolean ) => {
  return {
    id: zone.id,
    type: zone.type,
    txt: ( ( forSave !== undefined ) && forSave ) ? null : ( ( zone.txt === undefined ) ? null : zone.txt ),
    coef: ( ( forSave !== undefined ) && forSave ) ? null : ( ( zone.coef === undefined ) ? 0 : zone.coef ),
    note: ( zone.note === undefined ) ? null : ( ( zone.note === null ) ? null : Math.round(zone.note) ),
    elements: zone.elements.map(( element ) => {
      return converteElementGrillePropTypeToJson(element, forSave);
    }),
  };
};

export type ReceivedGrilleType = {
  [key: string | number]: ReceivedZoneType;
};
export type ReceveidCtrlPropType = {
  id: number,
	entry?: ReceveidEntryType,
  rsd: number,
  time: number,
  date: number,
  config: ReceveidConfigType,
  controleur: number,
  presta: PrestataireCtrlPropType,
  note: number,
  grille: ReceivedGrilleType,
  plan: ReceveidPlanActionsType,
  type: string, // edle, edls, standard, levées
  version: string, // cplt, rnd, spe
  signatures?: string,
	saved?: boolean,
};
export type GrillePropType = {
  rsd?: number,
	entry?: EntryType,
  id?: number,
  time?: number,
  date?: number,
  config?: ConfigPropType,
  presta?: PrestataireCtrlPropType,
  note?: number | null,
  grille: ZoneGrillePropType[],
  plan?: PlanActionsType,
  type?: string, // edle, edls, standard, levées
  version?: string, // cplt, rnd, spe
  signatures?: string[] | null,
	saved?: boolean,
};
export const initialGrilleProp: GrillePropType = {
  grille: [],
};
export const mapToGrillePropType = ( data: ReceivedGrilleType ): GrillePropType => {
  return {
    grille: mapToZoneGrillePropType(data),
  };
};
export const convertToGrillePropType = ( data: ReceveidCtrlPropType, presta: PrestataireCtrlPropType ): GrillePropType => {
  return {
    rsd: data.rsd,
    id: data.id,
    time: data.time,
    date: data.date,
    config: convertToConfigPropType(data.config),
    presta: presta,
    note: data.note,
    grille: ( Object.values(data.grille).length > 0 ) ? mapToZoneGrillePropType(data.grille) : [],
    plan: convertToPlanActionsType(data.plan),
    version: data.version,
    type: data.type,
    signatures: [],
  };
};
export const MakeGrilleCtrlProp = ( structure: AssociativeArrayType, grille: GrillePropType ): GrillePropType => {
  return {
    rsd: grille.rsd,
    id: grille.id,
    time: grille.time,
    date: grille.date,
    config: grille.config,
    presta: grille.presta,
    note: grille.note,
    grille: Object.values(grille.grille).map(( zone ) => {
      return {
        id: zone.id,
        type: zone.type,
        txt: structure[zone.id].txt,
        coef: structure[zone.id].coef,
        note: zone.note,
        elements: zone.elements.map(( element ) => {
          return {
            id: element.id,
            txt: structure[zone.id].data[element.id].txt,
            coef: structure[zone.id].data[element.id].coef,
            note: element.note,
            criters: element.criters.map(( criter ) => {
              return {
                id: criter.id,
                txt: structure[zone.id].data[element.id].data[criter.id].txt,
                coef: structure[zone.id].data[element.id].data[criter.id].coef,
                note: criter.note,
                val: criter.val,
                comment: criter.comment,
              };
            }),
          };
        }),
      };
    }),
    plan: grille.plan,
    type: grille.type,
    version: grille.version,
    signatures: grille.signatures,
  };
};
export const convertGrillePropTypeToJson = ( grille: GrillePropType, mbr: MbrType, forSave?: boolean ) => {
  if( ( grille.rsd === null ) || ( grille.rsd === undefined ) ) {
    return null;
  } else {
    return {
      rsd: grille.rsd,
			entry: ( ( forSave !== undefined ) && forSave ) ? null : ( ( ( grille.entry === undefined ) || ( grille.entry === null ) ) ? null : convertEntryTypeToJson(grille.entry) ),
      id: ( ( grille.id === undefined ) || ( grille.id === null ) ) ? 0 : grille.id,
      time: grille.time,
      date: grille.date,
      config: ( ( grille.config === undefined ) || ( grille.config === null ) ) ? null : convertConfigPropTypeToJson(grille.config),
      controleur: mbr.idAgent,
      presta: ( ( grille.presta === undefined ) || ( grille.presta === null ) ) ? 0 : convertPrestataireCtrlPropTypeToJson(grille.presta),
      note: ( grille.note === undefined ) ? null : ( ( grille.note === null ) ? null : Math.round(grille.note) ),
      grille: Object.values(grille.grille).map(( zone ) => {
        return convertZoneGrillePropTypeToJson(zone, forSave);
      }),
      plan: convertPlanActionsTypeToJson(grille.plan),
      version: ( ( grille.version === undefined ) || ( grille.version === null ) ) ? CONFIG_PROP_VERSION_COMPLET : grille.version,
      type: ( ( grille.type === undefined ) || ( grille.type === null ) ) ? CONFIG_PROP_TYPE_CTRL : grille.type,
      signatures: ( ( grille.signatures === undefined ) || ( grille.signatures === null ) ) ? '' : convertSignatruePropToJson(grille.signatures),
      saved: ( ( forSave !== undefined ) && forSave ) ? null : ( ( ( grille.saved === undefined ) || ( grille.saved === null ) ) ? grille.saved : true ),
    };
  }  
};
export const converteToGrilleTypeFromJson = ( grille: ReceveidCtrlPropType ): GrillePropType => {
  return {
    rsd: grille.rsd,
		entry: ( ( grille.entry ) ? convertToEntryType(grille.entry) : { ...initialEntry } ),
    id: grille.id,
    time: grille.time,
    date: grille.date,
    config: convertToConfigPropType(grille.config),
    presta: convertToPrestatairePropTypeFromJson(grille.presta),
    note: grille.note,
    grille: mapToZoneGrillePropType(grille.grille),
    plan: convertToPlanActionsTypeFromJson(grille.plan),
    type: grille.type,
    version: grille.version,
    signatures: convertToSignaturePropTypeFromJson(grille.signatures),
		saved: ( grille.saved ) ? grille.saved : true,
  };
};

type ReceveidConfigType = {
  visite: boolean,
  meteo: boolean,
  produits: boolean,
  affichage: boolean,
};
export type ConfigPropType = {
  visite: boolean,
  meteo: boolean,
  produits: boolean,
  affichage: boolean,
};
export const initialConfigProp: ConfigPropType = {
  visite: false,
  meteo: false,
  produits: true,
  affichage: true,
};
export const convertToConfigPropType = ( data: ReceveidConfigType ): ConfigPropType => {
  return {
    visite: data.visite,
    meteo: data.meteo,
    produits: data.produits,
    affichage: data.affichage,
  }
};
export const convertConfigPropTypeToJson = ( config: ConfigPropType ) => {
  return {
    visite: config.visite,
    meteo: config.meteo,
    produits: config.produits,
    affichage: config.affichage,
  }
};

type ReceveidPlanActionsType = {
  id: number,
  date?: number,
  echeance?: string,
  fin?: string,
  txt?: string,
};
export type PlanActionsType = {
  id: number,
  date: number,
  echeance: string,
  fin: string,
  txt: string,
};
export const initialPlanActions: PlanActionsType = {
	id: 0,
	date: 0,
	echeance: '',
	fin: '',
	txt: '',
};
export const convertToPlanActionsType = ( plan: ReceveidPlanActionsType ): PlanActionsType => {
  return {
    id: plan.id,
    date: ( plan.date ) ? plan.date : 0,
    echeance: ( plan.echeance ) ? plan.echeance : '',
    fin: ( plan.fin ) ? plan.fin : '',
    txt: ( plan.txt ) ? plan.txt : '',
  };
};
export const convertToPlanActionsTypeFromLine = ( plan: LinePlanActionsStatType ): PlanActionsType => {
  return {
    id: plan.id,
    date: 0,
    echeance: '',
    fin: '',
    txt: '',
  };
};
export const convertPlanActionsTypeToJson = ( plan: PlanActionsType | undefined ) => {
  return {
    id: ( plan === undefined ) ? 0 : plan.id,
    date: ( plan === undefined ) ? null : plan.date,
    echeance: ( plan === undefined ) ? null : plan.echeance,
    fin: ( plan === undefined ) ? null : plan.fin,
    txt: ( plan === undefined ) ? '' : plan.txt,
  }
};
export const convertToPlanActionsTypeFromJson = ( plan: ReceveidPlanActionsType ): PlanActionsType => {
  return {
    id: plan.id,
    date: ( plan.date === undefined ) ? 0 : plan.date,
    echeance: ( plan.echeance === undefined ) ? '' : plan.echeance,
    fin: ( plan.fin === undefined ) ? '' : plan.fin,
    txt: ( plan.txt === undefined ) ? '' : plan.txt,
  };
};

type ReceveidPrestatairePropType = {
  id: number;
  type: string;
};
export type PrestataireCtrlPropType = {
  id: number,
  type: string,
};
export const initialPrestatairePropType: PrestataireCtrlPropType = {
  id: 0,
  type: 'a',
};
export const convertToPrestatairePropType = ( data: ReceveidPrestatairePropType ): PrestataireCtrlPropType => {
  return {
    id: data.id,
    type: data.type,
  }
};
export const convertPrestataireCtrlPropTypeToJson = ( presta: PrestataireCtrlPropType ) => {
  return {
    id: presta.id,
    type: presta.type,
  };
};
const convertToPrestatairePropTypeFromJson = ( presta: ReceveidPrestatairePropType ): PrestataireCtrlPropType => {
  return {
    id: presta.id,
    type: presta.type,
  }
};

const convertSignatruePropToJson = ( data: string[] ): string => {
  return ( ( data.length > 0 ) ? ( data[0] + ';' +  ( ( data.length > 1 ) ? data[1] : '' ) ) : '' );
};
const convertToSignaturePropTypeFromJson = ( data: string | null | undefined ): string[] => {
  return ( ( data === undefined ) || ( data === null  ) || ( data === '' ) ) ? [] : data.split(';');
};

export const makeStructureCtrlProp = ( data: ReceivedZoneType[] ): GrillePropType => {
  return {
    grille: Object.values(data).map(( zone ) => {
      if( ( 'id' in zone ) && ( 'txt' in zone ) && ( 'coef' in zone ) && ( 'elements' in zone ) ) {
        let newZone: ZoneGrillePropType = { ...initialZone };

        newZone.id = zone.id;
        newZone.txt = zone.txt;
        newZone.coef = ( zone.coef ) ? parseInt(zone.coef) : 1;

        newZone.elements = Object.values(zone.elements).map(( element ) => {
          if( ( 'id' in element ) && ( 'txt' in element ) && ( 'coef' in element ) && ( 'criters' in element ) ) {
            let newElement: ElementGrillePropType = { ...initialElement };

            newElement.id = element.id;
            newElement.txt = element.txt;
            newElement.coef = ( element.coef ) ? parseInt(element.coef) : 1;

            newElement.criters = Object.values(element.criters).map(( criter ) => {
              if( ( 'id' in criter ) && ( 'txt' in criter ) && ( 'coef' in criter ) ) {
                let newCriter: CriterGrillePropType = { ...initialCriter };

                newCriter.id = criter.id;
                newCriter.txt = criter.txt;
                newCriter.coef = ( criter.coef ) ? parseInt(criter.coef) : 1;

                return newCriter;
              } else {
                console.error("Critère invalide détectée", criter);
                throw new Error("Critère invalide détecté");
              }
            });

            return newElement;
          } else {
            console.error("Élément invalide détectée", element);
            throw new Error("Élément invalide détecté");
          }
        });

        return newZone;
      } else {
        console.error("Zone invalide détectée", zone);
        throw new Error("Zone invalide détecté");
      }
    })
  };
};
