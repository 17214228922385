
/*	PROD	*/

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigation } from '../components/navigationContext';

const useConditionalRedirect = ( allowedPreviousPaths: string[] ) => {
  const { previousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    if( previousPath && !allowedPreviousPaths.includes(previousPath) ) {
      history.push(previousPath || '/');
    }
  }, [previousPath, allowedPreviousPaths, history]);
};

export default useConditionalRedirect;
