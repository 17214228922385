
/*	PROD	*/

import React, { FunctionComponent, ReactNode, useState, useEffect, createContext, useContext } from "react";

import "./statistiques.css";

import { STAT_ENCART_TYPE_CONTROLEUR } from "../../models/consts/statistiques";
import { convertToPlanActionsType, convertToStatType } from "../../models/statistiques";
import { MbrType } from "../../models/mbr";
import { FOCUS_VIEW_MENU, FOCUS_VIEW_PROP_STATISTIQUES } from "../../models/consts";
import { MapSelectionType } from "../../models/mapSelection";
import { fetchGetStatAgence, fetchGetStatControleur, fetchGetStatGroupement, fetchGetStatPlanActions, fetchGetStatResidence } from "../../services/getStatistiques";
import MiniStat from "./mini";
import FullPlanComponent from "./fullPlans";
import FullStat from "./full";
import { Box, Typography } from "@mui/material";

interface StatEncartProps {
  mbr: MbrType;
  mapSelection: MapSelectionType;
  children: ReactNode;
  focusTo?: string;
  callFocus?: ( v: string ) => void;
};
interface StatData {
  dataCtrl?: any | null;
  dataPlans?: { nb: number; data: any } | null;
  dataAgc?: any | null;
  dataGrp?: any | null;
  dataRsd?: any | null;
};
interface StatComponentType extends FunctionComponent<StatEncartProps> {
  MiniStatCtrlProp: FunctionComponent<{ dataKey: keyof StatData }>;
  MiniPlansActions: FunctionComponent;
  MiniObjectifsProp: FunctionComponent;
  FullStatCtrlProp: FunctionComponent<{ dataKey: keyof StatData }>;
  FullPlansActions: FunctionComponent;
};
interface StatDisplayProps {
  dataKey: keyof StatData;
};
interface StatDataContextType extends StatData {
  mbr: MbrType;
  refreshDataPlans: () => void;
}

const StatDataContext = createContext<StatDataContextType | null>(null);

const useStatData = () => {
  const context = useContext(StatDataContext);
  if (!context) throw new Error("useStatData must be used within a StatDataContext.Provider");
  return context;
};

const StatComponent: StatComponentType = ({
  mbr,
  mapSelection,
  children,
  focusTo,
  callFocus,
}) => {
  const [mbrComponent, setMbrCopmponent] = useState(0);
  const [agcComponent, setAgcComponent] = useState(0);
  const [grpComponent, setGrpComponent] = useState(0);
  const [rsdComponent, setRsdComponent] = useState(0);
  const [entryComponent, setEntryComponent] = useState(0);
  const [data, setData] = useState<StatData>({
    dataCtrl: null,
    dataPlans: null,
    dataAgc: null,
    dataGrp: null,
    dataRsd: null,
  });
  const [haveFocus, setHaveFocus] = useState(false);

  useEffect(() => {
    if( ( mbr.id > 0 ) && ( mbr.id !== mbrComponent ) ) {
      fetchControleur();
      fetchPlansActions();

      setMbrCopmponent(mbr.id);
    }
// eslint-disable-next-line
  }, [mbr]);
  useEffect(() => {
    if( ( mbr.id > 0 ) && ( mapSelection.agc > 0 ) && ( mapSelection.agc !== agcComponent ) ) {
      fetchAgence();
      setAgcComponent(mapSelection.agc);
      setGrpComponent(0);
      setRsdComponent(0);
      setEntryComponent(0);
    }
    if( ( mbr.id > 0 ) && ( mapSelection.grp > 0 ) ) {
      if( mapSelection.grp !== grpComponent ) {
        fetchGroupement();
        setGrpComponent(mapSelection.grp);
        setRsdComponent(0);
        setEntryComponent(0);
      }
    } else {
      setGrpComponent(0);
      setRsdComponent(0);
      setEntryComponent(0);
    }
    if( ( mbr.id > 0 ) && ( mapSelection.rsd > 0 ) ) {
      if( mapSelection.rsd !== rsdComponent ) {
        fetchResidence();
        setRsdComponent(mapSelection.rsd);
        setEntryComponent(0);
      }
    } else {
      setRsdComponent(0);
      setEntryComponent(0);
    }
// eslint-disable-next-line
  }, [mapSelection]);
  useEffect(() => {
    if( !haveFocus && ( focusTo === FOCUS_VIEW_MENU ) ) {
      setHaveFocus(true);
    } else if( haveFocus && ( focusTo !== FOCUS_VIEW_MENU ) ) {
      setHaveFocus(false);
    }
// eslint-disable-next-line
  }, [focusTo]);

  const handelCallFocus = () => {
    if( callFocus ) {
      callFocus(FOCUS_VIEW_PROP_STATISTIQUES);
    }
  };

  const fetchControleur = async () => {
    try {
      const response = await fetchGetStatControleur(mbr.id, mbr.mac);

      if( response.result ) {
        const newStat = convertToStatType(response.data);

        setData(prevState => ({
          ...prevState,
          ...prevState,
          dataCtrl: newStat,
        }));
      } else {
        console.error(response.data.txt);
      }
    } catch(error) {
      console.error(error);
    }      
  };
  const fetchPlansActions = async () => {
    try {
      const param = STAT_ENCART_TYPE_CONTROLEUR + ':' + mbr.id;
      const response = await fetchGetStatPlanActions(mbr.id, mbr.mac, param);

      if( response.result ) {
        var newPlans = convertToPlanActionsType(response.data);

        setData(prevState => ({
          ...prevState,
          ...prevState,
          dataPlans: newPlans,
        }));
      } else {
        console.error(response.data.txt);
      }
    } catch(error) {
      console.error(error);
    }
  };
  const fetchAgence = async () => {
    try {
      const response = await fetchGetStatAgence(mbr.id, mbr.mac, mapSelection.agc);

      if( response.result ) {
        const newStat = convertToStatType(response.data);

        if( ( newStat !== null ) && ( newStat !== undefined ) ) {
          setData(prevState => ({
            ...prevState,
            ...prevState,
            dataAgc: newStat,
          }));
        }
      } else {
        console.error(response.data.txt);
      }
    } catch(error) {
      console.error(error);
    }
  };
  const fetchGroupement = async () => {
    try {
      const response = await fetchGetStatGroupement(mbr.id, mbr.mac, mapSelection.agc, mapSelection.grp);

      if( response.result ) {
        const newStat = convertToStatType(response.data);

        setData(prevState => ({
          ...prevState,
          ...prevState,
          dataGrp: newStat,
        }));
      } else {
        console.error(response.data.txt);
      }
    } catch(error) {
      console.error(error);
    }
  };
  const fetchResidence = async () => {
    try {
      const response = await fetchGetStatResidence(mbr.id, mbr.mac, mapSelection.agc, mapSelection.grp, mapSelection.rsd);

      if( response.result ) {
        const newStat = convertToStatType(response.data);

        setData(prevState => ({
          ...prevState,
          ...prevState,
          dataRsd: newStat,
        }));
      } else {
        console.error(response.data.txt);
      }
    } catch(error) {
      console.error(error);
    }
  };

  const refreshDataPlans = () => {
    fetchPlansActions();
  }

  return (
    <StatDataContext.Provider value={ { mbr, ...data, refreshDataPlans } }>
        <Box className="stat-screen"
          onClick={ handelCallFocus }
          sx={{ height: '100%', }}
        >
          { children }
        </Box>
    </StatDataContext.Provider>
  );
};

const MiniStatCtrlProp: FunctionComponent<StatDisplayProps> = ({ dataKey }) => {
  const data = useStatData()[dataKey];

  return (
    <Box className="field-mini-stat statistiques-component">
      <MiniStat data={ data }/>
    </Box>
  );
};
const MiniPlansActions: FunctionComponent = () => {
  const data = useStatData()['dataPlans'];

  return (
    <Box className="field-mini-plans statistiques-component"
      sx={{
        border: '2px solid #45b40b',
        color: '#45b40b',
        borderRadius: '10px',
        width: '100%',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Typography className="mini-plans-title statistiques-component-title"
        sx={{
          position: 'absolute',
          top: '-10px',
          left: '20px',
          backgroundColor: 'white',
          padding: '0 5px',
        }}
      >
        Plans d'actions en cours
      </Typography>
      <Typography className="mini-plans-value statistiques-component-value"
        sx={{
          fontWeight: 'bold',
          fontSize: '2rem',
        }}
      >
        { ( ( data !== undefined ) && ( data !== null ) ) ? data.nb : 'Patientez...' }
      </Typography>
    </Box>
  );
};
const MiniObjectifsProp: FunctionComponent = () => {
  const data = useStatData()['dataCtrl'];

  return (
    <Box className="field-mini-objectifs statistiques-component"
      sx={{
        border: '2px solid #45b40b',
        color: '#45b40b',
        borderRadius: '10px',
        width: '100%',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Typography className="mini-objectifs-title statistiques-component-title"
        sx={{
          position: 'absolute',
          top: '-10px',
          left: '20px',
          backgroundColor: 'white',
          padding: '0 5px',
        }}
      >
        Mes objectifs
      </Typography>

      <Typography className="mini-objectifs-value statistiques-component-value"
        sx={{
          fontWeight: 'bold',
          fontSize: '2rem',
        }}
      >
        { ( data === null ) ? 'Patientez...' : data.objectif }
      </Typography>
    </Box>
  );
};

const FullStatCtrlProp: FunctionComponent<StatDisplayProps> = ( { dataKey } ) => {
  const data = useStatData()[dataKey];

  return (
    <Box className="field-full-stat statistiques-component">
      <FullStat data={ data }/>
    </Box>
  );
};
const FullPlansActions: FunctionComponent = () => {
  const { mbr, dataPlans, refreshDataPlans } = useStatData();

  const handelSomeEvent = () => {
    refreshDataPlans();
  };

  return (
      <FullPlanComponent
        mbr={ mbr }
        data={ ( ( dataPlans !== undefined ) && ( dataPlans !== null ) ) ? dataPlans.data : [] }
        onDataChange={ handelSomeEvent }
      />
  );
};

StatComponent.MiniStatCtrlProp = MiniStatCtrlProp;
StatComponent.MiniPlansActions = MiniPlansActions;
StatComponent.MiniObjectifsProp = MiniObjectifsProp;
StatComponent.FullStatCtrlProp = FullStatCtrlProp;
StatComponent.FullPlansActions = FullPlansActions;

export default StatComponent;
