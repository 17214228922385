
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";

import "./styles/recupMail.css";

import logoSociete from "../assets/logo_appli.png";
import vignette from "../assets/ORGAprop_bonhomme.png"

import { RECUP_MAIL_ALLOWED_PATHS } from "../models/consts/aplliUrls";
import fetchRecupMail from "../services/recupMail";
import useConditionalRedirect from "../helpers/useConditionalRedirect";
import getScreenSize from "../helpers/getScreenSize";
import InputComponent from "../components/balises/input";

const RecupMail : FunctionComponent = () => {
  useConditionalRedirect(RECUP_MAIL_ALLOWED_PATHS);

  const [message, setMessage] = useState<string>('Entrez votre adresse mail de contact');
  const [mail, setMail] = useState('');
  const [error, setError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInputChange = ( champs: string, value: string | number | null ): void => {
    if( ( champs === 'text' ) && ( typeof value === 'string' ) ) {
      setMail(value);
    }
  };
  const handleSubmit = async () => {
    try {
      const response = await fetchRecupMail(mail);

      if( response.result ) {
        setError(false);
        setMessage('Nous venons de transmettre vos identifiants sur votre email et de réinitialiser votre accès !');
        setShowResult(true);
      } else {
        setError(true);
        setMessage(response.data.txt);
        setShowResult(true);
      }
    } catch( error ) {
      setError(true);
      setMessage('Une erreur s\'est produite lors de la connexion:');
      setShowResult(true);
    }
  }

  return (
    <Box className="recup-mail-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `portrait-view ${ screenSize }-size` }
          container
          component="div"
          direction="column"
          justifyContent="space-between"
          size={12}
          sx={{
            height: '100%',
          }}
        >
          <Grid2 component="div" size={12}>
            { ViewVignettes(isPortrait) }
          </Grid2>

          { ( showResult ) &&
            <Grid2 component="div" size={12}>
              { ViewResult(message, error) }
            </Grid2>
          }
          { ( !showResult ) &&
            <Grid2 component="div" size={12}>
              { ViewPrincipale(mail, error, handleInputChange, handleSubmit) }
            </Grid2>
          }

          <Grid2 component="div" flexGrow={ 0 } size={12}></Grid2>
        </Grid2>
      }
      { ( !isPortrait ) &&
        <Grid2 className={ `landscape-view ${ screenSize }-size` }
          container
          component="div"
          alignItems="center"
          justifyContent="center"
        >
          <Grid2 component="div" size={6} sx={{ height: '100vh', }}>
            { ViewVignettes(isPortrait) }
          </Grid2>

          { ( showResult ) &&
            <Grid2 component="div" size={6} sx={{ height: '100vh', }}>
              { ViewResult(message, error) }
            </Grid2>
          }
          { ( !showResult ) &&
            <Grid2 component="div" size={6} sx={{ height: '100vh', }}>
              { ViewPrincipale(mail, error, handleInputChange, handleSubmit) }
            </Grid2>
          }
        </Grid2>
      }
    </Box>
  );
};

const ViewVignettes = (
  isPortrait: boolean,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={  ( isPortrait ) ? 'flex-end' : 'space-between' }
      alignItems="center"
      sx={ ( !isPortrait ) ? { height: '100%' } : {} }
    >
      <Grid2 className="logo-appli-container"
        component="div"
        size={4}
      >
        <img src={ logoSociete } alt="logo" className="logo-appli"/>
      </Grid2>

      <Grid2 className="logo-perso-container"
        component="div"
        size={6}
      >
        <img src={ vignette } alt="logo" className="logo-perso"/>
      </Grid2>
    </Grid2>
  );
};
const ViewSaisie = (
  valueComponent: string,
  errorComponent: boolean,
  onChange: (
    champs: string,
    value: string | number | null,
  ) => void,
  onFinish: () => void
) => {
  return (
    <InputComponent
      name="text"
      label="Entrez votre adresse mail de contact"
      value={ valueComponent }
      type="text"
      error={ errorComponent }
      onChange={ onChange }
      onFinish={ onFinish }
    />
  );
};
const ViewButton = (
  onClick: () => void
) => {
  return (
    <Button className="btn-send"
      variant="contained"
      onClick={ onClick }
      
      sx={{
        width: '100%',
      }}
    >
      ENVOYER
    </Button>
  );
};
const ViewPrincipale = (
  valueComponent: string,
  errorComponent: boolean,
  onChange: (
    champs: string,
    value: string | number | null,
  ) => void,
  onClick: () => void,
) => {
  return (
    <Grid2 className="field-principal"
      container
      component="div"
      direction="column"
      alignItems="center"
      sx={{
        marginBottom: 4,
      }}
      spacing={2}
    >
      <Grid2 component='div' size={10}>
        { ViewSaisie(valueComponent, errorComponent, onChange, onClick) }
      </Grid2>

      <Grid2 component="div" size={6} sx={{ marginTop: '20%', }}>
        { ViewButton(onClick) }
      </Grid2>
    </Grid2>
  );
};
const ViewMessage = (
  messageComponenet: string,
) => {
  return (
    <Box>{ messageComponenet }</Box>
  );
};
const ViewReturn = () => {
  return (
      <Button href="/login">Retour à la page de connexion</Button>
  );
};
const ViewResult = (
  messageComponent: string,
  errorComponent: boolean,
) => {
  return (
    <Grid2 className="field-result"
      container
      component="div"
      direction="column"
      alignItems="center"
      sx={{
        marginBottom: 4,
        textAlign: 'center',
      }}
      spacing={2}
    >
      <Grid2 component="div" size={10} sx={ ( errorComponent ) ? { marginTop: '10%', color: 'red' } : { marginTop: '10%', } }>
        { ViewMessage(messageComponent) }
      </Grid2>

      <Grid2 component="div" size={10} sx={{ marginTop: '10%', }}>
        { ViewReturn() }
      </Grid2>
    </Grid2>
  );
};

export default RecupMail;
