
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { CONFIG_PROP_CONFIG_AFFICHAGE, CONFIG_PROP_CONFIG_METEO, CONFIG_PROP_CONFIG_PRODUITS, CONFIG_PROP_CONFIG_VISITE } from "../../../models/consts/ctrlProp";
import { ConfigPropType, initialConfigProp } from "../../../models/ctrlProp";
import ToggleSwitchComponent from "../../toggleSwitch/toggleSwitch";

interface ConfigurationPropComponentProps {
	config: ConfigPropType;
	onConfigCtrlChange: (config: ConfigPropType) => void;
}

const ConfigurationPropComponent: FunctionComponent<ConfigurationPropComponentProps> = ({
	config,
	onConfigCtrlChange,
}) => {
	const [configComponent, setConfigComponent] = useState({ ...initialConfigProp });
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setConfigComponent(config);
		setIsMounted(true);
	}, [config]);
	useEffect(() => {
		if( isMounted ) {
			onConfigCtrlChange(configComponent);
		}
// eslint-disable-next-line
	}, [configComponent]);

	const handelConfigChange = (param: string, val: boolean) => {
		switch( param ) {
			case CONFIG_PROP_CONFIG_VISITE:
				setConfigComponent(prevState => ({ ...prevState, visite: val }));
				break;
			case CONFIG_PROP_CONFIG_METEO:
				setConfigComponent(prevState => ({ ...prevState, meteo: val }));
				break;
			case CONFIG_PROP_CONFIG_AFFICHAGE:
				setConfigComponent(prevState => ({ ...prevState, affichage: val }));
				break;
			case CONFIG_PROP_CONFIG_PRODUITS:
				setConfigComponent(prevState => ({ ...prevState, produits: val }));
				break;
		}
	}

	return (
		<Box className="config-prop-component">
			<Grid2 className="config-prop-container"
				container
				component="div"
				direction="column"
			>
				<Grid2 className="field-program"
					component="div"
					size={12}
				>
					<ToggleSwitchComponent
						param={ CONFIG_PROP_CONFIG_VISITE }
            lbl0="Programmée"
            lbl1="Inopinée"
						value={ config.visite }
						onChangeValue={ (p, v) => handelConfigChange(p, v) }
					/>
				</Grid2>

				<Grid2 className="field-meteo"
					component="div"
					size={12}
				>
					<ToggleSwitchComponent
						param={ CONFIG_PROP_CONFIG_METEO }
            lbl0="Normale"
            lbl1="Perturbée"
            value={ config.meteo }
            onChangeValue={ (p, v) => handelConfigChange(p, v) }
					/>
				</Grid2>

				<Grid2 className="field-affichage"
					component="div"
					size={12}
				>
					<ToggleSwitchComponent
						param={ CONFIG_PROP_CONFIG_AFFICHAGE }
            lbl0="Conforme"
            lbl1="Non conforme"
            value={ !config.affichage }
            onChangeValue={ (p, v) => handelConfigChange(p, v) }
					/>
				</Grid2>

				<Grid2 className="field-produits"
					component="div"
					size={12}
				>
					<ToggleSwitchComponent
						param={ CONFIG_PROP_CONFIG_PRODUITS }
            lbl0="Conforme"
            lbl1="Non conforme"
            value={ !config.produits }
            onChangeValue={ (p, v) => handelConfigChange(p, v) }
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
};

export default ConfigurationPropComponent;
