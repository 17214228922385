
/*	PROD	*/

export const SECRET_KEY = "2f7704b93a1720f0800da94c79d5a8605d553b69218c728434550c15277c6380";

export const URL_DGPF = 'https://www.orgaprop.org/ress/rgpd.php';

export const COLOR_ORGA_PROP = '#5E7994';

export const LIST_SELECT_TYPE_VIEW_AGENCE = 'agc';
export const LIST_SELECT_TYPE_VIEW_GROUPEMENT = 'grp';
export const LIST_SELECT_TYPE_VIEW_RESIDENCE = 'rsd';
export const LIST_SELECT_TYPE_VIEW_ENTRY = 'entry';
export const LIST_SELECT_TYPE_VIEW_MAIN = 'main';

export const FOCUS_VIEW_SELECTION_ENTRY = 'sel_entry';
export const FOCUS_VIEW_MENU = 'menu';
export const FOCUS_VIEW_PROP_MENU = 'prop_conf';
export const FOCUS_VIEW_PROP_PLAN_D_ACTIONS = 'prop_plan';
export const FOCUS_VIEW_PROP_SIGNATURE = 'prop_sign';
export const FOCUS_VIEW_PROP_SEND = 'prop_send';
export const FOCUS_VIEW_PROP_GRILLE = 'prop_grill';
export const FOCUS_VIEW_PROP_GRILLE_ZONE = 'prop_zone';
export const FOCUS_VIEW_PROP_GRILLE_ELEMENT = 'prop_element';
export const FOCUS_VIEW_PROP_GRILLE_CRITER = 'prop_criter';
export const FOCUS_VIEW_PROP_STATISTIQUES = 'prop_stat';

export const APPLI_SELECTED_ORGA_PROXI = 'proxi';
export const APPLI_SELECTED_ORGA_CONTRAT = 'contrat';
export const APPLI_SELECTED_CONTROL_PROP = 'prop';
export const APPLI_SELECTED_CONTROL_SECU = 'secu';
export const APPLI_SELECTED_CONTROL_PSP = 'psp';
export const APPLI_SELECTED_CONTROL_PREST = 'prest';

export const SCREEN_SIZE_DESKTOP = 'desktop';
export const SCREEN_SIZE_TABLETTE = 'tablette';
export const SCREEN_SIZE_MOBILE = 'mobile';
