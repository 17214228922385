
/*	PROD	*/

export const LOGIN_URL = '/';

export const RECUP_MAIL_URL = '/recup-mail';
export const ACCEUIL_URL = '/acceuil';
export const PROFIL_URL = '/profil';

export const SELECT_ENTRY_URL = '/select-entry';

export const CONTROL_PROP_DESKTOP_URL = '/control-prop';
export const CONTROL_PROP_MOBILE_URL = '/controlprop';
export const CONTROL_PROP_OBJECTIFS_URL = CONTROL_PROP_MOBILE_URL + '/objectifs';
export const CONTROL_PROP_PLANS_ACTIONS_URL = CONTROL_PROP_MOBILE_URL + '/plans-actions';
export const CONTROL_PROP_ENTRY_SELECT_URL = CONTROL_PROP_MOBILE_URL + '/entry-select';
export const CONTROL_PROP_CONFIGURATION_URL = CONTROL_PROP_MOBILE_URL + '/config';
export const CONTROL_PROP_GRILLE_URL = CONTROL_PROP_MOBILE_URL + '/grille';

export const LOGIN_ALLOWED_PATHS = [''];

export const RECUP_MAIL_ALLOWED_PATHS = [
	'/', 
	RECUP_MAIL_URL
];
export const ACCEUIL_ALLOWED_PATH = [
	'/',
	ACCEUIL_URL,
	CONTROL_PROP_DESKTOP_URL,
	CONTROL_PROP_MOBILE_URL,
	PROFIL_URL
];
export const PROFIL_ALLOWED_PATHS = [
	ACCEUIL_URL,
	PROFIL_URL,
	CONTROL_PROP_MOBILE_URL
];

export const CONTROL_PROP_ALLOWED_PATHS = [
	ACCEUIL_URL,
	CONTROL_PROP_DESKTOP_URL
];

export const MOBILE_CONTROL_PROP_ALLOWED_PATH = [
	ACCEUIL_URL,
	CONTROL_PROP_MOBILE_URL,
	PROFIL_URL,
	CONTROL_PROP_OBJECTIFS_URL,
	CONTROL_PROP_PLANS_ACTIONS_URL,
	CONTROL_PROP_ENTRY_SELECT_URL,
	CONTROL_PROP_CONFIGURATION_URL,
	CONTROL_PROP_ENTRY_SELECT_URL
];
export const OBJECTIFS_ALLOWED_PATHS = [
	CONTROL_PROP_MOBILE_URL,
	CONTROL_PROP_OBJECTIFS_URL
];
export const PLAN_ACTIONS_ALLOWED_PATHS = [
	CONTROL_PROP_MOBILE_URL,
	CONTROL_PROP_PLANS_ACTIONS_URL
];
export const ENTRY_SELECT_ALLOWED_PATHS = [
	CONTROL_PROP_MOBILE_URL,
	CONTROL_PROP_ENTRY_SELECT_URL
];
export const CONFIGURATION_CONTROL_PROP_ALLOWED_PATHS = [
	CONTROL_PROP_MOBILE_URL,
	CONTROL_PROP_CONFIGURATION_URL,
	CONTROL_PROP_ENTRY_SELECT_URL
];
export const GRILLE_CONTROL_PROP_ALLOWED_PATHS = [
	CONTROL_PROP_ENTRY_SELECT_URL,
	CONTROL_PROP_CONFIGURATION_URL,
	CONTROL_PROP_GRILLE_URL
];
