
import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid2, Switch } from "@mui/material";

import './toggleSwitch.css';

import { CONFIG_PROP_CONFIG_AFFICHAGE, CONFIG_PROP_CONFIG_METEO, CONFIG_PROP_CONFIG_PRODUITS, CONFIG_PROP_CONFIG_VISITE } from "../../models/consts/ctrlProp";

interface ToggleSwitchComponentProps {
  param: string;
  lbl0: string;
  lbl1: string;
  value: boolean;
  onChangeValue: (param: string, val: boolean) => void;
};

const ToggleSwitchComponent: FunctionComponent<ToggleSwitchComponentProps> = ( { param, lbl0, lbl1, value, onChangeValue } ) => {
  const [valueComponent, setValueComponent] = useState(true);
  const [paramComponent, setParamComponent] = useState('');
	const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if( param === CONFIG_PROP_CONFIG_VISITE ) {
      setParamComponent('Visite');
    } else if( param === CONFIG_PROP_CONFIG_METEO ) {
      setParamComponent('Météo');
    } else if( param === CONFIG_PROP_CONFIG_PRODUITS ) {
      setParamComponent('Produits, matériels, équipements');
    } else if( param === CONFIG_PROP_CONFIG_AFFICHAGE ) {
      setParamComponent('Affichage parties communes');
    }
  }, [param]);
  useEffect(() => {
		if( !isMounted ) {
    	setValueComponent(value);
			setIsMounted(true);
		}
// eslint-disable-next-line
  }, [value]);

  const toggleValue = () => {
    setValueComponent(( prevValue ) => ( prevValue ) ? false : true);
    onChangeValue(param, valueComponent);
  };
	const handelSwitchOff = () => {
		setValueComponent(false);
		onChangeValue(param, valueComponent);
	};
	const handelSwitchOn = () => {
		setValueComponent(true);
		onChangeValue(param, valueComponent);
	};

  return (
    <Box className="toggle-switch-component"
			sx={{
				fontSize: '10pt',
				margin: '5px',
				padding: '5px',
				border: '1px solid #bdbdbd',
				borderRadius: '5px',
			}}
		>
      <Grid2
				container
				component="div"
				alignItems="center"
			>
				<Grid2 className="toggle-switch-title"
					component="div"
					size={4}
					sx={{
						fontWeight: 'bold',
					}}
				>
					{ paramComponent }
				</Grid2>

				<Grid2 className="toggle-switch-lbl0"
					component="div"
					size={3}
				>
					<Box
						onClick={ handelSwitchOff }
					>
						{ lbl0 }
					</Box>
					
				</Grid2>

				<Grid2 className="toggle-switch" 
					component="div"
					size={2}
				>
					<Switch
						checked={ valueComponent }
						onChange={ toggleValue }
					/>
				</Grid2>
        
				<Grid2 className="toggle-switch-lbl1"
					component="div"
					size={3}
				>
					<Box
						onClick={ handelSwitchOn }
					>
						{ lbl1 }
					</Box>
					
				</Grid2>
      </Grid2>
    </Box>
  );
};

export default ToggleSwitchComponent;
