
/*	PROD	*/

export const STORE_PARAM_CIBLE = 'cible_prop';
export const STORE_PARAM_LIST_SELECT = 'list_select';
export const STORE_PARAM_MEMBRE = 'mbr';
export const STORE_PARAM_AGENCES = 'agcs';
export const STORE_PARAM_GROUPEMENTS = 'grps';
export const STORE_PARAM_RESIDENCES = 'rsds';
export const STORE_PARAM_ADRESSES = 'adrs';
export const STORE_PARAM_ENTRY = 'entry';
export const STORE_PARAM_GRILLE_PROP = 'grilleProp';
export const STORE_PARAM_CONF_PROP = 'configProp';
export const STORE_PARAM_LIST_SAVES_PROP = 'saveProp';
export const STORE_PARAM_OLD_CONECT = 'encryptedData';
export const STORE_PARAM_BACK_PAGE = 'backPage';
export const STORE_PARAM_APPLI_SELECTED = 'appliSelected';
