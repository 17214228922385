
/*	PROD	*/

import { SCREEN_SIZE_DESKTOP, SCREEN_SIZE_MOBILE, SCREEN_SIZE_TABLETTE } from "../models/consts";

const getScreenSize = (): string => {
  const width = window.innerWidth;
  var m = SCREEN_SIZE_MOBILE;

  if( width > 768 ) m = SCREEN_SIZE_TABLETTE;
  if( width > 1400 ) m = SCREEN_SIZE_DESKTOP;

  return m;
}

export default getScreenSize;
