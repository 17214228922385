
/*	PROD	*/

import axios from "axios";

import { STAT_ENCART_TYPE_AGENCE, STAT_ENCART_TYPE_CONTROLEUR, STAT_ENCART_TYPE_ENTRY, STAT_ENCART_TYPE_GROUPEMENT, STAT_ENCART_TYPE_RESIDENCE } from "../models/consts/statistiques";
import { API_URL_GET_MEMBRE_OBEJECTIFS, API_URL_GET_MEMBRE_PLANS_ACTIONS, API_URL_GET_MEMBRE_STATISTIQUES, API_URL_GET_RESIDENCE_PLAN_ACTIONS, API_URL_GET_RESIDENCE_STATISTIQUES, API_URL_GET_SOCIETE_PLANS_ACTIONS, API_URL_GET_SOCIETE_STATISTIQUES } from "../models/consts/apiUrls";

export async function fetchGetStatControleur( i: number, m: string ) {
  try {
    const url = API_URL_GET_MEMBRE_STATISTIQUES;
    const params = {
      mbr: i,
      mac: m,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetStatPlanActions( i: number, m: string, v: string ) {
  try {
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    
    let url = '';

    switch( v.split(':')[0] ) {
      case STAT_ENCART_TYPE_CONTROLEUR: 
        url = API_URL_GET_MEMBRE_PLANS_ACTIONS; 
        break;
      case STAT_ENCART_TYPE_AGENCE:
      case STAT_ENCART_TYPE_GROUPEMENT:
      case STAT_ENCART_TYPE_RESIDENCE:
        url = API_URL_GET_SOCIETE_PLANS_ACTIONS; 
        break;
      case STAT_ENCART_TYPE_ENTRY: 
        url = API_URL_GET_RESIDENCE_PLAN_ACTIONS; 
        break;
    }

    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetStatAgence( i: number, m: string, v: number ) {
  try {
    const url = API_URL_GET_SOCIETE_STATISTIQUES;
    const params = {
      mbr: i,
      mac: m,
      typ: STAT_ENCART_TYPE_AGENCE,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetStatGroupement( i: number, m: string, a: number, v: number ) {
  try {
    const url = API_URL_GET_SOCIETE_STATISTIQUES;
    const params = {
      mbr: i,
      mac: m,
      typ: STAT_ENCART_TYPE_GROUPEMENT,
      agc: a,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetStatResidence( i: number, m: string, a: number, g: number, v: number ) {
  try {
    const url = API_URL_GET_SOCIETE_STATISTIQUES;
    const params = {
      mbr: i,
      mac: m,
      typ: STAT_ENCART_TYPE_RESIDENCE,
      agc: a,
      grp: g,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetStatEntry( i: number, m: string, v: number ) {
  try {
    const url = API_URL_GET_RESIDENCE_STATISTIQUES;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
export async function fetchGetObjectifsControleur( i: number, m: string ) {
  try {
    const url = API_URL_GET_MEMBRE_OBEJECTIFS;
    const params = {
      mbr: i,
      mac: m,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      data: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
};
