
/*	PROD	*/

import React, { FunctionComponent, useState } from "react";

import "./fullPlans.css";

import { LinePlanActionsStatType } from "../../models/statistiques";
import InputComponent from "../balises/input";
import { fetchSetPlanAction } from "../../services/setCtrlProp";
import { MbrType } from "../../models/mbr";
import { convertToPlanActionsTypeFromLine } from "../../models/ctrlProp";
import { Box, Button, Grid2, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

interface FullPlanComponentProps {
  mbr: MbrType;
  data: LinePlanActionsStatType[];
  onDataChange: () => void;
};

const FullPlanComponent: FunctionComponent<FullPlanComponentProps> = ( { mbr, data, onDataChange } ) => {
  const [showList, setShowList] = useState(true);
  const [planSelected, setPlanSelected] = useState<LinePlanActionsStatType | null>(null);

  const handelOpenPlanAction = ( plan: LinePlanActionsStatType ) => {
    setPlanSelected(plan);
    setShowList(false);
  };
  const handelActionEditPlan = ( val: boolean ) => {
    setLevePlan(val);
  };

  const setLevePlan = async ( val: boolean ) => {
    if( planSelected !== null ) {
      try {
        const plan = convertToPlanActionsTypeFromLine(planSelected);

        plan.echeance = ( val ) ? '1' : '0';

        const response = await fetchSetPlanAction(mbr.id, mbr.mac, parseInt(planSelected.rsd), 0, plan);

        if( response.result ) {
          onDataChange();
          setShowList(true);
        } else {
          console.error(response.data.txt);
        }
      } catch( error ) {
        console.error(error);
      }
    }
  };

  return (
    <Box className="full-plans-component">
      { ( showList ) && ViewListe(data, handelOpenPlanAction) }
      { ( !showList && ( planSelected !== null ) ) && ViewEdit(planSelected, handelActionEditPlan) }
    </Box>
  );
};

const ViewListe = (
  data: LinePlanActionsStatType[],
  onPlanCliked: ( plan: LinePlanActionsStatType ) => void,
) => {
  return (
    <Grid2 className="list-plans-view"
      container
      component="div"
      direction="column"
    >
      <Grid2 component="div" size={12}>
        <InputComponent
          name="search"
          label=""
          value=""
          type="text"
          error={ false }
        />
      </Grid2>

      <Grid2 component="div" size={12}>
        <TableContainer className="tbl-entete-container" sx={{ height: 'calc(100vh - 350px)' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={ { width: '50%' } }>Résidence</TableCell>
                <TableCell align="center" style={ { width: '25%' } }>Date</TableCell>
                <TableCell align="center" style={ { width: '25%' } }>Échéance</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { ( data !== null ) && data.map(( donnee ) => {
                return ViewLinePlan(donnee, onPlanCliked)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  );
};
const ViewLinePlan = (
  data: LinePlanActionsStatType,
  onClickLine: ( plan: LinePlanActionsStatType ) => void,
) => {
  return (
    <TableRow key={data.id} onClick={ e => onClickLine(data) }>
      <TableCell align="left" style={{  fontSize: '8pt', }}>{ data.rsd.split('-- ')[1] }</TableCell>
      <TableCell align="center" style={{  fontSize: '8pt', }}>{ data.date }</TableCell>
      <TableCell align="center" style={{  fontSize: '8pt', }}>{ data.echeance }</TableCell>
    </TableRow>
  )
};
const ViewEdit = (
  plan: LinePlanActionsStatType,
  onBtnClicked: ( val: boolean ) => void,
) => {
  return (
    <Grid2 className="edit-plan-view"
      container
      component="div"
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      sx={{
        height: 'calc(100vh - 300px)',
      }}
    >
      <Grid2 component="div" size={10}>
        <InputComponent
          name="echeance"
          label="Échéance"
          value={ plan.echeance }
          type="search"
          readonly={ true }
          underline={ false }
          error={ false }
        />
      </Grid2>
      
      <Grid2 component="div" size={10}>
        <TextField className="text-edit-plan"
          multiline
          rows={4}
          variant="outlined"
          sx={{ width: '100%', }}
          value={ plan.txt }
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
        />
      </Grid2>

      <Grid2 component="div" size={12}>
        <Grid2
          container
          component="div"
          justifyContent="space-around"
        >
          <Grid2 component="div" size={4}>
            <Button className="btn-levee-plan"
              variant="outlined"
              color="success"
              onClick={ e => onBtnClicked(true) }
            >
              Lever le plan d'actions
            </Button>
          </Grid2>

          <Grid2 component="div" size={4}>
            <Button className="btn-refus-plan"
              variant="outlined"
              color="error"
              onClick={ e => onBtnClicked(false) }
            >
              Refuser le plan d'actions
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default FullPlanComponent;
