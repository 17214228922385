
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";

import './index.css';

import { fetchSetPlanAction } from "../../../services/setCtrlProp";
import { MbrType } from "../../../models/mbr";
import { convertToPlanActionsTypeFromJson, GrillePropType, PlanActionsType } from "../../../models/ctrlProp";
import { EntryType } from "../../../models/entry";
import { Box, Button, Grid2 } from "@mui/material";
import InputComponent from "../../balises/input";
import TextareaComponent from "../../balises/textarea";

interface PlanActionsComponentProps {
  mbr: MbrType;
  rsd: EntryType;
  ctrl: GrillePropType;
  onPlanChanging: ( plan: PlanActionsType ) => void;
};

const PlanActionsComponent: FunctionComponent<PlanActionsComponentProps> = ( {
	mbr,
	rsd,
	ctrl,
	onPlanChanging,
} ) => {
  const [dateEcheance, setDateEcheance] = useState('');
  const [message, setMessage] = useState('');
  const [isLeve, setIsLeve] = useState(true);
  const [canModify, setCanModify] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if( ctrl.plan ) {
      setDateEcheance(ctrl.plan.echeance);
      setMessage(ctrl.plan.txt);
      setIsLeve(( ctrl.plan.fin !== '' ));
      setCanModify(( ctrl.plan.id === 0 ))
    }
// eslint-disable-next-line
  }, [ctrl]);

  const handelEcheancePlan = ( v: string ) => {
    setDateEcheance(v);
    setIsModified(true);
  };
  const handelMessagePlan = ( v: string ) => {
    setMessage(v);
    setIsModified(true);
  };
  const handelLevePlan = () => {
    if( ctrl.plan && ( ctrl.plan.date === ctrl.time ) ) {
      setIsLeve(true);
      setCanModify(true);
    } else {
      setLeve('1');
    }
  };
  const handelNewPlan = () => {
    if( ctrl.plan && ( ctrl.plan.date === ctrl.time ) ) {
      setIsLeve(true);
      setCanModify(true);
    } else {
      setLeve('2');
    }
  };
  const handelSavePlan = () => {
    if( ctrl.id && ( ctrl.id > 0 ) ) {
      savePlan();
    }
  };

  const savePlan = async () => {
    try{
      const newPlan: PlanActionsType = {
        id: ( ctrl.plan ) ? ctrl.plan.id : 0,
        date: ( ctrl.plan ) ? ctrl.plan.date : 0,
        echeance: dateEcheance,
        fin: '',
        txt: message,
      }
      const response = await fetchSetPlanAction(mbr.id, mbr.mac, rsd.id, ( ( ctrl.id ) ? ctrl.id : 0 ), newPlan);

      if( ( response.result !== undefined ) && response.result ) {
        const planReceived = response.data;

        onPlanChanging(convertToPlanActionsTypeFromJson(planReceived));
      } else if( response.data !== undefined ) {
        console.error(response.data.txt);
      } else {
        console.error('Réponse non valide');
      }
    } catch( error ) {
      console.error(error);
    }
  }
  const setLeve = async ( fin: string ) => {
    if( ctrl.plan && ( ctrl.plan.id > 0 ) ) {
      try{
        const newPlan: PlanActionsType = {
          id: ctrl.plan.id,
          date: ctrl.plan.date,
          echeance: ctrl.plan.echeance,
          fin: fin,
          txt: ctrl.plan.txt,
        };
        const response = await fetchSetPlanAction(mbr.id, mbr.mac, rsd.id, ( ( ctrl.id ) ? ctrl.id : 0 ), newPlan);

        if( ( response.result !== undefined ) && response.result ) {
          const planReceived = response.data;

          setIsLeve(true);
          setCanModify(true);
          onPlanChanging(convertToPlanActionsTypeFromJson(planReceived));
        } else if( response.data !== undefined ) {
          console.error(response.data.txt);
        }
      } catch( error ) {
        console.error(error);
      }
    }
  };

  return (
    <Box className="plan-actions-component"
			sx={{
				height: '100%',
			}}
		>
      <Grid2 className="plan-actions-container"
				container
				component="div"
				direction="column"
				justifyContent="space-evenly"
				alignItems="center"
				sx={{
					height: '100%',
				}}
			>
        <Grid2 className="field-echeance"
					component="div"
					size={10}
				>
					<InputComponent
						name="echeance"
						label="Échéance"
						value={ dateEcheance }
						type="date"
						error={ false }
						readonly={ ( !( ( ctrl.plan ) && ( ctrl.plan.id > 0 ) ) || !canModify ) }
						onChange={ handelEcheancePlan }
					/>
        </Grid2>

        <Grid2 className="field-message"
					component="div"
					size={10}
				>
          <TextareaComponent
						name="message"
            value={ message } 
            onComponentChange={ handelMessagePlan }
            disabled={ ( !( ( ctrl.plan ) && ( ctrl.plan.id > 0 ) ) || !canModify ) }
          />
        </Grid2>

        <Grid2 className="field-buttons"
					component="div"
					size={12}
				>
          { ViewButtons(ctrl.plan, isLeve, isModified, canModify, handelLevePlan, handelNewPlan, handelSavePlan) }
        </Grid2>
      </Grid2>
    </Box>
  );
};

const ViewButtons = (
	planView: PlanActionsType | undefined,
	planIsLeve: boolean,
	planIsModified: boolean,
	planCanBeModifed: boolean,
	onClickLeve: () => void,
	onClickNew: () => void,
	onClickSave: () => void,
) => {
	return (
		<Box className="buttons-view"
			sx={{
				width: '100%',
			}}
		>
			<Grid2
				container
				component="div"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
			>
				{ ( ( planView ) && ( planView.id > 0 ) && !planIsLeve ) &&
					<Grid2
						component="div"
						size={8}
					>
						<Button className="btn-leve"
							variant="contained"
							key="leve"
							onClick={ onClickLeve }
							sx={{
								width: '100%',
							}}
						>
							Lever
						</Button>
					</Grid2>
				}
				{	( ( planView ) && ( planView.id > 0 ) && !planIsLeve && !planIsModified && !planCanBeModifed ) &&
					<Grid2
						component="div"
						size={8}
					>
						<Button className="btn-modif"
							variant="contained"
							key="modifier"
							onClick={ onClickNew }
							sx={{
								width: '100%',
							}}
						>
							Modifier
						</Button>
					</Grid2>
				}

				<Grid2
					component="div"
					size={8}
				>
					<Button className="btn-save"
						variant={ ( planIsModified ) ? 'contained' : 'outlined' }
						key="save"
						onClick={ onClickSave }
						sx={{
							width: '100%',
						}}
					>
						Enregistrer
					</Button>
				</Grid2>
			</Grid2>
		</Box>
	)
};

export default PlanActionsComponent;
