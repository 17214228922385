
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import { Box, Button, Grid2 } from "@mui/material";

import "./styles/login.css";

import logoSociete from "../assets/logo_appli.png";
import logoVignette from "../assets/ORGAprop-background-immeubles.png";

import { STORE_PARAM_MEMBRE, STORE_PARAM_CIBLE, STORE_PARAM_LIST_SELECT, STORE_PARAM_GRILLE_PROP, STORE_PARAM_CONF_PROP, STORE_PARAM_OLD_CONECT } from "../models/consts/store";
import { SECRET_KEY } from "../models/consts";
import { PARAM_LOGIN_TEST, PARAM_LOGIN_OK } from "../models/consts/login";
import { ACCEUIL_URL } from "../models/consts/aplliUrls";
import { MbrType, initialMbr } from "../models/mbr";
import { mapToGrillePropType } from "../models/ctrlProp";
import fetchLogin from "../services/login";
import getScreenSize from "../helpers/getScreenSize";
import InputComponent from "../components/balises/input";
import { useNavigation } from "../components/navigationContext";
import { useNotification } from "../components/notify";

const Login: FunctionComponent = () => {
  const notify = useNotification();

  const [form, setForm] = useState<MbrType>(initialMbr);
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [testLocalStorage, setTestLocalStorage] = useState(true);
  const [testConected, setTestConected] = useState(true);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [setPreviousPath, history]);

  useEffect(() => {
    const fetchData = async () => {
      const encryptedDataFromStorage = localStorage.getItem(STORE_PARAM_OLD_CONECT);

      if( encryptedDataFromStorage !== null ) {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, SECRET_KEY);
        const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const newForm = { ...form };

        const idMbr = ( ( decryptedData.id === null ) || ( decryptedData.id === undefined ) ) ? 0 : decryptedData.id;
        const pseudo = ( ( decryptedData.pseudo === null ) || ( decryptedData.pseudo === undefined ) ) ? '' : decryptedData.pseudo;
        const mdp = ( ( decryptedData.mdp === null ) || ( decryptedData.mdp === undefined ) ) ? '' : decryptedData.mdp;
        const mac = ( ( decryptedData.mac === null ) || ( decryptedData.mac === undefined ) ) ? '' : decryptedData.mac;

        newForm.id = idMbr;
        newForm.pseudo = pseudo;
        newForm.mdp = mdp;
        newForm.mac = mac;

        setForm(newForm);

        if( testConected ) {
          try {
            const response = await fetchLogin(pseudo, mdp, mac, PARAM_LOGIN_TEST);

            if( response.result ) {
              const dataMbr = {
                id: response.donn.id,
                pseudo: response.donn.pseudo,
                mac: response.donn.mac,
                mdp: response.donn.mdp,
                idAgent: response.donn.idAgent,
                txt: response.donn.txt,
                mail: response.donn.mail,
                niveau: response.donn.niveau,
                tel: response.donn.tel,
                abonnements: response.donn.abonnements,
              };

              const dataAgcs = response.donn.agences;

              let dataCible = {
                agc: ( dataMbr.niveau > 2 ) ? null : dataAgcs[0].id,
                grp: null,
                rsd: null,
                entry: null,
              };

              const dataProp = mapToGrillePropType(response.donn.grille.prop);
              const dataConf = response.donn.conf.prop;
              const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

              if( encryptedDataCible !== null ) {
                let decriptedDataCible = JSON.parse(encryptedDataCible);

                dataCible.agc = decriptedDataCible.agc;
                dataCible.grp = decriptedDataCible.grp;
                dataCible.rsd = decriptedDataCible.rsd;
                dataCible.entry = decriptedDataCible.entry;
              }

              const jsonMbr = JSON.stringify(dataMbr);
              const jsonAgcs = JSON.stringify(dataAgcs);
              const jsonCible = JSON.stringify(dataCible);
              const jsonProp = JSON.stringify(dataProp);
              const jsonConf = JSON.stringify(dataConf);

              let encryptedData = CryptoJS.AES.encrypt(jsonMbr, SECRET_KEY).toString();
              localStorage.setItem(STORE_PARAM_MEMBRE, encryptedData);
              localStorage.setItem(STORE_PARAM_LIST_SELECT, jsonAgcs);
              localStorage.setItem(STORE_PARAM_CIBLE, jsonCible);
              localStorage.setItem(STORE_PARAM_GRILLE_PROP, jsonProp);
              localStorage.setItem(STORE_PARAM_CONF_PROP, jsonConf);

              history.push(ACCEUIL_URL);
            }
          } catch (error) {
            notify('👉 Une erreur s\'est produite lors de la connexion:', { variant: 'error' });
            console.error(error);
          }

          setTestConected(false);
        }
      } else {
        console.error('Erreur lors de la récupération des données depuis LocalStorage');
      }

      setTestLocalStorage(false);
    };

    if( testLocalStorage ) {
			document.title = "Login";

      fetchData();
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInputChange = ( champs: string, value: string | number | null ): void => {
    const newField = { ...form };

    if( ( champs === 'pseudo' ) && ( typeof value === "string" ) ) {
      newField.pseudo = value;
    } else if( ( champs === 'mdp' ) && ( typeof value === "string" ) ) {
      newField.mdp = value;
    }

    setForm(newField);
  };
  const handleSubmit = async () => {
    var dataToStore = {
			id: form.id,
			pseudo: form.pseudo,
			mdp: form.mdp,
			mac: '',
		};

    var jsonData = JSON.stringify(dataToStore);
		var encryptedData = CryptoJS.AES.encrypt(jsonData, SECRET_KEY).toString();

		localStorage.setItem(STORE_PARAM_OLD_CONECT, encryptedData);

		try {
			const response = await fetchLogin(form.pseudo, form.mdp, form.mac, PARAM_LOGIN_OK);

			if( response.result ) {
				const dataMbr = {
					id: response.donn.id,
					pseudo: response.donn.pseudo,
					mac: response.donn.mac,
					mdp: response.donn.mdp,
					idAgent: response.donn.idAgent,
					txt: response.donn.txt,
					mail: response.donn.mail,
					niveau: response.donn.niveau,
					tel: response.donn.tel,
					abonnements: response.donn.abonnements,
				};
				const dataAgcs = response.donn.agences;
				const dataCible = {
					agc: (dataMbr.niveau > 2) ? null : dataAgcs[0].id,
					grp: null,
					rsd: null,
					entry: null,
				};
				const dataConf = response.donn.conf.prop;

				dataToStore = {
					id: response.donn.id,
					pseudo: response.donn.pseudo,
					mdp: response.donn.mdp,
					mac: response.donn.mac,
				};
    
				jsonData = JSON.stringify(dataToStore);
				encryptedData = CryptoJS.AES.encrypt(jsonData, SECRET_KEY).toString();
    
				localStorage.setItem(STORE_PARAM_OLD_CONECT, encryptedData);

				const jsonMbr = JSON.stringify(dataMbr);
				const jsonAgcs = JSON.stringify(dataAgcs);
				const jsonCible = JSON.stringify(dataCible);
				const jsonConf = JSON.stringify(dataConf);

				encryptedData = CryptoJS.AES.encrypt(jsonMbr, SECRET_KEY).toString();
				localStorage.setItem(STORE_PARAM_MEMBRE, encryptedData);
				localStorage.setItem(STORE_PARAM_LIST_SELECT, jsonAgcs);
				localStorage.setItem(STORE_PARAM_CIBLE, jsonCible);
				localStorage.setItem(STORE_PARAM_CONF_PROP, jsonConf);
				
				history.push(ACCEUIL_URL);
			} else {
				notify(response.donn.txt, { variant: 'error' });
			}
		} catch( error ) {
			notify('Une erreur s\'est produite lors de la connexion:', { variant: 'error' });
			console.log(error);
		}
  };

  return (
    <Box className="login-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `portrait-view ${ screenSize }-size` }
          container
          component="div"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '100%',
          }}
        >
          <Grid2 component="div" size={12}>
            { ViewVignettes(isPortrait) }
          </Grid2>

          <Grid2 component="div" size={12}>
            { ViewSaisie(form.pseudo, form.mdp, isPortrait, handleInputChange) }
          </Grid2>

          <Grid2 component="div" size={12}>
            { ViewAction(handleSubmit) }
          </Grid2>

          <Grid2 component="div" flexGrow={ 0 } size={12}></Grid2>
        </Grid2>
      }
      { ( !isPortrait ) &&
        <Grid2 className={ `landscape-view ${ screenSize }-size` }
          container
          component="div"
          alignItems="center"
          justifyContent="center"
        >
          <Grid2 component="div" size={6} sx={{ height: '100vh', }}>
            { ViewVignettes(isPortrait) }
          </Grid2>

          <Grid2 component="div" size={6} sx={{ height: '100vh', }}>
            <Grid2 className="container-field-principal"
              container
              component="div"
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              sx={{
                height: '100%',
              }}
            >
              <Grid2 component="div" size={12}>
                { ViewSaisie(form.pseudo, form.mdp, isPortrait, handleInputChange) }
              </Grid2>
              
              <Grid2 component="div" size={12} sx={{ textAlign: 'center' }}>
                { ViewAction(handleSubmit) }
              </Grid2>
            </Grid2>
            
          </Grid2>
        </Grid2>
      }
    </Box>
  );
};

const ViewVignettes = (
  isPortrait: boolean,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-between' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
    >
      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img className="logo-appli" src={ logoSociete } alt="logo"/>
      </Grid2>

      <Grid2 className="logo-vignette-container" component="div" size={12}>
        <img className="logo-vignette" src={ logoVignette } alt="logo"/>
      </Grid2>
    </Grid2>
  );
};
const ViewSaisie = (
  psd: string,
  pwd: string,
  isPortrait: boolean,
  onChange: (
    champs: string,
    value: string | number | null
  ) => void
) => {
  return (
    <Grid2 className="field-saisie"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'center' : 'space-evenly' }
      alignItems="center"
      spacing={5}
      sx={{
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <Grid2 className="field-pseudo"
        component="div"
        size={10}
      >
        <InputComponent
          name="pseudo"
          label="Identifiant"
          value={ psd }
          type="text"
          error={ false }
          onChange={ onChange }
        />
      </Grid2>

      <Grid2 className="field-mdp"
        component="div"
        size={10}
      >
        <InputComponent
          name="mdp"
          label="Mot de passe"
          value={ pwd }
          type="password"
          error={ false }
          onChange={ onChange }
        />
      </Grid2>
    </Grid2>
  );
};
const ViewAction = (
  onClick: () => void
) => {
  return (
    <Grid2 className="field-actions"
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={5}
    >
      <Grid2 component="div" size={12} sx={{ textAlign: 'center' }}>
        <Button href="/recup-mail">Problème de connexion ?</Button>
      </Grid2>

      <Grid2 component="div" size={12} sx={{ textAlign: 'center' }}>
        <Button onClick={ onClick } variant="contained">SE CONNECTER</Button>
      </Grid2>
    </Grid2>
    
  );
};

export default Login;
