
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import './style/grilleControlMobile.css';

import goutte from '../../assets/logo_prop.png';
import cone from '../../assets/cone_alpha_512_pixels_32bit.png';
import logo from '../../assets/controlprop.png';
import abords from '../../assets/controlProp/abords_acces_immeubles_prop.png';
import hall from '../../assets/controlProp/hall_prop.png';
import ascenseur from '../../assets/controlProp/ascenseur_prop.png';
import escalier from '../../assets/controlProp/escalier_prop.png';
import palier from '../../assets/controlProp/paliers_coursives_prop.png';
import localOm from '../../assets/controlProp/local_om_prop.png';
import localVelo from '../../assets/controlProp/local_velo_prop.png';
import cave from '../../assets/controlProp/cave_prop.png';
import parkSS from '../../assets/controlProp/parking_sous_sol_prop.png';
import cour from '../../assets/controlProp/cour_interieure_prop.png';
import parkExt from '../../assets/controlProp/parking_ext_prop.png';
import espaceExt from '../../assets/controlProp/espaces_exterieurs_prop.png';
import burreau from '../../assets/controlProp/icone_bureau_prop.png';
import reunion from '../../assets/controlProp/salle_commune_prop.png';
import sanitaire from '../../assets/controlProp/buanderie_prop.png';
import poussette from '../../assets/controlProp/local_poussette_prop.png';
import localisation from '../../assets/controlProp/localisation_prop.png';

import { GRILLE_CONTROL_PROP_ALLOWED_PATHS, LOGIN_URL, SELECT_ENTRY_URL } from "../../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../../models/mbr";
import { MENU_ONGLET_BUTTON_ACCEUIL } from "../../models/consts/ctrlProp";
import { convertToEntryType, EntryType, initialEntry } from "../../models/entry";
import { STORE_PARAM_CIBLE, STORE_PARAM_CONF_PROP } from "../../models/consts/store";
import { ConfigPropType, converteToGrilleTypeFromJson, convertGrillePropTypeToJson, convertToPlanActionsType, convertToPrestatairePropType, GrillePropType, initialGrilleProp, mapToZoneGrillePropType, PlanActionsType, ZoneGrillePropType } from "../../models/ctrlProp";
import { AgentType, initialAgent, mapListAgents, mapListAgentsForSave } from "../../models/agent";
import { ConfigSocPropType, convertToConfigSocPropType, initialConfigSocProp } from "../../models/configSocProp";
import { FOCUS_VIEW_PROP_GRILLE } from "../../models/consts";
import { fetchGetGrille } from "../../services/getRsd";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import getScreenSize from "../../helpers/getScreenSize";
import makeMbr from "../../helpers/make-mbr";
import TestConect from "../../helpers/test-conect";
import { useNavigation } from "../../components/navigationContext";
import { NOTIFY_VARIANT_INFO, useNotification } from "../../components/notify";
import MenuOngletComponent from "../../components/menu/onglet";
import IdResidComponent from "../../components/idResid/idResid";

const GrilleControlPropScreen: FunctionComponent = () => {
	useConditionalRedirect(GRILLE_CONTROL_PROP_ALLOWED_PATHS);

	const notify = useNotification();
	
	const [mbr, setMbr] = useState({ ...initialMbr });
	const [entry, setEntry] = useState({ ...initialEntry });
	const [grille, setGrille] = useState<GrillePropType>({ ...initialGrilleProp });
	const [listPrestataires, setListPrestataires] = useState<AgentType[]>([]);
	const [listAgents, setListAgents] = useState<AgentType[]>([]);
	const [presta, setPresta] = useState<AgentType>({ ...initialAgent });
	const [configSocProp, setConfigSocProp] = useState<ConfigSocPropType>({ ...initialConfigSocProp });
	const [selectedView, setSelectedView] = useState(MENU_ONGLET_BUTTON_ACCEUIL);
	const [displayType, setDisplayType] = useState(getScreenSize());
	const [screenSize, setScreenSize] = useState(0);
	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	const [isMounted, setIsMounted] = useState(false);

	const { setPreviousPath } = useNavigation();
	const history = useHistory();

	useEffect(() => {
		setPreviousPath(history.location.pathname);
	}, [history.location.pathname, setPreviousPath]);

	useEffect(() => {
		if( !isMounted ) {
			document.title = "CONTROLprop - Grille";

			fetchMbr();
		}
// eslint-disable-next-line
	}, []);
	useEffect(() => {
		const handleResize = () => {
			setDisplayType(getScreenSize());
			setScreenSize(window.innerWidth);
			setIsPortrait(window.innerHeight > window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handelSelectView = ( view: string ) => {
		setSelectedView(view);
	};
	const handelChangeConfig = ( newConfig: ConfigPropType ) => {

	};
	const handelChangePlanActions = ( newPlan: PlanActionsType ) => {

	};
	const handelSigneCtrl = ( sigs: string[] ) => {

	};

	const handelSelectPresta = ( agt: AgentType ) => {

	};

	const fetchMbr = async () => {
		try {
			const user = await makeMbr();

			setMbr(prevMbr => ({
				...prevMbr,
				...user
			}));
					
			testMbr(user);
		} catch(error) {
			console.error('Error', error);
		}
	};
	const testMbr = async ( user: MbrType ) => {
		try {
			const response = await TestConect(user.pseudo, user.mdp, user.mac);

			if( response === false ) {
				history.push(LOGIN_URL);
			} else {
				fetchData(user);
			}
		} catch (error) {
			console.error('Error', error);
		}
	};
	const fetchData = ( user: MbrType ) => {
		try{
			const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);
			const encryptedDataConfSocProp = localStorage.getItem(STORE_PARAM_CONF_PROP);

			if( ( encryptedDataCible !== null ) && ( encryptedDataConfSocProp !== null ) ) {
				const decryptedDataCible = JSON.parse(encryptedDataCible);

				if( decryptedDataCible.entry > 0 ) {
					const encryptedGrille = localStorage.getItem('prop_'+decryptedDataCible.entry.toString());

					if( encryptedGrille !== null ) {
						const decryptedGrille = JSON.parse(encryptedGrille);
						const newGrille = converteToGrilleTypeFromJson(decryptedGrille);

						setGrille(newGrille);

						const decryptedDataConfSocProp = JSON.parse(encryptedDataConfSocProp);
						const newConfigSocProp = convertToConfigSocPropType(decryptedDataConfSocProp);

						setConfigSocProp(newConfigSocProp);

						if( ( newGrille.entry === undefined ) || ( newGrille.entry === null ) || ( newGrille.entry.id !== newGrille.rsd ) ) {
							fetchEntry(user, decryptedDataCible.entry, newGrille);
						} else {
							setEntry(newGrille.entry);

							const encryptedListPrestas = localStorage.getItem('list_prestas');
							const encryptedListAgents = localStorage.getItem('list_agents');

							if( ( encryptedListPrestas !== null ) && ( encryptedListAgents !== null ) ) {
								const newListPrestataires = mapListAgents(JSON.parse(encryptedListPrestas));
								const newListAgents = mapListAgents(JSON.parse(encryptedListAgents));

								setListPrestataires(newListPrestataires);
								setListAgents(newListAgents);
							} else {
								console.error("Error recupe lists");
							}

							setIsMounted(true);
						}
					} else {
						history.push(SELECT_ENTRY_URL);
					}
				} else {
					history.push(SELECT_ENTRY_URL);
				}
			}
		} catch(error) {
			console.log(error);
		}
	};
	const fetchEntry = async ( user: MbrType, idResid: number, thisGrille: GrillePropType ) => {
		try {
			const response = await fetchGetGrille(user.id, user.mac, idResid);
			const grilleToSet = { ...thisGrille };

			if( response.result ) {
				const newFiche = convertToEntryType(response.data.fiche);
				const newPlan = convertToPlanActionsType(response.data.plan);
				const newPrest = convertToPrestatairePropType(response.data.fiche.agt);
				const newListPrestataires = mapListAgents(response.data.list.presta);
				const newListAgents = mapListAgents(response.data.list.agt);

				setEntry(newFiche);

				grilleToSet.entry = newFiche;
				grilleToSet.presta = newPrest;

				if( response.data.grille.grille === 'vide' ) {
					notify("Aucune zone à contrôler sur cette entrée.", { variant: NOTIFY_VARIANT_INFO });
				} else {
					const newGrille = mapToZoneGrillePropType(response.data.grille.grille);
					
					grilleToSet.grille = newGrille;
					grilleToSet.plan = newPlan;

					setGrille(prevState => ({
						...prevState,
						grille: newGrille,
						plan: newPlan,
					}));
				}

				setListPrestataires(newListPrestataires);
        setListAgents(newListAgents);

				protectListsPrestas(newListPrestataires, newListAgents);

				if( response.data.fiche.agt.typ === 'p' ) {
          for( let x in newListPrestataires ) {
            if( newListPrestataires[x].id === newPrest.id ) {
              setPresta(newListPrestataires[x]);
            }
          }
        } else {
          for( let x in newListAgents ) {
            if( newListAgents[x].id === newPrest.id ) {
              setPresta(newListAgents[x]);
            }
          }
        }

				protectGrille(grilleToSet);
			} else {
        console.error(response.data.txt);
      }
		} catch(error) {
      console.error(error);
    }
	};

	const protectListsPrestas = ( listPrestas?: AgentType[], listAgts?: AgentType[] ) => {
		let listToPut = ( listPrestas === undefined ) ? listPrestataires : listPrestas;
		let jsonToPut = JSON.stringify(mapListAgentsForSave(listToPut));

		localStorage.setItem('list_prestas', jsonToPut);

		listToPut = ( listAgts === undefined ) ? listAgents : listAgts;
		jsonToPut = JSON.stringify(mapListAgentsForSave(listToPut));

		localStorage.setItem('list_agents', jsonToPut);
	};
	const protectGrille = ( thisGrille?: GrillePropType ) => {
		let grilleToProtect = ( thisGrille === undefined ) ? grille : thisGrille;

		if( grilleToProtect.entry !== undefined ) {
			let jsonToProtect = convertGrillePropTypeToJson(grilleToProtect, mbr);

			localStorage.setItem('prop_'+grilleToProtect.entry.id, JSON.stringify(jsonToProtect));
		}
	};

	return (
		<Box className="grille-control-mobile-screen">
			<Grid2 className="grille-control-mobile-screen-container"
				container
				component="div"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					height: '100%',
					width: '100%',
				}}
			>
				<Grid2 className="field-applis"
					component="div"
					size={12}
				>
					{ ViewMenuApplis() }
				</Grid2>

				<Grid2 className="field-central"
					component="div"
					size={12}
					sx={{
						height: 'calc(100% - 135px)',
						paddingLeft: '5px',
					}}
				>
					<Grid2 className="field-central-container"
						container
						component="div"
						sx={{
							height: '100%',
						}}
					>
						<Grid2 className="field-menu-onglet"
							component="div"
							size={1}
							sx={{
								height: '100%',
							}}
						>
							<MenuOngletComponent
								mbr={ mbr }
								view={ selectedView }
								entryCtrl={ entry }
								grilleCtrl={ grille }
								onViewSelected={ handelSelectView }
								onConfigChanged={ handelChangeConfig }
								onPlanActionsChanged={ handelChangePlanActions }
								onSignature={ handelSigneCtrl }
							/>
						</Grid2>

						<Grid2 className="field-principal"
							component="div"
							size={11}
							sx={{
								paddingLeft: '15px',
								width: 'calc(100% - 45px)',
								height: '100%',
							}}
						>
							{ ( selectedView !== FOCUS_VIEW_PROP_GRILLE ) &&
								ViewAcceuil(
									mbr,
									entry,
									listPrestataires,
									listAgents,
									presta,
									grille.grille,
									handelSelectPresta,
								)
							}
						</Grid2>
					</Grid2>
				</Grid2>

				<Grid2 className="field-vignette"
					component="div"
					size={8}
				>
					<img src={ logo } alt="vignette"/>
				</Grid2>
			</Grid2>
		</Box>
	);
};



/* VIEWS */
const ViewMenuApplis = () => {
	return (
		<Box className="menu-applis-view">
			<Grid2 className="menu-appli-view-container"
				container
				component="div"
				justifyContent="space-evenly"
				sx={{
					marginTop: '5px',
				}}
			>
				<Grid2 className="field-prop"
					component="div"
					size={2}
				>
					<img src={ goutte } alt="goutte"/>
				</Grid2>
				
				<Grid2 className="field-secu"
					component="div"
					size={2}
				>
					<img src={ cone } alt="cobe"/>
				</Grid2>

				<Grid2 className="field-psp"
					component="div"
					size={2}
				>
					<img src={ goutte } alt="goutte"/>
				</Grid2>
			</Grid2>
		</Box>
	);
};

const ViewAcceuil = (
	mbr: MbrType,
	entry: EntryType,
	listPrestas: AgentType[],
	listAgents: AgentType[],
	prestaSelected: AgentType,
	grille: ZoneGrillePropType[],
	onSelectedPresta: ( agt: AgentType ) => void,
) => {
	return (
		<Box className="acceuil-view"
			sx={{
				height: '100%',
			}}
		>
			<Grid2 className="acceuil-view-container"
				container
				component="div"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					height: '100%',
				}}
			>
				<Grid2 className="field-entry"
					component="div"
					size={12}
				>
					<IdResidComponent
						mbr={ mbr }
						fiche={ entry }
						listPrestataires={ listPrestas }
						listAgents={ listAgents }
						presta={ prestaSelected }
						onSelectedPresta={ onSelectedPresta }
					/>
				</Grid2>

				<Grid2 className="field-zones"
					component="div"
					size={12}
					sx={{
						height: 'calc(100% - 15em)',
						overflow: 'hidden',
					}}
				>
					{ ViewZones(grille) }
				</Grid2>
			</Grid2>
		</Box>
	);
};
const ViewZones = (
	grille: ZoneGrillePropType[],
) => {
	return (
		<Box className="grille-zone-view"
			sx={{
				height: '100%',
				overflowY: 'scroll',
			}}
		>
			<Grid2 className="grille-zone-view-container"
				container
				justifyContent="space-between"
				alignItems="center"
			>
				{ Object.values(grille).map((zone) => {
					var icon = localisation;

					switch( zone.id ) {
						case 1: icon = abords; break;
						case 2: icon = hall; break;
						case 16:
						case 3: icon = ascenseur; break;
						case 4: icon = escalier; break;
						case 19:
						case 5: icon = palier; break;
						case 17:
						case 6: icon = localOm; break;
						case 7: icon = localVelo; break;
						case 8: icon = cave; break;
						case 9: icon = parkSS; break;
						case 10: icon = cour; break;
						case 11: icon = parkExt; break;
						case 12: icon = espaceExt; break;
						case 13: icon = burreau; break;
						case 14: icon = reunion; break;
						case 15: icon = sanitaire; break;
						case 18: icon = poussette; break;
						default: icon = localisation; break;
					}

					return (
						<Grid2 className="btn-open-zone"
							component="div"
							key={ zone.id }
							size={4}
						>
							<Box
								sx={{
									width: '95%',
									margin: '0 auto 5px',
									borderRadius: '5px',
									padding: '2px',
									textAlign: 'center',
									height: '5.5em',
									position: 'relative',
								}}
							>
								<Typography
									sx={{
										float: 'right',
									}}
								>
									{ ( zone.note ) ? `${ zone.note }%` : 'SO' }
								</Typography>

								<img src={ icon } alt={ zone.id.toString() }/>

								<Typography
									sx={{
										fontSize: '8pt',
										color: '#888',
										position: 'absolute',
										bottom: '5px',
										width: '95%',
									}}
								>
									{ ( zone.txt ) ? zone.txt : 'divers' }
								</Typography>
							</Box>
						</Grid2>
					)
				})}
			</Grid2>
		</Box>
	);
};

export default GrilleControlPropScreen;
