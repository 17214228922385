
/*	PROD	*/

import { Box, Button, Grid2 } from "@mui/material";
import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./style/controlProp.css";

import vignette from "../../assets/ORGAprop_bonhomme.png";
import logo from "../../assets/controlprop.png";

import { CONTROL_PROP_CONFIGURATION_URL, CONTROL_PROP_ENTRY_SELECT_URL, CONTROL_PROP_MOBILE_URL, CONTROL_PROP_OBJECTIFS_URL, CONTROL_PROP_PLANS_ACTIONS_URL, LOGIN_URL, MOBILE_CONTROL_PROP_ALLOWED_PATH, PROFIL_URL } from "../../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../../models/mbr";
import { initialMapSelectionType, MapSelectionType } from "../../models/mapSelection";
import { STORE_PARAM_BACK_PAGE, STORE_PARAM_CIBLE } from "../../models/consts/store";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import getScreenSize from "../../helpers/getScreenSize";
import makeMbr from "../../helpers/make-mbr";
import TestConect from "../../helpers/test-conect";
import StatComponent from "../../components/statistiques/statistiques";
import { useNavigation } from "../../components/navigationContext";

const ControlPropMobileScreen: FunctionComponent = () => {
  useConditionalRedirect(MOBILE_CONTROL_PROP_ALLOWED_PATH);

  const [mbr, setMbr] = useState<MbrType>({ ...initialMbr });
  const [mapSelection, setMapSelection] = useState<MapSelectionType>({ ...initialMapSelectionType });
  const [displayType, setDisplayType] = useState(getScreenSize());
  const [screenSize, setScreenSize] = useState(0);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMounted, setIsMounted] = useState(false);
  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
    if( !isMounted ) {
			document.title = 'CONTROLprop';

      fetchMbr();
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setDisplayType(getScreenSize());
      setScreenSize(window.innerWidth);
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if( isMounted && ( mapSelection.entry > 0 ) ) {
      openGrille();
    }
// eslint-disable-next-line
  }, [mapSelection]);

  const handelStartItemClicked = ( view: string ) => {
    switch( view ) {
      case 'profil': openProfil(); break;
      case 'objectifs': openObjectifs(); break;
      case 'plans': openPlansActions(); break;
      case 'start': openGrille(); break;
    }
  };

	const fetchMbr = async () => {
		try {
			const user = await makeMbr();

			setMbr(prevMbr => ({
				...prevMbr,
				...user
			}));
					
			testMbr(user);
		} catch(error) {
			console.error('Error', error);
		}
	};
  const testMbr = async ( user: MbrType ) => {
    try {
      const response = await TestConect(user.pseudo, user.mdp, user.mac);

      if( response === false ) {
        history.push(LOGIN_URL);
      } else {
        fetchData();
      }
    } catch (error) {
      console.error('Error', error);
    }
  };
  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

    if( encryptedDataCible !== null ) {
      const decryptedDataCible = JSON.parse(encryptedDataCible);

      var newSelection = { ...initialMapSelectionType };

      newSelection.agc = ( decryptedDataCible.agc === null ) ? 0 : decryptedDataCible.agc;
      newSelection.grp = ( decryptedDataCible.grp === null ) ? 0 : decryptedDataCible.grp;
      newSelection.rsd = ( decryptedDataCible.rsd === null ) ? 0 : decryptedDataCible.rsd;
      newSelection.entry = ( decryptedDataCible.entry === null ) ? 0 : decryptedDataCible.entry;

      if( ( decryptedDataCible.entry !== null ) && ( decryptedDataCible.entry > 0 ) ) {
        openGrille();
      }

      setIsMounted(true);
    }
  };

  const openProfil = () => {
    localStorage.setItem(STORE_PARAM_BACK_PAGE, CONTROL_PROP_MOBILE_URL);

    history.push(PROFIL_URL);
  };
  const openObjectifs = () => {
    localStorage.setItem(STORE_PARAM_BACK_PAGE, CONTROL_PROP_MOBILE_URL);

    history.push(CONTROL_PROP_OBJECTIFS_URL);
  };
  const openPlansActions = () => {
    localStorage.setItem(STORE_PARAM_BACK_PAGE, CONTROL_PROP_PLANS_ACTIONS_URL);

    history.push(CONTROL_PROP_PLANS_ACTIONS_URL);
  };
  const openGrille = () => {
    if( mapSelection.entry > 0 ) {
      history.push(CONTROL_PROP_CONFIGURATION_URL);
    } else {
      history.push(CONTROL_PROP_ENTRY_SELECT_URL);
    }
  };

  return (
    <Box className="control-prop-screen">
      <StatComponent
        mbr={ mbr }
        mapSelection={ mapSelection }
      >
        { ( isPortrait ) &&
          <Grid2 className={ `control-prop-container portrait-view ${ displayType }-size` }
            container
            component="div"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
							height: '100%',
            }}
          >
            <Grid2 className="field-vignette"
              component="div"
              size={12}
            >
              { ViewVignette(mbr, isPortrait, handelStartItemClicked) }
            </Grid2>

            <Grid2 className="field-objectifs"
              component="div"
              size={10}
              onClick={ e => handelStartItemClicked('objectifs') }
            >
              <StatComponent.MiniObjectifsProp/>
            </Grid2>

            <Grid2 className="field-plans-actions"
              component="div"
              size={10}
              onClick={ e => handelStartItemClicked('plans') }
            >
              <StatComponent.MiniPlansActions/>
            </Grid2>

            <Grid2 className="field-start"
              component="div"
              size={12}
            >
              { ViewFooter(handelStartItemClicked) }
            </Grid2>
          </Grid2>
        }
        { ( !isPortrait ) &&
          <Grid2 className={ `control-prop-mobile-container landscape-view ${ displayType }-size` }
            container
            component="div"
            alignItems="center"
            sx={{
              height: '100%',
            }}
          >
            <Grid2 className="left-field"
              component="div"
              size={6}
            >
              <Grid2 className="left-container"
                container
                component="div"
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: '100vh',
                }}
              >
                <Grid2 className="field-profil"
                  component="div"
                  size={12}
                  sx={{
                    marginTop: '20px',
                  }}
                >
                  { ViewVignette(mbr, isPortrait, handelStartItemClicked) }
                </Grid2>
                
                <Grid2 className="field-start"
                  component="div"
                  size={12}
                >
                  { ViewFooter(handelStartItemClicked) }
                </Grid2>
              </Grid2>
            </Grid2>

            <Grid2 className="right-field"
              component="div"
              size={6}
            >
              <Grid2 className="right-container"
                container
                component="div"
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{
                  height: '100vh',
                }}
              >
                <Grid2 className="field-objectifs"
                  component="div"
                  size={10}
                  onClick={ e => handelStartItemClicked('objectifs') }
                >
                  <StatComponent.MiniObjectifsProp/>
                </Grid2>

                <Grid2 className="field-plans-actions"
                  component="div"
                  size={10}
                  onClick={ e => handelStartItemClicked('plans') }
                >
                  <StatComponent.MiniPlansActions/>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        }
      </StatComponent>
    </Box>
  )
};

const ViewVignette = (
  mbrView: MbrType,
  isPortrait: boolean,
  onClick: ( view: string ) => void,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-evenly' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
      onClick={ e => onClick('profil') }
    >
      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img src={ vignette } alt="vignette" className="vignette"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={4}>
        { `Bonjour, ${ mbrView.txt }` }
      </Grid2>
    </Grid2>
  );
};
const ViewFooter = (
  onClickBtn: ( view: string ) => void
) => {
  return (
    <Grid2 className="field-footer"
      container
      component="div"
      direction="column"
      alignItems="center"
    >
      <Grid2 component="div" size={12} sx={{ textAlign: 'center', marginTop: 1 }}>
        <Button variant="contained" onClick={ e => onClickBtn('start') }>Démarrer un contrôle</Button>
      </Grid2>

      <Grid2 component="div" size={6} sx={{ marginTop: 2, marginBottom: 2 }}>
        <img src={ logo } alt="logo" className="logo"/>
      </Grid2>
    </Grid2>
  );
};

export default ControlPropMobileScreen;
