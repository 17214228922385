
/*	PROD	*/

import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';

import './signature.css';

import { MbrType } from '../../models/mbr';

import { fetchSetSignature } from '../../services/setCtrlProp';
import { Box, Button } from '@mui/material';

interface SignatureProps {
  mbr: MbrType;
  idCtrl: number;
  numberOfSignatures: number;
  isSigned: boolean;
  onSignature: ( sigs: string[] ) => void;
};

const SignatureComponent: FunctionComponent<SignatureProps> = ( {
	mbr,
	idCtrl,
	numberOfSignatures,
	isSigned,
	onSignature,
}) => {
  const [canModify, setCanModify] = useState(true);
  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const signaturePads = useRef<SignaturePad[]>([]);

  useEffect(() => {
    canvasRefs.current.forEach((canvas, index) => {
      if( canvas ) {
        signaturePads.current[index] = new SignaturePad(canvas);
      }
    });
  }, []);

  useEffect(() => {
    setCanModify(!isSigned);
  }, [isSigned]);

  const handelClearSignature = (index: number) => {
    if( canModify ) {
      signaturePads.current[index].clear();
    }
  };

  const saveAllSignatures = async () => {
    if( canModify && ( idCtrl > 0 ) ) {
      const savedSignatures = signaturePads.current.map(( signaturePad, index ) => {
        if( !signaturePad.isEmpty() ) {
          const dataURL = signaturePad.toDataURL();

          return dataURL;
        } else {
          return '';
        }
      });
      
      signaturePads.current.forEach(( signaturePad ) => {
        signaturePad.off();
      });

      try {
        const response = await fetchSetSignature(mbr.id, mbr.mac, idCtrl, savedSignatures);

        if( ( response.result !== undefined ) && response.result ) {
          onSignature(response.data);
        } else if( response.data !== undefined ) {
          console.error(response.data.txt);
        }
      } catch( error ) {
        console.error(error);
      }

      onSignature(savedSignatures);
      setCanModify(false);
    }
  };

  return (
    <Box className="signature-component"
			sx={{
				height: '100%',
			}}
		>
      {[...Array(numberOfSignatures)].map((_, index) => (
        <Box className="field-signature"
					key={index}
				>
          <canvas ref={ (el) => (canvasRefs.current[index] = el) }/>

          <Button className="btn-clear-signature"
						onClick={ () => handelClearSignature(index) }
						sx={{
							marginLeft: '5%',
						}}
					>
            Vider
          </Button>
        </Box>
      ))}

      <Button  className="btn-save"
				variant="contained"
        sx={{
					marginTop: '30px',
					marginLeft: '70%',
				}}
        onClick={ saveAllSignatures }
			>
				Signer
			</Button>
    </Box>
  );
};

export default SignatureComponent;
