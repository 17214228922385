
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, List } from "@mui/material";

import 'react-tooltip/dist/react-tooltip.css';
import './index.css';

import { MbrType } from "../../models/mbr";
import { EntryType, mapToEntryType } from "../../models/entry";
import { FOCUS_VIEW_MENU } from "../../models/consts";
import { fetchSearchRsd } from "../../services/getSearch";
import InputComponent from "../balises/input";
import BtnEntry from "../balises/buttons/btnEntry";

interface SearchEntryProps {
  mbr: MbrType;
  focusTo?: string;
  list?: EntryType[];
  callFocus?: ( v: string ) => void;
  onEntrySelected: ( agc: number, grp: number, rsd: number, entry: number ) => void;
};

const SearchEntryComponent: FunctionComponent<SearchEntryProps> = ({
  mbr,
  focusTo,
  list,
  callFocus,
  onEntrySelected,
}) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [listResult, setListResult] = useState<EntryType[]>([]);
  const [showListResult, setShowListResult] = useState(false);
  const [haveFocus, setHaveFocus] = useState(false);

  useEffect(() => {
    if( list !== undefined ) {
      setListResult(list);
      setShowListResult(true);
    }
  }, [list])

  useEffect(() => {
    if( ( focusTo === FOCUS_VIEW_MENU ) && !haveFocus ) {
      setHaveFocus(true);
    } else if( haveFocus && ( focusTo !== FOCUS_VIEW_MENU ) ) {
      setHaveFocus(false);
    }
// eslint-disable-next-line
  }, [focusTo]);

  const handleInputChanged = ( v: string | number | null ) => {
    if( typeof v === 'string' ) {
      setQuery(v);
    }
  };
  const handleSearch = () => {
    if( ( list !== undefined ) && ( query !== '' ) ) {
      setLoading(true);
      fetchData();

      if( callFocus !== undefined ) {
        callFocus(FOCUS_VIEW_MENU);
      }
    }
  };
  const handleEntrySelected = (item: EntryType) => {
    setShowListResult(false);
    onEntrySelected(item.agence.id, item.groupement.id, item.residence.id, item.id);
  };
  const handleKeyDown = ( e: string, m: string | number | null ) => {
    if( ( callFocus !== undefined ) && !haveFocus ) {
      callFocus(FOCUS_VIEW_MENU);
    }
    if( e === 'Enter' ) {
      handleSearch();
    } else if( ( list !== undefined ) && ( m !== null ) ) {
      setQuery(( ( typeof m === 'string' ) ? m : m.toString() ));
      filtreList();
    }
  };

  const fetchData = async () => {
    try{
      const response = await fetchSearchRsd(mbr.id, mbr.mac, query);

      if( response.result ) {
        const newListe = mapToEntryType(response.data);

        setListResult(newListe)
        setLoading(false);
        setShowListResult(true);
      } else {
        setLoading(false);
      }
    } catch( error ) {
      console.error(error);
    }
  };
  const filtreList = () => {
    if( query !== '' ) {
      var newList: EntryType[] = [];
      let c = listResult.length;

      for( let i = 0; i < c; i++ ) {
        var item = listResult[i];
        let b = false;

        if( item.adresse.adr.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.adresse.cp.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.adresse.ville.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.agence.txt.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.groupement.txt.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.ref.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.residence.txt.indexOf(query) >= 0 ) {
          b = true;
        }
        if( item.txt.indexOf(query) >= 0 ) {
          b = true;
        }

        if( b ) {
          newList.push(item);
        }
      }

      setListResult(newList);
    }
  };

  return (
    <Box className="search-entry-component">
      <InputComponent
        name="search-input"
        label="Rechercher..."
        value={ query }
        type="search"
        error={ false }
        onChange={ handleInputChanged }
        onFinish={ handleSearch }
        onKeyPressed={ handleKeyDown }
      />

      <Box className="container-list-search" style={ { display: ( showListResult ) ? 'block' : 'none' } }>
        { ViewListSearchResult(listResult, handleEntrySelected) }
      </Box>
    </Box>
  );
};

const ViewListSearchResult = (
  list: EntryType[],
  onEntrySelected: ( entry: EntryType ) => void,
) => {
  return (
    <Box>
      <List>
        {
          list.map(( item ) => (
            <Button className="b-entry-result-search"
              key={ item.id }
              onClick={ () => onEntrySelected(item) }
            >
              <BtnEntry entry={ item } onClicked={ onEntrySelected }/>
            </Button>
          ))
        }
      </List>
    </Box>
  );
};

export default SearchEntryComponent;
