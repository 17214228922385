
/*	PROD	*/

import React, { FunctionComponent } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { createTheme, CssBaseline, ThemeOptions, ThemeProvider } from "@mui/material";

import "./screens/styles/app.css";

import { LOGIN_URL, RECUP_MAIL_URL, ACCEUIL_URL, PROFIL_URL, CONTROL_PROP_OBJECTIFS_URL, CONTROL_PROP_DESKTOP_URL, CONTROL_PROP_MOBILE_URL, CONTROL_PROP_ENTRY_SELECT_URL, CONTROL_PROP_CONFIGURATION_URL, CONTROL_PROP_PLANS_ACTIONS_URL, CONTROL_PROP_GRILLE_URL } from "./models/consts/aplliUrls";

import Login from "./screens/login";
import RecupMail from "./screens/recupMail";
import ControlProp from "./screens/controlProp/controlPropDesktop";
import AcceuilScreen from "./screens/acceuil";
import ProfilScreen from "./screens/profil";
import ObjectifsScreen from "./screens/controlProp/objectifsMobile";
import ControlPropMobileScreen from "./screens/controlProp/controlPropMobile";
import SelectEntryScreen from "./screens/selectEntryMobile";
import ConfigurationControlProp from "./screens/controlProp/configurationMobile";
import PlanActionsScreen from "./screens/controlProp/planActionsMobile";
import GrilleControlPropScreen from "./screens/controlProp/grilleControlMobile";

import { NavigationProvider } from "./components/navigationContext";
import { AppWrapper } from "./components/notify";

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#45b40b',
      contrastText: '#f5f5f5',
    },
    secondary: {
      main: '#f44336',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            overflowY: 'auto',
            overflowX: 'hidden',
            wordWrap: 'break-word',
          },
          '& textarea': {
            resize: 'none',
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },
  }
};

const theme = createTheme(themeOptions);

const App: FunctionComponent = () => {
  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline/>
      <AppWrapper>
        <Router>
          <NavigationProvider>
            <AppBody />
          </NavigationProvider>
        </Router>
      </AppWrapper>
    </ThemeProvider>
  );
}

const AppBody: FunctionComponent = () => {
  return (
    <div className="app-body">
      <Switch>
        <Route
          path={ RECUP_MAIL_URL }
          component={ RecupMail }
        />
        <Route
          path={ ACCEUIL_URL }
          component={ AcceuilScreen }
        />
        <Route
          path={ PROFIL_URL }
          component={ ProfilScreen }
        />
        <Route exact
          path={ CONTROL_PROP_DESKTOP_URL }
          component={ ControlProp }
        />
        <Route exact
          path={ CONTROL_PROP_MOBILE_URL }
          component={ ControlPropMobileScreen }
        />
        <Route exact
          path={ CONTROL_PROP_OBJECTIFS_URL }
          component={ ObjectifsScreen }
        />
				<Route exact
          path={ CONTROL_PROP_PLANS_ACTIONS_URL }
          component={ PlanActionsScreen }
        />
        <Route exact
          path={ CONTROL_PROP_ENTRY_SELECT_URL }
          component={ SelectEntryScreen }
        />
        <Route exact
          path={ CONTROL_PROP_CONFIGURATION_URL }
          component={ ConfigurationControlProp }
        />
				<Route exact
					path={ CONTROL_PROP_GRILLE_URL }
					component={ GrilleControlPropScreen }
				/>
        <Route exact
          path={ LOGIN_URL }
          component={ Login }
        />
        <Redirect to={ LOGIN_URL } />
      </Switch>
    </div>
  );
};

export default App;
