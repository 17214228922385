
/*	PROD	*/

import { PARAM_LOGIN_TEST } from "../models/consts/login";
import fetchLogin from "../services/login";

const TestConect = async ( pseudo: string, mdp: string, mac: string ) => {
  try {
    const response = await fetchLogin(pseudo, mdp, mac, PARAM_LOGIN_TEST);

    return response.result
  } catch (error) {
    console.error(error);

    return false;
  }
}

export default TestConect;
