
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { useNotification } from "../notify";

import { MbrType } from "../../models/mbr";
import { EntryType, mapToEntryType } from "../../models/entry";
import { fetchGetObjectifsControleur } from "../../services/getStatistiques";
import InputComponent from "../balises/input";
import LoadingMessage from "../balises/buttons/loadingMessage";

interface ObjectifsComponentProps {
  mbr: MbrType;
	onResidenceSelected: ( entry: EntryType ) => void;
};

const ObjectifsComponent: FunctionComponent<ObjectifsComponentProps> = ({
	mbr,
	onResidenceSelected,
}) => {
	const notify = useNotification();

  const [listNoControl, setListNoControl] = useState<EntryType[]>([]);
	const [listToShow, setListToShow] = useState<EntryType[]>([]);
	const [message, setMessage] = useState('Patientez ...');
	const [showMessage, setShowMessage] = useState(true);
	const [erreur, setErreur] = useState(false);

	useEffect(() => {
		if( listNoControl.length === 0 ) {
			fetchData();
		}
// eslint-disable-next-line
	}, []);

	const handelKeyPressed = ( caracter: string, val: string | number | null ) => {
		if( typeof val === 'string' ) {
			filtreListToShow(val);
		}
	};
	const handelResidenceSelected = ( entry: EntryType ) => {
		onResidenceSelected(entry);
	};

	const fetchData = async () => {
		if( mbr.id > 0 ) {
			try {
				const response = await fetchGetObjectifsControleur(mbr.id, mbr.mac);

				if( response.result ) {
					if( response.data.nb > 0 ) {
						const newListEntry = mapToEntryType(response.data.list);

						setListNoControl(newListEntry);
						setListToShow(newListEntry);
						setShowMessage(false);
					} else {
						notify(`👉 ${response.data.txt}`, { variant: 'success' });
						setMessage(response.data.txt);
						setErreur(true);
						setShowMessage(true);
					}
				} else {
					console.error(response.data.txt);
					setMessage(response.data.txt);
					setErreur(true);
					setShowMessage(true);
				}
			} catch(error) {
				console.error(error);
				setMessage('Une erreur s\'produite lors de la récupération des données');
				setErreur(true);
				setShowMessage(true);
			}
		}
	};

	const filtreListToShow = ( val: string ) => {
		const newList: EntryType[] = [];

		for( var x in listNoControl ) {
			let item = listNoControl[x];
			let b = false;

			if( item.adresse.adr.indexOf(val) >= 0 ) b = true;
			if( item.adresse.cp.indexOf(val) >= 0 ) b = true;
			if( item.adresse.ville.indexOf(val) >= 0 ) b = true;
			if( item.agence.txt.indexOf(val) >= 0 ) b = true;
			if( item.entry.indexOf(val) >= 0 ) b = true;
			if( item.groupement.txt.indexOf(val) >= 0 ) b = true;
			if( item.ref.indexOf(val) >= 0 ) b = true;
			if( item.residence.txt.indexOf(val) >= 0 ) b = true;
			if( item.txt.indexOf(val) >= 0 ) b = true;

			if( b ) {
				newList.push(item);
			}
		}

		setListToShow(newList);
	};

	return (
		<Box className="objectifs-component" sx={{ height: '100%' }}>
			{ ( showMessage && erreur ) &&
				<Box
					sx={{
						textAlign: 'center',
						marginTop: '5em',
						fontWeight: 'bold',
						color: '#45b40b',
					}}
				>
					{ message }
				</Box>
			}
			{ ( showMessage && ! erreur ) &&
				<Box
					sx={{
						textAlign: 'center',
						marginTop: '5em',
					}}
				>
					<LoadingMessage/>
				</Box>
			}
			{ ( !showMessage ) &&
				<Grid2
					container
					component="div"
					direction="column"
					justifyContent="flex-start"
					alignItems="center"
					sx={{ height: '100%' }}
				>
					<Grid2 component="div" size={ 12 }>
						<InputComponent
							name="search"
							label=""
							value=""
							type="search"
							error={ false }
							onKeyPressed={ handelKeyPressed }
						/>
					</Grid2>

					<Grid2
						component="div"
						size={ 12 }
						sx={{
							height: 'calc(100% - 60px)',
							width: '100%',
							overflow: 'hidden',
						}}
					>
						<Box
							sx={{
								height: 'calc(100% - 10px)',
								width: 'calc(100% + 15px)',
								overflowX: 'hidden',
								overflowY: 'scroll',
								marginTop: '10px',
							}}
						>
							{ listToShow.map((item) => {
								return (
									<Button
										key={ item.id }
										onClick={ e => handelResidenceSelected(item) }
										sx={{ width: '100%' }}
									>
									</Button>
								)
							})}
						</Box>
					</Grid2>
				</Grid2>
			}
		</Box>
	);
};

export default ObjectifsComponent;
