
/*	PROD	*/

import axios from "axios";
import { API_URL_SEARCH } from "../models/consts/apiUrls";

export async function fetchSearchRsd( i: number, m: string, v: string ) {
  try {
    const url = API_URL_SEARCH;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'getSearch.tsx::fetchSearchRsd::Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
}

