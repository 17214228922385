
// recevied datas

type ReceveidAutoSend = {
  val: number,
  dest: string,
};
type ReceveidLimtesProp = {
  basse: number,
  haute: number,
};
type ReceveidConfigSocProp = {
  limites: ReceveidLimtesProp,
  auto_send: ReceveidAutoSend,
};

type AutoSendType = {
  val: number,
  destinataire: string,
};
type LimitesPropType = {
  basse: number,
  haute: number,
};
export type ConfigSocPropType = {
  limites: LimitesPropType,
  autoSend: AutoSendType,
};

const initialAutoSend: AutoSendType = {
  val: 101,
  destinataire: '',
};
const initialLimites: LimitesPropType = {
  basse: 45,
  haute: 70,
};
export const initialConfigSocProp: ConfigSocPropType = {
  limites: { ...initialLimites },
  autoSend: {  ...initialAutoSend },
};

const convertToAutoSendType = ( data: ReceveidAutoSend ): AutoSendType => {
  return {
    val: data.val,
    destinataire: data.dest,
  };
};
const convertToLimitesPropType = ( data: ReceveidLimtesProp ): LimitesPropType => {
  return {
    basse: data.basse,
    haute: data.haute,
  };
};
export const convertToConfigSocPropType = ( data: ReceveidConfigSocProp ): ConfigSocPropType => {
  return {
    limites: convertToLimitesPropType(data.limites),
    autoSend: convertToAutoSendType(data.auto_send),
  };
};
