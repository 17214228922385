
/*	PROD	*/

const API_URL = "https://api.orgaprop.com/";

const API_URL_MBR_GET = API_URL + 'mbr/get/';
const API_URL_MBR_SET = API_URL + 'mbr/set/';

export const API_URL_GET_RECUP_MAIL = API_URL_MBR_GET + "mail.php";
export const API_URL_SET_LOGIN = API_URL_MBR_SET + "login.php";
export const API_URL_SET_FICHE = API_URL_MBR_SET + "fiche.php";

const API_URL_SOC_GET = API_URL + 'soc/get/';
const API_URL_SOC_SET = API_URL + 'soc/set/';

export const API_URL_GET_LISTS = API_URL_SOC_GET + "list.php";
export const API_URL_SEARCH = API_URL_SOC_GET + "search.php";
export const API_URL_UPLOAD_IMG = API_URL_SOC_SET + "uploadImg.php";

const API_URL_RSD_GET = API_URL + 'rsd/get/';

export const API_URL_GET_FICHE_RESIDENCE = API_URL_RSD_GET + "fiche.php";

const API_URL_PROP_MBR_GET = API_URL + 'ctrlProp/mbr/get/';
const API_URL_PROP_SOC_GET = API_URL + 'ctrlProp/soc/get/';
const API_URL_PROP_RSD_GET = API_URL + 'ctrlProp/rsd/get/';
const API_URL_PROP_RSD_SET = API_URL + 'ctrlProp/rsd/set/';

export const API_URL_GET_MEMBRE_STATISTIQUES = API_URL_PROP_MBR_GET + "stat.php";
export const API_URL_GET_MEMBRE_PLANS_ACTIONS = API_URL_PROP_MBR_GET + "plans.php";
export const API_URL_GET_MEMBRE_OBEJECTIFS = API_URL_PROP_MBR_GET + "objectifs.php";
export const API_URL_GET_SOCIETE_STATISTIQUES = API_URL_PROP_SOC_GET + "stat.php";
export const API_URL_GET_SOCIETE_PLANS_ACTIONS = API_URL_PROP_SOC_GET + "plans.php";
export const API_URL_GET_RESIDENCE_STATISTIQUES = API_URL_PROP_RSD_GET + "stat.php";
export const API_URL_GET_GRILLE_CTRLPROP = API_URL_PROP_RSD_GET + "grille.php";
export const API_URL_GET_RESIDENCE_PLAN_ACTIONS = API_URL_PROP_RSD_GET + "plan.php";
export const API_URL_SET_GRILLE_CTRLPROP = API_URL_PROP_RSD_SET + "grille.php";
export const API_URL_SET_SIGNATURE = API_URL_PROP_RSD_SET + "signe.php";
export const API_URL_SET_PLANS_ACTIONS = API_URL_PROP_RSD_SET + "plan.php";
