
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Button, Grid2, Typography } from "@mui/material";

import './onglet.css';

import { MENU_ONGLET_BUTTON_CONFIG, MENU_ONGLET_BUTTON_PLAN, MENU_ONGLET_BUTTON_SEND, MENU_ONGLET_BUTTON_SIGNATURE, MENU_ONGLET_HIDDEN } from "../../models/consts/ctrlProp";
import { VALIDATION_VIEW_SELECTED } from "../../models/validations";
import { ConfigPropType, GrillePropType, initialConfigProp, PlanActionsType } from "../../models/ctrlProp";
import ConfigurationPropComponent from "../grilleControlProp/configProp/configuration";
import PlanActionsComponent from "../grilleControlProp/planActions";
import { EntryType } from "../../models/entry";
import { MbrType } from "../../models/mbr";
import SignatureComponent from "../signature/signature";
import SendRapportComponent from "../sendRapport";

interface MenuOngletComponentProps {
	mbr: MbrType;
  view: string;
	entryCtrl: EntryType;
	grilleCtrl: GrillePropType;
  onViewSelected: ( view: string ) => void;
	onConfigChanged: ( newConf: ConfigPropType ) => void;
	onPlanActionsChanged: ( newPlan: PlanActionsType ) => void;
	onSignature: ( sigs: string[] ) => void;
};

const MenuOngletComponent: FunctionComponent<MenuOngletComponentProps> = ( {
	mbr,
	view,
	entryCtrl,
	grilleCtrl,
	onViewSelected,
	onConfigChanged,
	onPlanActionsChanged,
	onSignature,
} ) => {
  const [viewSelected, setViewSelected] = useState(MENU_ONGLET_HIDDEN);
	const [isOpen, setIsOpen] = useState(false);
	const [canSendRapport, setCanSendRapport] = useState(false);

  useEffect(() => {
    if( VALIDATION_VIEW_SELECTED.includes(view) ) {
      setViewSelected(view);
    }
  }, [view]);
	useEffect(() => {
		if( ( grilleCtrl.id !== undefined ) && ( grilleCtrl.id > 0 ) && ( grilleCtrl.saved === true ) ) {
			setCanSendRapport(true);
		} else {
			setCanSendRapport(false);
		}
	}, [grilleCtrl]);


  const handelSelectConfig = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_CONFIG ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
			setIsOpen(false);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_CONFIG);
      onViewSelected(MENU_ONGLET_BUTTON_CONFIG);
			setIsOpen(true);
    }
  };
  const handelSelectSend = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_SEND ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
			setIsOpen(false);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_SEND);
      onViewSelected(MENU_ONGLET_BUTTON_SEND);
			setIsOpen(true);
    }
  };
  const handelSelectPlan = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_PLAN ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
			setIsOpen(false);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_PLAN);
      onViewSelected(MENU_ONGLET_BUTTON_PLAN);
			setIsOpen(true);
    }
  };
  const handelSelectSignature = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_SIGNATURE ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
			setIsOpen(false);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_SIGNATURE);
      onViewSelected(MENU_ONGLET_BUTTON_SIGNATURE);
			setIsOpen(true);
    }
  };

	const handelConfigChanged = ( newConf: ConfigPropType ) => {
		onConfigChanged(newConf);
	};
	const handelPlanActionsChanded = ( newPlan: PlanActionsType ) => {
		onPlanActionsChanged(newPlan);
	};
	const handelCtrlSigned = ( sigs: string[] ) => {
		onSignature(sigs);
	};

  return (
    <Box className="menu-onglet-component">
      <Grid2 className="menu-onglet-container"
				container
				component="div"
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
			>
        <Grid2 className="field-btn-config"
					component="div"
					size={12}
				>
					<Button className="btn-vertical"
						variant={ ( viewSelected === MENU_ONGLET_BUTTON_CONFIG ) ? 'contained' : 'outlined' }
						onClick={ handelSelectConfig }
						sx={{
							padding: '10px 5px',
							minWidth: '100%',
						}}
					>
						Configuration
					</Button>
				</Grid2>
        
				<Grid2 className="field-btn-plan"
					component="div"
					size={12}
				>
					<Button className="btn-vertical"
						variant={ ( viewSelected === MENU_ONGLET_BUTTON_PLAN ) ? 'contained' : 'outlined' }
						onClick={ handelSelectPlan }
						sx={{
							padding: '10px 5px',
							minWidth: '100%',
						}}
					>
						Plan d'actions
					</Button>
				</Grid2>

        <Grid2 className="field-btn-signature"
					component="div"
					size={12}
				>
					<Button className="btn-vertical"
						variant={ ( viewSelected === MENU_ONGLET_BUTTON_SIGNATURE ) ? 'contained' : 'outlined' }
						onClick={ handelSelectSignature }
						sx={{
							padding: '10px 5px',
							minWidth: '100%',
						}}
					>
						Signer
					</Button>
				</Grid2>

        <Grid2 className="field-btn-send"
					component="div"
					size={12}
				>
					<Button className="btn-vertical"
						variant={ ( viewSelected === MENU_ONGLET_BUTTON_SEND ) ? 'contained' : 'outlined' }
						onClick={ handelSelectSend }
						sx={{
							padding: '10px 5px',
							minWidth: '100%',
						}}
					>
						Envoyer
					</Button>
				</Grid2>
      </Grid2>

			<Box className="drawer-container">
				<Box className={`drawer ${ ( isOpen ) ? 'open' : ''}`}>
					{ ( viewSelected === MENU_ONGLET_BUTTON_CONFIG ) && ViewConfig(grilleCtrl.config, handelConfigChanged) }
					{ ( viewSelected === MENU_ONGLET_BUTTON_PLAN ) && ViewPlanActions(mbr, entryCtrl, grilleCtrl, handelPlanActionsChanded) }
					{ ( viewSelected === MENU_ONGLET_BUTTON_SIGNATURE ) && ViewSignature(mbr, grilleCtrl, handelCtrlSigned) }
					{ ( viewSelected === MENU_ONGLET_BUTTON_SEND ) && ViewSend(mbr, canSendRapport) }
				</Box>
				
			</Box>
    </Box>
  );
};

const ViewConfig = (
	confCtrlView: ConfigPropType | undefined,
	onConfigViewChange: ( newConf: ConfigPropType ) => void,
) => {
	return (
		<Box className="config-view drawer-content">
			<ConfigurationPropComponent
				config={ ( confCtrlView ) ? confCtrlView : { ...initialConfigProp } }
				onConfigCtrlChange={ onConfigViewChange }
			/>

			<Box>
				<Typography
					sx={{
						fontWeight: 'bold',
						paddingLeft: '20px',
					}}
				>
					Conytr�le contradictoire
				</Typography>
			</Box>
		</Box>
	);
};
const ViewPlanActions = (
	mbrView: MbrType,
	rsdView: EntryType,
	ctrlView: GrillePropType,
	onPlanViewChanging: ( plan: PlanActionsType ) => void,
) => {
	return (
		<Box className="plan-actions-view drawer-content"
			sx={{
				height: '100%',
			}}
		>
			<PlanActionsComponent
				mbr={ mbrView }
				rsd={ rsdView }
				ctrl={ ctrlView }
				onPlanChanging={ onPlanViewChanging }
			/>
		</Box>
	);
};
const ViewSignature = (
	mbr: MbrType,
	grilleView: GrillePropType,
	onIsSigned: ( sigs: string[] ) => void,
) => {
	return (
		<Box className="signature-view drawer-content"
			sx={{
				height: '100%',
			}}
		>
			<SignatureComponent
				mbr={ mbr }
				idCtrl={ ( grilleView.id ) ? grilleView.id : 0 }
				numberOfSignatures={2}
				isSigned={ ( ( grilleView.signatures !== undefined ) && ( grilleView.signatures !== null ) && ( grilleView.signatures.length > 0 ) ) }
				onSignature={ onIsSigned }
			/>
		</Box>
	);
};
const ViewSend = (
	mbrView: MbrType,
	isSaved: boolean,
) => {
	return (
		<Box className="send-view drawer-content"
			sx={{
				height: '100%',
			}}
		>
			<SendRapportComponent
				to={ ( isSaved && ( mbrView.mail ) ) ? mbrView.mail : '' }
			/>
		</Box>
	);
};

export default MenuOngletComponent;
