
/*	PROD	*/

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

function LoadingMessage() {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => {
        if( prevDots.length === 3 ) return '';
        return prevDots + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        width: '20em',
        marginLeft: 'calc(100% / 2 - 35px)',
        textAlign: 'left',
        fontWeight: 'bold',
        color: '#45b40b',
      }}
    >
      Patientez {dots}
    </Box>
  );
}

export default LoadingMessage;
