
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import "./style/objectifMobile.css";

import icon from "../../assets/logo_prop.png";

import { LOGIN_URL, OBJECTIFS_ALLOWED_PATHS } from "../../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../../models/mbr";
import { EntryType, mapToEntryType } from "../../models/entry";
import { STORE_PARAM_CIBLE } from "../../models/consts/store";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import getScreenSize from "../../helpers/getScreenSize";
import makeMbr from "../../helpers/make-mbr";
import TestConect from "../../helpers/test-conect";
import { fetchGetObjectifsControleur } from "../../services/getStatistiques";
import { useNotification } from "../../components/notify";
import InputComponent from "../../components/balises/input";
import LoadingMessage from "../../components/balises/buttons/loadingMessage";
import BtnEntry from "../../components/balises/buttons/btnEntry";

import { useNavigation } from "../../components/navigationContext";

const ObjectifsScreen: FunctionComponent = () => {
  useConditionalRedirect(OBJECTIFS_ALLOWED_PATHS);

  const notify = useNotification();

  const [mbr, setMbr] = useState<MbrType>({ ...initialMbr });
  const [listNoControl, setListNoControl] = useState<EntryType[]>([]);
	const [listToShow, setListToShow] = useState<EntryType[]>([]);
  const [objectifs, setObjectifs] = useState('Patientez');
	const [message, setMessage] = useState('Patientez');
  const [showMessage, setShowMessage] = useState(true);
	const [erreur, setErreur] = useState(false);
  const [displayType, setDisplayType] = useState(getScreenSize());
  const [screenSize, setScreenSize] = useState(0);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
    if( !isMounted ) {
			document.title = 'Objectifs';

      const fetchMbr = async () => {
        try {
          const user = await makeMbr();

          setMbr(prevMbr => ({
            ...prevMbr,
            ...user
          }));
              
          testMbr(user);
        } catch(error) {
          console.error('Error', error);
        }
      };

      fetchMbr();
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setDisplayType(getScreenSize());
      setScreenSize(window.innerWidth);
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  /* FUNCTIONS */
  const testMbr = async ( user: MbrType ) => {
    try {
      const response = await TestConect(user.pseudo, user.mdp, user.mac);

      if( response === false ) {
        history.push(LOGIN_URL);
      } else {
        fetchData(user);
      }
    } catch (error) {
      console.error('Error', error);
    }
  };
  const fetchData = async ( user: MbrType ) => {
		try {
			const response = await fetchGetObjectifsControleur(user.id, user.mac);

			if( response.result ) {
				if( response.data.nb > 0 ) {
					const newListEntry = mapToEntryType(response.data.list);

          setObjectifs(response.data.objectif);
					setListNoControl(newListEntry);
					setListToShow(newListEntry);
					setShowMessage(false);
					setIsMounted(true);
				} else {
					notify(response.data.txt, { variant: 'success' });
					setMessage(response.data.txt);
					setErreur(true);
					setShowMessage(true);
				}
			} else {
				console.error(response.data.txt);
				setMessage(response.data.txt);
				setErreur(true);
				setShowMessage(true);
			}
		} catch(error) {
			console.error(error);
			setMessage('Une erreur s\'produite lors de la récupération des données');
			setErreur(true);
			setShowMessage(true);
		}
	};

  const filtreListToShow = ( val: string ) => {
		const newList: EntryType[] = [];

		for( var x in listNoControl ) {
			let item = listNoControl[x];
			let b = false;

			if( item.adresse.adr.indexOf(val) >= 0 ) b = true;
			if( item.adresse.cp.indexOf(val) >= 0 ) b = true;
			if( item.adresse.ville.indexOf(val) >= 0 ) b = true;
			if( item.agence.txt.indexOf(val) >= 0 ) b = true;
			if( item.entry.indexOf(val) >= 0 ) b = true;
			if( item.groupement.txt.indexOf(val) >= 0 ) b = true;
			if( item.ref.indexOf(val) >= 0 ) b = true;
			if( item.residence.txt.indexOf(val) >= 0 ) b = true;
			if( item.txt.indexOf(val) >= 0 ) b = true;

			if( b ) {
				newList.push(item);
			}
		}

		setListToShow(newList);
	};

  const handelKeyPressed = ( caracter: string, val: string | number | null ) => {
		if( typeof val === 'string' ) {
			filtreListToShow(val);
		}
	};
	const handelEntrySelected = ( entry: EntryType ) => {
		const newSelection = {
      agc: entry.agence.id,
      grp: entry.groupement.id,
      rsd: entry.residence.id,
      entry: entry.id,
    };

    localStorage.setItem(STORE_PARAM_CIBLE, JSON.stringify(newSelection));

    history.goBack();
	};

  return (
    <Box className="objectifs-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `objectifs-container portrait-view ${ screenSize }-size` }
          container
          component="div"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
					sx={{
						height: '100%',
					}}
        >
          <Grid2 className="field-icon"
            component="div"
						size={2}
						offset={10}
            sx={{
              padding: '20px 20px 0 0',
            }}
          >
            <img src={ icon } alt="M" className="icon"/>
          </Grid2>

          <Grid2 className="field-resum"
						component="div"
						size={10}
						sx={{
							marginTop: '1em',
						}}
					>
            { ViewObjectifs(objectifs) }
          </Grid2>

          <Grid2 className="views-container"
            component="div"
						size={12}
            sx={{
              height: 'calc(100% - 190px)',
              width: '100%',
              overflow: 'hidden',
              marginTop: ( showMessage ) ? '20px' : '10px',
            }}
          >
            { ( showMessage ) ?
                (
                  ( erreur ) ?
                    <Box className="cont-loading-message"
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                      }}
                    >
                      <LoadingMessage/>
                    </Box>
                  :
                    <Box className="cont-message-return"
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                        fontWeight: 'bold',
                        color: '#45b40b',
                      }}
                    >
                      { message }
                    </Box>
                )
              :
                <Grid2
                  container
                  component="div"
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid2 className="field-search"
										component="div"
										size={12}
										sx={{
											padding: '10px',
										}}
									>
                    <InputComponent
                      name="search"
                      label=""
                      value=""
                      type="search"
                      error={ false }
                      onKeyPressed={ handelKeyPressed }
                    />
                  </Grid2>

                  <Grid2 className="field-list"
										component="div"
										size={12}
										sx={{
											padding: '10px',
											height: 'calc(100vh - 260px)',
										}}
									>
                    { ViewListEntry(listToShow, handelEntrySelected) }
                  </Grid2>
                </Grid2>
            }
          </Grid2>
        </Grid2>
      }
      { ( !isPortrait ) &&
        <Grid2 className={ `objectifs-container landscape-view ${ screenSize }-size` }
          container
          component="div"
					sx={{
						height: '100%',
					}}
        >
          <Grid2 className="left-container" size={6}
						component="div"
						sx={{
							height: '100%',
						}}
					>
            <Grid2
              container
              component="div"
              direction="column"
							justifyContent="space-evenly"
							sx={{
								height: '90%',
							}}
            >
              <Grid2 className="field-icon" size={2}
                component={"div"}
                sx={{
                  padding: '20px 0 0 20px',
                }}
              >
                <img src={ icon } alt="M" className="icon"/>
              </Grid2>

              <Grid2 className="fied-resum" size={12}
								component="div"
								sx={{
									padding: '20px 40px',
								}}
							>
                { ViewObjectifs(objectifs) }
              </Grid2>
              
              <Grid2 className="fied-search" size={10} offset={1}
								component="div"
								sx={{
									opacity: ( showMessage ) ? '0' : '1',
								}}
							>
                <InputComponent
                  name="search"
                  label=""
                  value=""
                  type="search"
                  error={ false }
                  onKeyPressed={ handelKeyPressed }
                />
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 className="right-container" size={6}
						component="div"
						sx={{
							height: '100%',
							padding: '5px',
						}}
					>
            { ( showMessage ) ?
                (
                  ( erreur ) ?
                    <Box
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                      }}
                    >
                      <LoadingMessage/>
                    </Box>
                  :
                    <Box
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                        fontWeight: 'bold',
                        color: '#45b40b',
                      }}
                    >
                      { message }
                    </Box>
                )
              :
								ViewListEntry(listToShow, handelEntrySelected)
						}
          </Grid2>
        </Grid2>
      }
    </Box>
  )
};

const ViewObjectifs = (
  objectifs: string,
) => {
  return (
    <Box className="field-objectifs"
      sx={{
        border: '2px solid #45b40b',
        color: '#45b40b',
        borderRadius: '10px',
        width: '100%',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Typography className="objectifs-title"
        sx={{
          position: 'absolute',
          top: '-15px',
          left: '20px',
          backgroundColor: 'white',
          padding: '0 5px',
        }}
      >
        Mes objectifs
      </Typography>

      <Typography className="objectifs-value"
        sx={{
          fontWeight: 'bold',
          fontSize: '2rem',
        }}
      >
        { objectifs }
      </Typography>
    </Box>
  )
};
const ViewListEntry = (
  datas: EntryType[],
  onItemClicked: ( entry: EntryType ) => void,
) => {
  return (
    <Box
			sx={{
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
				overflowY: 'scroll',
			}}
		>
        { datas.map(( item ) => {
          return (
            <BtnEntry key={ item.id } entry={ item } onClicked={ onItemClicked }/>
          )
        })}
    </Box>
  );
};

export default ObjectifsScreen;
