
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import "./index.css";
import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import { Search, Visibility, VisibilityOff } from "@mui/icons-material";

interface InputComponentProps {
  name: string;
  label: string;
  value: any;
  type: string;
  error: boolean;
  height?: number;
  readonly?: boolean;
  underline?: boolean;
  onChange?: ( n: string, v: string | number | null ) => void;
  onFinish?: () => void;
  onKeyPressed?: ( v: string, m: string | number | null ) => void;
};

const InputComponent: FunctionComponent<InputComponentProps> = ( {
  name,
  label,
  value,
  type,
  error,
  height,
  readonly,
  underline,
  onChange,
  onFinish,
  onKeyPressed,
} ) => {
  const [valueComponent, setValueComponent] = useState<string | number | null>(null);
  const [typeComponent, setTypeComponent] = useState('text');
  const [isPasswordInput, setIsPasswordInput] = useState(false);
  const [isSearchInput, setIsSearchInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValueComponent(value);
  }, [value]);
  useEffect(() => {
    if( type === 'search' ) {
      setIsPasswordInput(false);
      setIsSearchInput(true);
      setTypeComponent("text");
    } else {
      if( type === 'password' ) {
        setIsPasswordInput(true);
        setIsSearchInput(false);
        setShowPassword(false);
      }

      setTypeComponent(type);
    }
  }, [type]);

  const handelKeyPressed = ( e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    if( ( e.key === "Enter" ) && ( onFinish !== undefined ) ) {
      onFinish();
    } else if( onKeyPressed !== undefined ) {
      onKeyPressed(e.key, valueComponent);
    }
  };
  const handleInputChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const newValue = e.target.value;

    setValueComponent(newValue);
    
    if( onChange !== undefined ) {
      onChange(name, newValue);
    }
  };
  const togglePasswordVisibility = () => {
    var newType = typeComponent;

    if( typeComponent === 'password' ) {
      newType = 'text';
    } else {
      newType = 'password';
    }

    setShowPassword(!showPassword);
    setTypeComponent(newType);
  };
  const handleMouseDownPassword = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    event.preventDefault();
  };

  return (
    <Box className="input-component">
      <FormControl className="content-input-component" variant="standard" sx={{ width: '100%' }}>
        <InputLabel
          htmlFor={ name }
          className="label-input-component"
          sx={{
						color: ( error ) ? 'red' : '#888',
						top: ( typeComponent === 'date' ) ? '-20px' : null,
					}}
        >
          { label }
        </InputLabel>

        <Input
          className="champs-input-component"
          error={ error }
          name={ name }
          type={ typeComponent }
          defaultValue={ value }
          style={ { color: ( error ) ? 'red' : 'black', height: `${ ( height === undefined) ? '3' : height }rem` } }
          disabled={ ( ( readonly !== undefined ) && readonly ) }
          disableUnderline={ ( underline !== undefined ) && ( underline === false ) }
          endAdornment={
            ( isPasswordInput ) ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'hide the password' : 'display the password'
                  }
                  onClick={ togglePasswordVisibility }
                  onMouseDown={ handleMouseDownPassword }
                  onMouseUp={ handleMouseUpPassword }
                >
                  { ( showPassword ) ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
              </InputAdornment>
            ) : ( isSearchInput ) ? (
              <InputAdornment position="end">
                <IconButton aria-label="search">
                  <Search />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          onKeyDown={ e => handelKeyPressed(e) }
          onChange={ e => handleInputChange(e) }
        />
      </FormControl>
    </Box>
  )
};

export default InputComponent;
  