
import {
  STAT_ENCART_TYPE_CONTROLEUR,
  STAT_ENCART_TYPE_AGENCE,
  STAT_ENCART_TYPE_ENTRY,
  STAT_ENCART_TYPE_GROUPEMENT,
  STAT_ENCART_TYPE_PLAN_ACTIONS,
  STAT_ENCART_TYPE_RESIDENCE,
} from "./consts/statistiques";
import {
  CONFIG_PROP_VERSION_ALEATOIRE,
  CONFIG_PROP_VERSION_COMPLET,
  CONFIG_PROP_VERSION_SPECIFIQUE,
  CONFIG_PROP_TYPE_CTRL,
  CONFIG_PROP_TYPE_EDLE,
  CONFIG_PROP_TYPE_EDLS,
  CONFIG_PROP_TYPE_LEVEE,
  CONFIG_PROP_CONFIG_AFFICHAGE,
  CONFIG_PROP_CONFIG_METEO,
  CONFIG_PROP_CONFIG_PRODUITS,
  CONFIG_PROP_CONFIG_VISITE,
  MENU_ONGLET_BUTTON_CONFIG,
  MENU_ONGLET_BUTTON_SEND,
  MENU_ONGLET_BUTTON_PLAN,
  MENU_ONGLET_BUTTON_SIGNATURE,
  MENU_ONGLET_HIDDEN,
} from "./consts/ctrlProp";
import {
  FOCUS_VIEW_PROP_MENU,
  FOCUS_VIEW_PROP_PLAN_D_ACTIONS,
  FOCUS_VIEW_PROP_SIGNATURE,
  FOCUS_VIEW_PROP_SEND,
  FOCUS_VIEW_PROP_GRILLE,
  FOCUS_VIEW_PROP_GRILLE_ZONE,
  FOCUS_VIEW_PROP_GRILLE_ELEMENT,
  FOCUS_VIEW_PROP_GRILLE_CRITER,
} from "./consts";

export const VALIDATION_TYPE_STAT = [ STAT_ENCART_TYPE_CONTROLEUR, STAT_ENCART_TYPE_PLAN_ACTIONS, STAT_ENCART_TYPE_AGENCE, STAT_ENCART_TYPE_GROUPEMENT, STAT_ENCART_TYPE_RESIDENCE, STAT_ENCART_TYPE_ENTRY ];

export const VALIDATION_CONFIG_PROP_VERSION = [ CONFIG_PROP_VERSION_COMPLET, CONFIG_PROP_VERSION_ALEATOIRE, CONFIG_PROP_VERSION_SPECIFIQUE ];
export const VALIDATION_CONFIG_PROP_TYPE = [ CONFIG_PROP_TYPE_EDLE, CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_TYPE_LEVEE, CONFIG_PROP_TYPE_EDLS ];
export const VALIDATION_CONFIG_PROP_CONFIG = [ CONFIG_PROP_CONFIG_VISITE, CONFIG_PROP_CONFIG_METEO, CONFIG_PROP_CONFIG_PRODUITS, CONFIG_PROP_CONFIG_AFFICHAGE ];
export const VALIDATION_VIEW_SELECTED = [ MENU_ONGLET_BUTTON_CONFIG, MENU_ONGLET_BUTTON_SEND, MENU_ONGLET_BUTTON_PLAN, MENU_ONGLET_BUTTON_SIGNATURE, MENU_ONGLET_HIDDEN ];

export const VALIDATION_FOCUS_PROP = [ FOCUS_VIEW_PROP_MENU, FOCUS_VIEW_PROP_PLAN_D_ACTIONS, FOCUS_VIEW_PROP_SIGNATURE, FOCUS_VIEW_PROP_SEND, FOCUS_VIEW_PROP_GRILLE_ZONE, FOCUS_VIEW_PROP_GRILLE_ELEMENT, FOCUS_VIEW_PROP_GRILLE_CRITER ];
export const VALIDATION_FOCUS_PROP_PROTECT = [ FOCUS_VIEW_PROP_GRILLE_ELEMENT, FOCUS_VIEW_PROP_GRILLE_CRITER ];
export const VALIDATION_FOCUS_PROP_SAVE = [ FOCUS_VIEW_PROP_MENU, FOCUS_VIEW_PROP_PLAN_D_ACTIONS, FOCUS_VIEW_PROP_SIGNATURE, FOCUS_VIEW_PROP_SEND,FOCUS_VIEW_PROP_GRILLE_ZONE ];
export const VALIDATION_FOCUS_PROP_GRILLE = [ FOCUS_VIEW_PROP_GRILLE, FOCUS_VIEW_PROP_GRILLE_ZONE, FOCUS_VIEW_PROP_GRILLE_ELEMENT, FOCUS_VIEW_PROP_GRILLE_CRITER ];
