
/*	PROD	*/

export type MapSelectionType = {
  agc: number,
  grp: number,
  rsd: number,
  entry: number,
};

export const initialMapSelectionType: MapSelectionType = {
  agc: 0,
  grp: 0,
  rsd: 0,
  entry: 0,
};
