
/*	PROD	*/

import { AgenceType, convertAgenceTypeToJson, convertToAgenceType, initialAgence, ReceveidAgenceType } from "./agence";
import { convertGroupememtTypeToJson, convertToGroupememtType, GroupementType, initialGroupement, ReceveidGroupementType } from "./groupement";

export type ReceveidResidenceType = {
  id: number,
  txt: string,
  agence: ReceveidAgenceType,
  groupement: ReceveidGroupementType,
};

export type ResidenceType = {
  id: number,
  txt: string,
  agence: AgenceType,
  groupement: GroupementType,
};

export const initialResidence: ResidenceType = {
  id: 0,
  txt: '',
  agence: initialAgence,
  groupement: initialGroupement,
};

export const convertToResidenceType = ( data: ReceveidResidenceType ): ResidenceType => {
  return {
    id: data.id,
    txt: data.txt,
    agence: convertToAgenceType(data.agence),
    groupement: convertToGroupememtType(data.groupement),
  };
};
export const convertResidenceTypeToJson = ( data: ResidenceType ) => {
	return {
		id: data.id,
		txt: data.txt,
		agence: convertAgenceTypeToJson(data.agence),
		groupement: convertGroupememtTypeToJson(data.groupement),
	};
};
