
/*	PROD	*/

import axios from "axios";

import { API_URL_UPLOAD_IMG } from "../models/consts/apiUrls";

export async function fetchUploadImg( mbr: number, mac: string, img: string ) {
  try {
    const url = API_URL_UPLOAD_IMG;
    const params = {
      mbr: mbr,
      mac: mac,
      val: img,
    };

    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
