
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box } from "@mui/material";

import { MbrType } from "../../models/mbr";
import { EntryType } from "../../models/entry";
import { AgentType, initialAgent } from "../../models/agent";
import ModalDialsComponent from "../dials";
import { DIALOG_TYPE_SELECT_AGENT } from "../dials/const";

interface IdResidComponentProps {
  mbr: MbrType;
  fiche: EntryType;
  listPrestataires: AgentType[];
  listAgents: AgentType[];
  presta: AgentType;
  onSelectedPresta: ( val: AgentType ) => void;
};

const IdResidComponent: FunctionComponent<IdResidComponentProps> = ( {
  mbr,
  fiche,
  listPrestataires,
  listAgents,
  presta,
  onSelectedPresta,
} ) => {
  const [prestaComponent, setPrestaComponent] = useState<AgentType>(initialAgent);
  const [selectAgentDialIsOpen, setSelectAgentDialIsOpen] = useState(false);
  const [listPrestatairesComponent, setListPrestairesComponent] = useState<AgentType[]>([]);
  const [listAgentsComponent, setListAgentsComponent] = useState<AgentType[]>([]);

  useEffect(() => {
    if( presta.id > 0 ) {
      setPrestaComponent(presta);
    }
  }, [presta]);
  useEffect(() => {
    if( ( listPrestataires !== null ) && ( listPrestataires !== undefined ) && ( Object.entries(listPrestataires).length > 0 ) ) {
      setListPrestairesComponent(listPrestataires);
    }
// eslint-disable-next-line
  }, [listPrestataires]);
  useEffect(() => {
    if( ( listAgents !== null ) && ( listAgents !== undefined ) && ( Object.entries(listAgents).length > 0 ) ) {
      setListAgentsComponent(listAgents);
    }
  }, [listAgents]);

  const handleOpenSelectAgent = () => {
    setSelectAgentDialIsOpen(true);
  };
  const handelSelectPresta = ( val: AgentType ) => {
    onSelectedPresta(val);
  };

  return (
    <Box className="id-resid-component"
			sx={{
				fontSize: '10pt',
				width: '100%',
				border: '1px solid #45b40b',
				borderRadius: '5px',
				padding: '2px',
			}}
		>
			<Box className="field-id-resid"
				sx={{
					backgroundColor: '#eee',
					borderRadius: '5px',
					cursor: 'pointer',
					padding: '5px 0 5px 5px',
					minHeight: '3.7em',
				}}
			>
				<Box className="cell-ref"
					sx={{
						fontWeight: 'bold',
					}}
				>
					{ fiche.ref }
				</Box>

				<Box className="cell_nom">
					{ fiche.txt }
				</Box>
			</Box>

			<Box className="field-adresse"
				sx={{
					backgroundColor: '#eee',
					borderRadius: '5px',
					cursor: 'pointer',
					padding: '5px 0 5px 5px',
					marginTop: '1px',
					minHeight: '3.7em',
				}}
			>
				<Box className="cell-entry">
					{ fiche.entry }
				</Box>

				<Box className="cell-adr">
					{ `${fiche.adresse.adr} ${fiche.adresse.cp} ${fiche.adresse.ville}` }
				</Box>
			</Box>
					
			<Box className="field-patrimoine"
				sx={{
					backgroundColor: '#eee',
					borderRadius: '5px',
					cursor: 'pointer',
					padding: '5px 0 5px 5px',
					marginTop: '1px',
				}}
			>
				<Box className="cell-agence"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Agence:</b>&nbsp;
					{ fiche.agence.txt }
				</Box>

				<Box className="cell-groupement"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Groupement:</b>&nbsp;
					{ fiche.groupement.txt }
				</Box>

				<Box className="cell-residence"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Résidence:</b>&nbsp;
					{ fiche.residence.txt }
				</Box>
			</Box>
					
			<Box className="field-referents"
				onClick={ handleOpenSelectAgent }
				sx={{
					backgroundColor: '#eee',
					borderRadius: '5px',
					cursor: 'pointer',
					padding: '5px 0 5px 5px',
					marginTop: '1px',
				}}
			>
				<Box className="cell-encadrant"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Référent secteur:</b>&nbsp;
					{ fiche.referents.encadrant.txt }
				</Box>

				<Box className="cell-admin"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Référent administratif:</b>&nbsp;
					{ fiche.referents.gardien.txt }
				</Box>

				<Box className="cell-prestataire"
					sx={{
						fontSize: '8pt',
					}}
				>
					<b>Référent entretien:</b>&nbsp;
					{ prestaComponent.txt }
				</Box>
			</Box>

      <ModalDialsComponent
        mbr={ mbr }
        isOpen={ selectAgentDialIsOpen }
        onClose={ () => setSelectAgentDialIsOpen(false) }
        listPrestataire={ listPrestatairesComponent }
        listAgents={ listAgentsComponent }
        onSelectAgent={ ( val: AgentType ) => handelSelectPresta(val) }
        dialogType={ DIALOG_TYPE_SELECT_AGENT }
      />
    </Box>
  );
};

export default IdResidComponent;
