
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';
import { Box, Button, Grid2, Typography } from "@mui/material";

import iconeAgence from "../../assets/agence_vert.png";
import iconeGroupement from "../../assets/groupement_residences_vert.png";
import iconeResidence from "../../assets/residence_vert.png";
import iconeEntry from "../../assets/localisation_vert.png";

import './listSelect.css';

import { MbrType } from "../../models/mbr";
import { APPLI_SELECTED_CONTROL_PROP, COLOR_ORGA_PROP, LIST_SELECT_TYPE_VIEW_AGENCE, LIST_SELECT_TYPE_VIEW_ENTRY, LIST_SELECT_TYPE_VIEW_GROUPEMENT, LIST_SELECT_TYPE_VIEW_RESIDENCE } from "../../models/consts";
import { STORE_PARAM_ADRESSES, STORE_PARAM_APPLI_SELECTED, STORE_PARAM_GROUPEMENTS, STORE_PARAM_LIST_SELECT, STORE_PARAM_RESIDENCES } from "../../models/consts/store";
import { LOGIN_URL } from "../../models/consts/aplliUrls";
import fetchGetList from "../../services/getList";
import { COLOR_CONTROL_PROP } from "../../models/consts/ctrlProp";

interface ListSelectProps {
  mbr: MbrType;
  type: string;
  parent: number;
  val: number;
  focusTo?: string;
  callFocus?: ( v: string ) => void;
  onSetSelect: ( val: number ) => void;
};

type ElementListType = {
  id: number,
  txt: string,
  tip: string,
};

const iconMap: { [key: string]: string } = {
  [LIST_SELECT_TYPE_VIEW_AGENCE]: iconeAgence,
  [LIST_SELECT_TYPE_VIEW_GROUPEMENT]: iconeGroupement,
  [LIST_SELECT_TYPE_VIEW_RESIDENCE]: iconeResidence,
};

const ListSelect: FunctionComponent<ListSelectProps> = ( {
  mbr,
  type,
  parent,
  val,
  focusTo,
  callFocus,
  onSetSelect,
} ) => {
  const [data, setData] = useState<ElementListType[]>([]);
  const [idParent, setIdParent] = useState(0);
  const [selected, setSelected] = useState(0);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
	const [colorType, setColorType] = useState(COLOR_ORGA_PROP);
  const [showList, setShowList] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
	const [viewVal, setViewVal] = useState('');
  const [haveFocus, setHaveFocus] = useState(true);
	const [clickPosition, setClickPosition] = useState<{ x: number; y: number } | null>(null);

  const history = useHistory();

  useEffect(() => {
    if( ( mbr.id > 0 ) && ( !isMounted || ( parent !== idParent ) ) ) {
			const appParent = localStorage.getItem(STORE_PARAM_APPLI_SELECTED);

			switch( appParent ) {
				case APPLI_SELECTED_CONTROL_PROP:
					setColorType(COLOR_CONTROL_PROP);
					break;
				default:
					setColorType(COLOR_ORGA_PROP);
			}

      switch( type ) {
        case LIST_SELECT_TYPE_VIEW_AGENCE: 
          setTitle('Agence');
					setViewVal(LIST_SELECT_TYPE_VIEW_AGENCE);
          fetchAgcs(); 
          break;
        case LIST_SELECT_TYPE_VIEW_GROUPEMENT: 
          setTitle('Groupement');
					setViewVal(LIST_SELECT_TYPE_VIEW_GROUPEMENT);
          fetchGrps(); 
          break;
        case LIST_SELECT_TYPE_VIEW_RESIDENCE: 
          setTitle('Résidence');
					setViewVal(LIST_SELECT_TYPE_VIEW_RESIDENCE);
          fetchRsds(); 
          break;
        case LIST_SELECT_TYPE_VIEW_ENTRY: 
          setTitle('Entrée');
					setViewVal(LIST_SELECT_TYPE_VIEW_ENTRY);
          fetchEntry(); 
          break;
      }
    }
    if( isMounted && ( val > 0 ) ) {
      selectItem();
    }
// eslint-disable-next-line
  }, [mbr, parent, val]);

  useEffect(() => {
    if( !haveFocus && ( focusTo === viewVal ) ) {
      setHaveFocus(true);
    } else if( haveFocus && ( focusTo !== viewVal ) ) {
      setShowList(false);
      setHaveFocus(false);
    }
// eslint-disable-next-line
  }, [focusTo]);

  const fetchAgcs = () => {
    const encryptedData = localStorage.getItem(STORE_PARAM_LIST_SELECT);

    if( encryptedData !== null ) {
      const decryptedData = JSON.parse(encryptedData);
      const list: ElementListType[] = [];

      var mess = '';

      for( let x in decryptedData ) {
        const donn: ElementListType = {
          id: decryptedData[x].id,
          txt: decryptedData[x].txt,
          tip: decryptedData[x].txt,
        }

        list.push(donn);

        if( val === decryptedData[x].id ) {
          mess = decryptedData[x].txt;
        }
      }

      if( parent === 0 ) {
        setMessage('Sélectionnez une agence');
      } else if( mess === '' ) {
        setMessage('Sélectionnez une agence');
      }
      
      setIdParent(parent);
      setData(list);
      setSelected(parent);
      setMessage(mess);

      selectItem(list);
    }

    setIsMounted(true);
  };
  const fetchGrps = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_GROUPEMENTS, param);

        if( response.result ) {
          let list: ElementListType[] = [];

          for( let x in response.donn.list ) {
            const donn: ElementListType = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].txt,
            }

            list.push(donn);
          }

          setIdParent(parent)
          setData(list);

          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }
      } catch( error ) {
        console.error(error);
      }
    } else {
      setMessage('Sélectionnez une agence');
    }

    setIsMounted(true);
  };
  const fetchRsds = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_RESIDENCES, param);

        if( response.result ) {
          let list: ElementListType[] = [];

          for( let x in response.donn.list ) {
            const donn = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].txt,
            }

            list.push(donn);
          }

          setIdParent(parent);
          setData(list);
          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }

        setIsMounted(true);
      } catch(error) {
        console.error(error);
      }
    } else {
      setMessage('Sélectionner un groupement');
    }
  };
  const fetchEntry = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_ADRESSES, param);

        if( response.result ) {
          let list = [];

          for( let x in response.donn.list ) {
            const donn = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].last,
            }

            list.push(donn);
          }

          setIdParent(parent);
          setData(list);
          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }

        setIsMounted(true);
      } catch(error) {
        console.error(error);
      }
    } else {
      setMessage('Sélectionner une résidence');
    }
  };

  const toggleList = ( e: React.MouseEvent<HTMLDivElement> ) => {
		if( ( parent > 0 ) || ( type === LIST_SELECT_TYPE_VIEW_AGENCE ) ) {
			const { clientX, clientY } = e;

			if( callFocus !== undefined ) {
				callFocus(viewVal);
			}

			setClickPosition({ x: clientX, y: clientY });
			setShowList(!showList);
		}
  };
  const handelClick = (item: ElementListType) => {
    if( callFocus !== undefined ) {
      callFocus(viewVal);
    }

    setSelected(item.id);
    setMessage(item.txt);

    onSetSelect(item.id);
  };

  const selectItem = (l?: ElementListType[]) => {
    if( isMounted || ( l !== undefined ) ) {
      var mess = 'Sélectionner un';

      switch( type ) {
        case LIST_SELECT_TYPE_VIEW_AGENCE: 
          mess += 'e agence';
          break;
        case LIST_SELECT_TYPE_VIEW_GROUPEMENT: 
          mess += ' groupement';
          break;
        case LIST_SELECT_TYPE_VIEW_RESIDENCE: 
          mess += 'e résidence';
          break;
        case LIST_SELECT_TYPE_VIEW_ENTRY: 
          mess += 'e entrée';
          break;
      }

      if( val > 0 ) {
        if( ( ( parent > 0 ) || ( type === LIST_SELECT_TYPE_VIEW_AGENCE ) ) && ( val > 0 ) ) {
          if( l !== undefined ) {
            l.forEach(item => {
              if( item.id === val ) {
                mess = item.txt;
              }
            });
          } else {
            data.forEach(item => {
              if( item.id === val ) {
                mess = item.txt;
              }
            });
          }
        }
      }

      setSelected(val);
      setMessage(mess);
    }
  };

  return (
    <Box className="list-select-component"
			onClick={ toggleList }
		>
			<Grid2 className="list-select-container"
				container
				component="div"
				justifyContent="space-between"
			>
				<Grid2 className="field-left"
					component="div"
					size={11}
				>
					<Grid2 className="field-left-container"
						container
						component="div"
						direction="column"
					>
						<Grid2 className="field-title"
							component="div"
							size={12}
							sx={{
								fontWeight: 'bold',
								color: colorType,
								marginBottom: '10px',
							}}
						>
							{ title }
						</Grid2>

						<Grid2 className="field-message"
							component="div"
							size={11}
							offset={1}
							sx={{
								fontSize: '11pt',
							}}
						>
							{ message }
						</Grid2>
					</Grid2>
				</Grid2>

				<Grid2 className="field-right-container"
					component="div"
					size={1}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Typography className="arrow-btn"
						sx={{
							color: colorType,
							fontSize: '24px',
						}}
					>
						▼
					</Typography>
				</Grid2>
			</Grid2>

			{ showList && clickPosition &&
				<Box className="list-data-container"
					style={{
						'--start-x': `${clickPosition.x}px`,
						'--start-y': `${clickPosition.y}px`,
					} as React.CSSProperties }
				>
					{ ViewListSelection(type, data, handelClick) }
				</Box>
			}
    </Box>
  );
};

const ViewIconButton = (
  type: string,
) => {
  const iconSrc = iconMap[type] || iconeEntry;

  return <img src={ iconSrc } alt=""/>;
};
const ViewListSelection = (
	typeList: string,
	donnees: ElementListType[],
	onItemClicked: (item: ElementListType) => void,
) => {
	return (
		<Box className="view-list-data">
			{ donnees.map((donnee) => (
				<Button className="btn-element-list"
					key={ donnee.id }
					variant="outlined"
					onClick={ () => onItemClicked(donnee) }
					sx={{
						justifyContent: 'flex-start',
						textAlign: 'left',
						gap: '0px',
					}}
				>
					{ ViewIconButton(typeList) }
					{ donnee.txt }
				</Button>
			)) }
		</Box>
	);
};

export default ListSelect;
