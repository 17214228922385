
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect, FormEvent } from "react";

import './index.css';
import { Box, Button, Grid2 } from "@mui/material";
import CheckboxComponent from "../balises/checkbox/checkbox";
import TextareaComponent from "../balises/textarea";
import InputComponent from "../balises/input";
import { isValidEmail } from "../../helpers/isValidForm";

interface SendRapportComponentProps {
	forDesordreTechnique?: boolean;
	to?: string;
	mask?: string;
};

const SendRapportComponent: FunctionComponent<SendRapportComponentProps> = ( {
	forDesordreTechnique,
	to,
	mask,
}) => {
  const [isDesordreTechnique, setIfDesordreTechnique] = useState(false);
  const [message, setMessage] = useState('');
	const [destinataire, setDestinataire] = useState('');
	const [errorComponent, setErrorComponent] = useState(false);

	useEffect(() => {
		if( forDesordreTechnique !== undefined ) {
			setIfDesordreTechnique(forDesordreTechnique);
		}
	}, [forDesordreTechnique]);
	useEffect(() => {
		if( to !== undefined ) {
			setDestinataire(to);

			setErrorComponent(( ( to !== '' ) && isValidEmail(to) ));
		}
	}, [to]);
	useEffect(() => {
		if( mask !== undefined ) {
			setMessage(mask);
		}
	}, [mask]);
  
  const handelSubmit = (event: FormEvent) => {
    event.preventDefault();

		if( isValidEmail(destinataire) ) {
			sendMessage();
		} else {
			setErrorComponent(true);
		}
  };
	const handelDesordreTechnique = ( isChecked: boolean ) => {
		setIfDesordreTechnique(isChecked);
	};
	const handelChangMessage = ( val: string ) => {
		setMessage(val);
	};
	const handelChangeDestinataire = ( val: string ) => {
		if( isValidEmail(val) ) {
			setDestinataire(val);
			setErrorComponent(false);
		} else {
			setErrorComponent(true);
		}
	};

	const sendMessage = () => {
		const formData = {
			desordre: isDesordreTechnique,
			destinataire: destinataire,
			message: message,
		};
	};

  return (
    <Box className="send-rapport-component"
			sx={{
				height: '100%',
			}}
		>
      <form onSubmit={ handelSubmit } style={{ height: '100%' }}>
      	<Grid2 className="send-rapport-container"
					container
					component="div"
					direction="column"
					justifyContent="space-evenly"
					alignItems="center"
					sx={{
						height: '100%',
					}}
				>
          <Grid2 className="field-desodre"
						component="div"
						size={10}
					>
						<InputComponent
							name="to"
							label="Destinataire"
							value={ destinataire }
							type="mail"
							error={ errorComponent }
							onChange={ handelChangeDestinataire }
						/>

						<CheckboxComponent
							name="desordre"
							label="Désordre technique"
							checked={ isDesordreTechnique }
							onComponentChange={ handelDesordreTechnique }
						/>
          </Grid2>

          <Grid2 className="message-container"
						component="div"
						size={10}
					>
						<TextareaComponent
							name="message"
							label="Joindre un message"
							value={ message }
							onComponentChange={ handelChangMessage }
						/>
          </Grid2>

          <Button className="btn-send"
						variant={ ( !errorComponent && ( destinataire !== '' ) ) ? 'contained' : 'outlined' }
						disabled={ ( errorComponent || ( destinataire === '' ) ) }
						name="submit"
            key="send"
            onClick={ handelSubmit }
          >
						envoyer
					</Button>
      	</Grid2>
      </form>
    </Box>
  );
};

export default SendRapportComponent;
