
// recevied datas


import { AgenceType, mapOfAgenceType, ReceveidAgenceType } from "./agence";

export type ReceveidAgentType = {
  id: number;
  ref: string;
  txt: string;
  metier: string;
  agences?: ReceveidAgenceType[];
};

export type AgentType = {
  id: number,
  ref: string,
  txt: string,
  metier: string,
  agences?: AgenceType[],
};

export const initialAgent = {
  id: 0,
  ref: '00000',
  txt: 'Non affectée',
  metier: 'agent',
};

export const convertToAgentType = ( data: ReceveidAgentType ): AgentType => {
  return {
    id: data.id,
    ref: data.ref,
    txt: data.txt,
    metier: (data.metier === undefined) ? 'prestataire' : data.metier,
    agences: mapOfAgenceType(data.agences || []),
  };
};
export const convertAgentTypeToJson = ( data: AgentType ) => {
  if( !( data.id > 0 ) ) {
    return {
      id: data.id,
      ref: data.ref,
      txt: data.txt,
      metier: data.metier,
    };
  } else {
    return { ...initialAgent };
  }
};
export const mapListAgents = ( data: ReceveidAgentType[] ): AgentType[] => {
  return Object.values(data).map((agent) => {
    return convertToAgentType(agent);
  });
};
export const mapListAgentsForSave = ( data: AgentType[] ) => {
	return Object.values(data).map((agent) => {
		return convertAgentTypeToJson(agent);
	});
};
