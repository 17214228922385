
/*	PROD	*/

import axios from "axios";

import { ConvertMbrTypeToJson, MbrType } from "../models/mbr";
import { API_URL_SET_FICHE } from "../models/consts/apiUrls";

export async function fetchSetMbr( idMbr: number, mac: string, mdp: string, mbr: MbrType ) {
  try {
    const url = API_URL_SET_FICHE;
    const params = {
      mbr: idMbr,
      mac: mac,
      val: mdp,
      data: JSON.stringify(ConvertMbrTypeToJson(mbr)),
    };
    const reponse = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return reponse.data;
  } catch( error ) {
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion!',
      },
    };
  }
};
