
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Grid2 } from "@mui/material";

import './typage.css';

import { CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_TYPE_EDLE, CONFIG_PROP_TYPE_EDLS, CONFIG_PROP_TYPE_LEVEE, CONFIG_PROP_VERSION_ALEATOIRE, CONFIG_PROP_VERSION_COMPLET, CONFIG_PROP_VERSION_SPECIFIQUE } from "../../../models/consts/ctrlProp";

interface TypageCtrlComponentProps {
	curentType: string;
	curentVersion: string;
	onChangeTypage: ( type: string, version: string ) => void;
	debugg?: boolean;
}

const TypageCtrlComponent: FunctionComponent<TypageCtrlComponentProps> = ({
	curentType,
	curentVersion,
	onChangeTypage,
}) => {
	const [typeComponent, setTypeComponent] = useState(CONFIG_PROP_TYPE_CTRL);
	const [versionComponent, setVersionComponent] = useState(CONFIG_PROP_VERSION_COMPLET);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if( !isMounted ) {
			setTypeComponent(curentType);
			setVersionComponent(curentVersion);
			setIsMounted(true);
		}
// eslint-disable-next-line
	}, []);
	useEffect(() => {
		onChangeTypage(typeComponent, versionComponent)
// eslint-disable-next-line
	}, [typeComponent, versionComponent]);

	const handelSetType = ( val: string ) => {
		setTypeComponent(val);
	};
	const handelSetVersion = ( val: string ) => {
		if( typeComponent === CONFIG_PROP_TYPE_CTRL ) {
			setVersionComponent(val);
		}
	};

	return (
		<Box className="typage-ctrl-component">
			<Grid2 className="typage-ctrl-container"
				container
				component="div"
			>
				<Grid2 className="field-type"
					component="div"
					size={ ( typeComponent === CONFIG_PROP_TYPE_CTRL ) ? 7 : 10 }
					offset={ ( typeComponent === CONFIG_PROP_TYPE_CTRL ) ? 0 : 1 }
				>
					{ ViewTypeCtrl(typeComponent, handelSetType) }
				</Grid2>

				<Grid2 className="field-version"
					component="div"
					size={ ( typeComponent === CONFIG_PROP_TYPE_CTRL ) ? 5 : 0 }
				>
					{ ( typeComponent === CONFIG_PROP_TYPE_CTRL ) && ViewVersionCtrl(typeComponent, versionComponent, handelSetVersion) }
				</Grid2>
			</Grid2>
		</Box>
	)
};

const ViewTypeCtrl = (
	paramType: string,
	onChangeThis: ( v: string ) => void,
) => {
	return (
		<Box className="type-ctrl-view">
			<Grid2 className="type-ctrl-view-container"
				container
				component="div"
				direction="column"
			>
				<Grid2 className="btn-edle-container"
					component="div"
					size={12}
				>
					<Button
						variant={ ( paramType === CONFIG_PROP_TYPE_EDLE ) ? 'contained' : 'outlined' }
						onClick={ () => onChangeThis(CONFIG_PROP_TYPE_EDLE) }
					>
						État des lieux entrant
					</Button>
				</Grid2>

				<Grid2 className="btn-std-container"
					component="div"
					size={12}
				>
					<Button
						variant={ ( paramType === CONFIG_PROP_TYPE_CTRL ) ? 'contained' : 'outlined' }
						onClick={ () => onChangeThis(CONFIG_PROP_TYPE_CTRL) }
					>
						Contrôle
					</Button>
				</Grid2>

				<Grid2 className="btn-plan-container"
					component="div"
					size={12}
				>
					<Button
						variant={ ( paramType === CONFIG_PROP_TYPE_LEVEE ) ? 'contained' : 'outlined' }
						onClick={ () => onChangeThis(CONFIG_PROP_TYPE_LEVEE) }
					>
						Levée de plan d'actions
					</Button>
				</Grid2>

				<Grid2 className="btn-edls-container"
					component="div"
					size={12}
				>
					<Button
						variant={ ( paramType === CONFIG_PROP_TYPE_EDLS ) ? 'contained' : 'outlined' }
						onClick={ () => onChangeThis(CONFIG_PROP_TYPE_EDLS) }
					>
						État des lieux sortant
					</Button>
				</Grid2>
			</Grid2>
		</Box>
	);
};
const ViewVersionCtrl = (
	paramType: string,
	paramVersion: string,
	onChangeThis: ( v: string ) => void,
) => {
	return (
		<Box className="version-ctrl-view">
			<Grid2 className="version-ctrl-view-container"
				container
				component="div"
				direction="column"
			>
				<Grid2 className="btn-edle-container"
					component="div"
					size={12}
				>
					<Button
						variant={
							( ( paramType === CONFIG_PROP_TYPE_CTRL ) && ( paramVersion === CONFIG_PROP_VERSION_COMPLET ) )
								? 'contained'
								: 'outlined'
						}
						onClick={ () => onChangeThis(CONFIG_PROP_VERSION_COMPLET) }
					>
						Complet
					</Button>
				</Grid2>

				<Grid2 className="btn-edle-container"
					component="div"
					size={12}
				>
					<Button
						variant={
							( ( paramType === CONFIG_PROP_TYPE_CTRL ) && ( paramVersion === CONFIG_PROP_VERSION_ALEATOIRE ) )
								? 'contained'
								: 'outlined'
						}
						onClick={ () => onChangeThis(CONFIG_PROP_VERSION_ALEATOIRE) }
					>
						Aléatoire
					</Button>
				</Grid2>

				<Grid2 className="btn-edle-container"
					component="div"
					size={12}
				>
					<Button
						variant={
							( ( paramType === CONFIG_PROP_TYPE_CTRL ) && ( paramVersion === CONFIG_PROP_VERSION_SPECIFIQUE ) )
								? 'contained'
								: 'outlined'
						}
						onClick={ () => onChangeThis(CONFIG_PROP_VERSION_SPECIFIQUE) }
					>
						Spécifique
					</Button>
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default TypageCtrlComponent;
