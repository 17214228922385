
/*	PROD	*/

import { AgenceType, convertAgenceTypeToJson, convertToAgenceType, initialAgence, ReceveidAgenceType } from "./agence";

export type ReceveidGroupementType = {
  id: number,
  txt: string,
  agence: ReceveidAgenceType,
};

export type GroupementType = {
  id: number,
  txt: string,
  agence: AgenceType,
};

export const initialGroupement : GroupementType = {
  id: 0,
  txt: '',
  agence: initialAgence,
};

export const convertToGroupememtType = ( data: ReceveidGroupementType ): GroupementType => {
  return {
    id: data.id,
    txt: data.txt,
    agence: convertToAgenceType(data.agence),
  };
};
export const convertGroupememtTypeToJson = ( data: GroupementType ) => {
	return {
		id: data.id,
  	txt: data.txt,
  	agence: convertAgenceTypeToJson(data.agence),
	}
};

