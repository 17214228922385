
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid2, Stack } from "@mui/material";

import "./styles/profil.css";

import logoSociete from "../assets/logo_appli.png";
import vignette from "../assets/ORGAprop_bonhomme.png";

import { LOGIN_URL, PROFIL_ALLOWED_PATHS } from "../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../models/mbr";
import useConditionalRedirect from "../helpers/useConditionalRedirect";
import makeMbr from "../helpers/make-mbr";
import TestConect from "../helpers/test-conect";
import getScreenSize from "../helpers/getScreenSize";
import { fetchSetMbr } from "../services/setMbr";
import { useNotification } from "../components/notify";
import { useNavigation } from "../components/navigationContext";
import InputComponent from "../components/balises/input";
import fetchLogin from "../services/login";
import { PARAM_LOGIN_DECO } from "../models/consts/login";
import { SCREEN_SIZE_MOBILE } from "../models/consts";
import { STORE_PARAM_BACK_PAGE } from "../models/consts/store";

const ProfilScreen: FunctionComponent = () => {
  useConditionalRedirect(PROFIL_ALLOWED_PATHS);

  const notify = useNotification();

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  const [mbr, setMbr] = useState<MbrType>({ ...initialMbr });

  const [pseudoComponent, setPseudoComponent] = useState('');
  const [mailComponent, setMailComponent] = useState('');
  const [telComponent, setTelComponent] = useState('');
  const [mdpComponent, setMdpComponent] = useState('');
  const [validationComponent, setValidationComponent] = useState('');
  const [editSelected, setEditSelected] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [errorComponent, setErrorComponent] = useState(false);
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [backPage, setBackPage] = useState('');
	const [isMounted, setIsMounted] = useState(false);
  const [openDialAlert, setOpenDialAlert] = useState(false);

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
		if( !isMounted ) {
			try {
				var oldPage = localStorage.getItem(STORE_PARAM_BACK_PAGE);

				if( oldPage !== null ) {
					document.title = 'Profil';

					setBackPage(oldPage);
					testMbr();
				} else {
					history.goBack();
				}
			} catch (error) {
				console.error('Error', error);
			}
		}
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handelOpenEdit = () => {
    setEditSelected(true);
  };
  const handelModify = ( champs: string, val: string | number | null ) => {
    setIsModified(true);

    if( typeof val === 'string' ) {
      switch( champs ) {
        case 'psd': setPseudoComponent(val); break;
        case 'mail': setMailComponent(val); break;
        case 'tel': setTelComponent(val); break;
        case 'new': setMdpComponent(val); break;
        case 'mdp': setValidationComponent(val); break;
      }
    }
  };
  const handelBtnAction = () => {
    if( isModified ) {
      saveMbr();
    } else {
      deconnectMbr();
    }
  };
  const handelCloseProfil = () => {
    if( isModified ) {
      setOpenDialAlert(true);
    } else {
      history.push(backPage);
    }
  };
  const handelCloseAlert = () => {
    setOpenDialAlert(false);
  };
	const handelForceClose = () => {
		history.push(backPage);
	};

	const testMbr = async () => {
		try {
			const user = await makeMbr();
			const response = await TestConect(user.pseudo, user.mdp, user.mac);

			if( response === false ) {
				history.push(LOGIN_URL);
			} else {
				setMbr(prevMbr => ({
					...prevMbr,
					...user
				}));
				setIsMounted(true);
			}
		} catch (error) {
      console.error('Error', error);
    }
	};
  const saveMbr = async () => {
    const newMbr = { ...mbr };

    newMbr.pseudo = pseudoComponent;
    newMbr.mail = mailComponent;
    newMbr.tel = telComponent;

    if( mdpComponent !== '' ) {
      newMbr.mdp = mdpComponent;
    }

    try {
      const response = await fetchSetMbr(mbr.id, mbr.mac, validationComponent, newMbr);

      if( response.result ) {
        setIsModified(false);
        setEditSelected(false);
        setOpenDialAlert(false);
      } else {
        notify(response.data.txt, { variant: 'error' });
        setValidationComponent('');
        setErrorComponent(true);
      }
    } catch( error ) {
      notify("Une erreur s'est produite lors de la connexion!", { variant: 'error' });
      setErrorComponent(true);
    }
  };
  const deconnectMbr = async () => {
    try {
      if( mbr.id > 0 ) {
        const response = await fetchLogin(mbr.pseudo, mbr.mdp, mbr.mac, PARAM_LOGIN_DECO);

        if( response.result ) {
          history.push(LOGIN_URL);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }
      }
    } catch(error) {
      console.error(error);
    }
  };
  
  return (
    <Box className="profil-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `portrait-view ${ screenSize }-size` }
          container
          component="div"
          direction="column"
          justifyContent="space-evenly"
          sx={{
            height: '100%',
            fontSize: ( screenSize === SCREEN_SIZE_MOBILE ) ? '10pt' : '1.5rem',
          }}
        >
          <Grid2 component="div" size={12}>
            { ViewVignettes(mbr, isPortrait, handelCloseProfil) }
          </Grid2>
          
          <Grid2 component="div" size={12}>
            { ViewPrincipale(mbr, isPortrait, screenSize, editSelected, errorComponent, handelOpenEdit, handelModify) }
          </Grid2>

          <Grid2 component="div" size={12} sx={{ textAlign: 'center', }}>
            <Button onClick={ handelBtnAction } variant="contained" sx={ ( screenSize !== SCREEN_SIZE_MOBILE ) ? { fontSize: '1.5rem' } : {} }>
              { ( isModified ) ? 'ENREGISTRER' : 'SE DECONNECTER' }
            </Button>
          </Grid2>
        </Grid2>
      }

      { ( !isPortrait ) &&
        <Grid2 className="landscape-view"
          container
          component="div"
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 component="div" size={6} sx={{ height: '100vh' }}>
            { ViewVignettes(mbr, isPortrait, handelCloseProfil) }
          </Grid2>

          <Grid2 component="div" size={6} sx={{ height: '100vh' }}>
            <Grid2 className="field-droit"
              container
              component="div"
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid2 component="div" size={12}>
                { ViewPrincipale(mbr, isPortrait, screenSize, editSelected, errorComponent, handelOpenEdit, handelModify) }
              </Grid2>

              <Grid2 component="div" size={12} sx={{ textAlign: 'center', marginTop: ( editSelected ) ? '5%' : '10%', }}>
                <Button onClick={ handelBtnAction } variant="contained">
                  { ( isModified ) ? 'ENREGISTRER' : 'SE DECONNECTER' }
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      }

      <Dialog
        open={ openDialAlert }
        onClose={ handelCloseAlert }
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Vous désirez quitter cette page ?"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Votre profil a été modifé. Êtes-vous certain de vouloir quitter cette page sans enregistrer vos modifications ?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={ handelCloseAlert }>
            Non
          </Button>
          <Button onClick={ handelForceClose } autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const ViewVignettes = (
  mbr: MbrType,
  isPortrait: boolean,
  onClick: () => void,
) => {
  return (
    <Grid2 className="field-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-evenly' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
      onClick={ onClick }
    >
      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img src={ logoSociete } alt="logo" className="logo-appli"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={4}>
        <img src={ vignette } alt="logo" className="vignette"/>
      </Grid2>
    </Grid2>
  );
};
const ViewPrincipale = (
  mbr: MbrType,
  isPortrait: boolean,
  screenSize: string,
  editView: boolean,
  errorView: boolean,
  onCallEdit: () => void,
  onItemChange: ( champs: string, val: string | number | null ) => void,
) => {
  return (
    <Grid2
      container
      className="field-principal"
      component="div"
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={4}
    >
      <Grid2 component="div" size={10}>
        <Box className="field-title" sx={{ fontWeight: 'bold', }}>
          <i>Mon profil</i>
        </Box>
      </Grid2>

      <Grid2 component="div" size={ ( editView ) ? 12 : 11 } offset={ ( editView ) ? 0 : 1}>
        { ( editView ) && ViewEdit(mbr, errorView, onItemChange) }
        { ( !editView ) && ViewInfo(mbr, screenSize, onCallEdit) }
      </Grid2>
    </Grid2>
  );
};
const ViewInfo = (
  mbr: MbrType,
  screenSize: string,
  onCallToModify: () => void,
) => {
  return (
    <Grid2 className="info-view" size={12}>
      <Grid2
        container
        className="field-data"
        component="div"
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid2 className="field-mail" component="div" size={12}>
          <Box sx={{ margin: '0 0 5% 5%' }}>
            <label className="lbl-title">Courriel : </label>
            <label className="lbl-value">{ mbr.mail }</label>
          </Box>
        </Grid2>

        <Grid2 className="field-tel" component="div" size={12}>
          <Box sx={{ margin: '0 0 5% 5%' }}>
            <label className="lbl-title">Tel. : </label>
            <label className="lbl-value">{ mbr.tel }</label>
          </Box>
        </Grid2>

        <Grid2 className="field-pseudo" component="div" size={12}>
          <Box sx={{ margin: '0 0 5% 5%' }}>
            <label className="lbl-title">Identifiant : </label>
            <label className="lbl-value">{ mbr.pseudo }</label>
          </Box>
        </Grid2>

				<Grid2 className="field-mcp" component="div" size={12}>
          <Box sx={{ margin: '0 0 5% 5%' }}>
            <label className="lbl-title">Mot de passe : </label>
            <label className="lbl-value">xxxx</label>
          </Box>
        </Grid2>
      </Grid2>

      <Grid2 className="field-action" component="div" size={12}>
        <Grid2
          container
          direction="column"
          component="div"
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 component="div" size={4} offset={7}>
            <Button
              variant="text"
              className="btn-edit-mbr"
              onClick={ onCallToModify }
              sx={{
                width: '100%',
                marginTop: '-20%',
                fontSize: ( screenSize === SCREEN_SIZE_MOBILE ) ? '10pt' : '1.5rem',
              }}
            >
              Modifier
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
const ViewEdit = (
  mbr: MbrType,
  errorView: boolean,
  onChange: ( champs: string, val: string | number | null ) => void,
) => {
  return (
    <Stack
      spacing={1}
      sx={{
        width: '80%',
        margin: 'auto',
      }}
    >
      <InputComponent
        name="pseudo"
        key={0}
        value={ mbr.pseudo }
        label="Pseudo"
        type="text"
        error={ errorView }
        height={ 2 }
        onChange={ onChange }
      />

      <InputComponent
        name="mail"
        key={1}
        value={ mbr.mail }
        label="Couriel"
        type="text"
        error={ errorView }
        height={ 2 }
        onChange={ onChange }
      />

      <InputComponent
        name="tel"
        key={2}
        value={ mbr.tel }
        label="Tél."
        type="text"
        error={ errorView }
        height={ 2 }
        onChange={ onChange }
      />

      <InputComponent
        name="mbp"
        key={4}
        value={ '' }
        label="Ancien mot de passe"
        type="password"
        error={ errorView }
        height={ 2 }
        onChange={ onChange }
      />

      <InputComponent
        name="new"
        key={3}
        value={ mbr.mdp }
        label="Nouveau mot de passe"
        type="password"
        error={ errorView }
        height={ 2 }
        onChange={ onChange }
      />
    </Stack>
  );
};

export default ProfilScreen;
