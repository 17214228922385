
/*	PROD	*/

import React, { FunctionComponent, ReactNode, createContext, useContext } from 'react';
import { OptionsObject, SnackbarMessage, SnackbarProvider, useSnackbar } from 'notistack';

type NotifyFunction = (message: SnackbarMessage, options?: OptionsObject) => void;

const NotificationContext = createContext<NotifyFunction | null>(null);

interface NotificationProviderProps {
  children: ReactNode;
};
interface AppWrapperProps {
  children: ReactNode;
}

export const NotificationProvider: FunctionComponent<NotificationProviderProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const notify: NotifyFunction = (message, options = {}) => {
    enqueueSnackbar(message, {
      variant: options.variant || 'default',
      autoHideDuration: options.autoHideDuration || 3000,
      ...options,
    });
  };

  return (
    <NotificationContext.Provider value={notify}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotifyFunction => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const AppWrapper: FunctionComponent<AppWrapperProps> = ({ children }) => (
  <SnackbarProvider maxSnack={3}>
    <NotificationProvider>{children}</NotificationProvider>
  </SnackbarProvider>
);

export const NOTIFY_VARIANT_SUCCESS = "success";
export const NOTIFY_VARIANT_ERROR = "error";
export const NOTIFY_VARIANT_WARNING = "warning";
export const NOTIFY_VARIANT_INFO = "info";
