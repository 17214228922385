
/*	PROD	*/

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useHistory } from "react-router-dom";

import "./styles/selectEntry.css";

import goutte from '../assets/logo_prop.png';
import logo from '../assets/controlprop.png';

import { CONTROL_PROP_CONFIGURATION_URL, ENTRY_SELECT_ALLOWED_PATHS, LOGIN_URL } from "../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../models/mbr";
import { APPLI_SELECTED_CONTROL_PREST, APPLI_SELECTED_CONTROL_PROP, APPLI_SELECTED_CONTROL_PSP, APPLI_SELECTED_CONTROL_SECU, LIST_SELECT_TYPE_VIEW_AGENCE, LIST_SELECT_TYPE_VIEW_ENTRY, LIST_SELECT_TYPE_VIEW_GROUPEMENT, LIST_SELECT_TYPE_VIEW_MAIN, LIST_SELECT_TYPE_VIEW_RESIDENCE } from "../models/consts";
import { STORE_PARAM_APPLI_SELECTED, STORE_PARAM_CIBLE } from "../models/consts/store";
import { initialMapSelectionType, MapSelectionType } from "../models/mapSelection";
import makeMbr from "../helpers/make-mbr";
import TestConect from "../helpers/test-conect";
import useConditionalRedirect from "../helpers/useConditionalRedirect";
import getScreenSize from "../helpers/getScreenSize";
import { useNavigation } from "../components/navigationContext";
import SearchEntryComponent from "../components/searchEntry";
import ListSelect from "../components/listSelect/listSelect";

const SelectEntryScreen: FunctionComponent = () => {
  useConditionalRedirect(ENTRY_SELECT_ALLOWED_PATHS);

  const [mbr, setMbr] = useState({ ...initialMbr });
  const [mapSelection, setMapSelection] = useState<MapSelectionType>({ ...initialMapSelectionType });
	const [focusVal, setFocusVal] = useState(LIST_SELECT_TYPE_VIEW_MAIN);
  const [displayType, setDisplayType] = useState(getScreenSize());
  const [screenSize, setScreenSize] = useState(0);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [appliSelected, setAppliSelected] = useState(APPLI_SELECTED_CONTROL_PROP);
  const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
    if( !isMounted ) {
      const fetchMbr = async () => {
        try {
          const user = await makeMbr();

          setMbr(prevMbr => ({
            ...prevMbr,
            ...user
          }));
              
          testMbr(user);
        } catch(error) {
          console.error('Error', error);
        }
      };

      fetchMbr();
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setDisplayType(getScreenSize());
      setScreenSize(window.innerWidth);
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if( isMounted ) {
      storeSelection();

      if( mapSelection.entry > 0 ) {
        let cible = '';

        switch( appliSelected ) {
          case APPLI_SELECTED_CONTROL_PROP: cible = CONTROL_PROP_CONFIGURATION_URL; break;
          case APPLI_SELECTED_CONTROL_SECU:
          case APPLI_SELECTED_CONTROL_PSP:
          case APPLI_SELECTED_CONTROL_PREST:
          default: cible = '';
        }

        if( cible !== '' ) {
          history.push(cible);
        }
      }
    }
// eslint-disable-next-line
  }, [mapSelection]);

  const testMbr = async ( user: MbrType ) => {
    try {
      const response = await TestConect(user.pseudo, user.mdp, user.mac);

      if( response === false ) {
        history.push(LOGIN_URL);
      } else {
        getAppliSelected();
      }
    } catch (error) {
      console.error('Error', error);
    }
  };
  const getAppliSelected = () => {
    const dataAppli = localStorage.getItem(STORE_PARAM_APPLI_SELECTED);

    if( dataAppli !== null ) {
      setAppliSelected(dataAppli);
      fetchData();
    }
  };
  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

    if( encryptedDataCible !== null ) {
      const decryptedDataCible = JSON.parse(encryptedDataCible);

      var newSelection = { ...initialMapSelectionType };

      newSelection.agc = ( decryptedDataCible.agc === null ) ? 0 : decryptedDataCible.agc;
      newSelection.grp = ( decryptedDataCible.grp === null ) ? 0 : decryptedDataCible.grp;
      newSelection.rsd = ( decryptedDataCible.rsd === null ) ? 0 : decryptedDataCible.rsd;
      newSelection.entry = ( decryptedDataCible.entry === null ) ? 0 : decryptedDataCible.entry;

      setMapSelection(newSelection);
      setIsMounted(true);
    }
  };

  const storeSelection = () => {
    const dataCible = {
      agc: mapSelection.agc,
      grp: mapSelection.grp,
      rsd: mapSelection.rsd,
      entry: mapSelection.entry,
    };
    const jsonCible = JSON.stringify(dataCible);

    localStorage.setItem(STORE_PARAM_CIBLE, jsonCible);
  };

  const handelEntrySelected = ( idAgence: number, idGroupement: number, idResidence: number, idEntry: number ) => {
    const newMapSelection = { ...initialMapSelectionType };

    newMapSelection.agc = idAgence;
    newMapSelection.grp = idGroupement;
    newMapSelection.rsd = idResidence;
    newMapSelection.entry = idEntry;

    setMapSelection(newMapSelection);
  };
  const handelSelectAgence = ( idAgence: number ) => {
    setMapSelection(prevMap => ({
      ...prevMap,
      agc: idAgence,
			grp: 0,
			rsd: 0,
			entry: 0,
    }));
  };
  const handelSelectGroupement = ( idGroupement: number ) => {
    setMapSelection(prevMap => ({
      ...prevMap,
      grp: idGroupement,
			rsd: 0,
			entry: 0,
    }));
  };
  const handelSelectResidence = ( idResidence: number ) => {
    setMapSelection(prevMap => ({
      ...prevMap,
      rsd: idResidence,
			entry: 0,
    }));
  };
  const handelSelectEntry = ( idEntry: number ) => {
    setMapSelection(prevMap => ({
      ...prevMap,
      entry: idEntry,
    }));
  };
	const handelCloseList = () => {
		setFocusVal(LIST_SELECT_TYPE_VIEW_MAIN);
	};
	const handelCallFocus = ( val: string ) => {
		setFocusVal(val);
	};

  return (
    <Box className="select-entry-screen">
      { ( isPortrait ) &&
        <Grid2 className={ `select-entry-container portrait-view ${ displayType }-size` }
          container
          component="div"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 className="field-search"
            component="div"
            size={10}
          >
            <SearchEntryComponent
              mbr={ mbr }
              onEntrySelected={ handelEntrySelected }
            />
          </Grid2>

          <Grid2 className="field-lists"
            component="div"
            size={10}
          >
						<Grid2 className="lists-container"
							container
							component="div"
							direction="column"
							alignItems="center"
							sx={{
								height: 'calc(100vh - 200px)',
							}}
						>
							<Grid2 className="field-select-agence"
								component="div"
								size={12}
							>
								<ListSelect
									mbr={ mbr }
									type={ LIST_SELECT_TYPE_VIEW_AGENCE }
									parent={0}
									val={ mapSelection.agc }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
									onSetSelect={ handelSelectAgence }
								/>
							</Grid2>

							<Grid2 className="field-select-groupement"
								component="div"
								size={12}
							>
								<ListSelect
									mbr={ mbr }
									type={ LIST_SELECT_TYPE_VIEW_GROUPEMENT }
									parent={ mapSelection.agc }
									val={ mapSelection.grp }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
									onSetSelect={ handelSelectGroupement }
								/>
							</Grid2>

							<Grid2 className="field-select-residence"
								component="div"
								size={12}
							>
								<ListSelect
									mbr={ mbr }
									type={ LIST_SELECT_TYPE_VIEW_RESIDENCE }
									parent={ mapSelection.grp }
									val={ mapSelection.rsd }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
									onSetSelect={ handelSelectResidence }
								/>
							</Grid2>

							<Grid2 className="field-select-entry"
								component="div"
								size={12}
							>
								<ListSelect
									mbr={ mbr }
									type={ LIST_SELECT_TYPE_VIEW_ENTRY }
									parent={ mapSelection.rsd }
									val={ mapSelection.entry }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
									onSetSelect={ handelSelectEntry }
								/>
							</Grid2>
						</Grid2>
          </Grid2>

          <Grid2 className="field-vignette"
						component="div"
						size={10}
						onClick={ handelCloseList }
					>
            { ViewVignette(mbr, isPortrait) }
          </Grid2>
        </Grid2>
      }
      { ( !isPortrait ) &&
        <Grid2 className={ `select-entry-container landscape-view ${ displayType }-size` }
          container
          component="div"
          alignItems="center"
        >
          <Grid2 className="left-field"
						component="div"
						size={6}
					>
            <Grid2 className="left-field-container"
              container
              component="div"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2 className="field-search"
								component="div"
								size={12}
							>
                <SearchEntryComponent
                  mbr={ mbr }
                  onEntrySelected={ handelEntrySelected }
                />
              </Grid2>

              <Grid2 className="field-vignette"
								component="div"
								size={12}
							>
                { ViewVignette(mbr, isPortrait) }
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 className="right-field"
						component="div"
						size={6}
					>
            <Grid2 className="right-field-container"
              container
              component="div"
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2 className="field-select-agence"
								component="div"
								size={12}
							>
                <ListSelect
                  mbr={ mbr }
                  type={ LIST_SELECT_TYPE_VIEW_AGENCE }
                  parent={0}
                  val={ mapSelection.agc }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
                  onSetSelect={ handelSelectAgence }
                />
              </Grid2>

              <Grid2 className="field-select-groupement"
								component="div"
								size={12}
							>
                <ListSelect
                  mbr={ mbr }
                  type={ LIST_SELECT_TYPE_VIEW_GROUPEMENT }
                  parent={ mapSelection.agc }
                  val={ mapSelection.grp }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
                  onSetSelect={ handelSelectGroupement }
                />
              </Grid2>

              <Grid2 className="field-select-residence"
								component="div"
								size={12}
							>
                <ListSelect
                  mbr={ mbr }
                  type={ LIST_SELECT_TYPE_VIEW_RESIDENCE }
                  parent={ mapSelection.grp }
                  val={ mapSelection.rsd }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
                  onSetSelect={ handelSelectResidence }
                />
              </Grid2>

              <Grid2 className="field-select-entry"
								component="div"
								size={12}
							>
                <ListSelect
                  mbr={ mbr }
                  type={ LIST_SELECT_TYPE_VIEW_ENTRY }
                  parent={ mapSelection.rsd }
                  val={ mapSelection.entry }
									focusTo={ focusVal }
									callFocus={ handelCallFocus }
                  onSetSelect={ handelSelectEntry }
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      }
    </Box>
  );
};

const ViewVignette = (
  mbrView: MbrType,
  isPortrait: boolean,
) => {
  return (
    <Grid2 className="view-vignette"
      container
      component="div"
      direction="column"
      justifyContent={ ( isPortrait ) ? 'flex-end' : 'space-evenly' }
      alignItems="center"
      sx={ ( isPortrait ) ? {} : { height: '100%' } }
    >
      <Grid2 className="logo-appli-container" component="div" size={2}>
        <img src={ goutte } alt="vignette" className="goutte"/>
      </Grid2>

      <Grid2 className="logo-appli-container" component="div" size={8}>
        <img src={ logo } alt="controlprop" className="logo"/>
      </Grid2>
    </Grid2>
  );
};

export default SelectEntryScreen;
