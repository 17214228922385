
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Grid2, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import './style/planActionsMobile.css';

import icon from "../../assets/logo_prop.png";

import { LOGIN_URL, PLAN_ACTIONS_ALLOWED_PATHS } from "../../models/consts/aplliUrls";
import { STAT_ENCART_TYPE_CONTROLEUR } from "../../models/consts/statistiques";
import { initialMbr, MbrType } from "../../models/mbr";
import { convertToPlanActionsType, initialPlanActions, LinePlanActionsStatType, PlanActionsStatType } from "../../models/statistiques";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import getScreenSize from "../../helpers/getScreenSize";
import makeMbr from "../../helpers/make-mbr";
import TestConect from "../../helpers/test-conect";
import { fetchGetStatPlanActions } from "../../services/getStatistiques";
import { useNavigation } from "../../components/navigationContext";
import { useNotification } from "../../components/notify";
import LoadingMessage from "../../components/balises/buttons/loadingMessage";
import InputComponent from "../../components/balises/input";

const PlanActionsScreen: FunctionComponent = () => {
	useConditionalRedirect(PLAN_ACTIONS_ALLOWED_PATHS);

	const notify = useNotification();
	
	const [mbr, setMbr] = useState({ ...initialMbr });
	const [data, setData] = useState({ ...initialPlanActions });
	const [displayType, setDisplayType] = useState(getScreenSize());
	const [screenSize, setScreenSize] = useState(0);
	const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
	const [isMounted, setIsMounted] = useState(false);
	const [message, setMessage] = useState('Patientez');
	const [showMessage, setShowMessage] = useState(true);
	const [erreur, setErreur] = useState(false);

	const { setPreviousPath } = useNavigation();
	const history = useHistory();

	useEffect(() => {
		setPreviousPath(history.location.pathname);
	}, [history.location.pathname, setPreviousPath]);

	useEffect(() => {
		if( !isMounted ) {
			document.title = 'PlansActions';

			const fetchMbr = async () => {
				try {
					const user = await makeMbr();

					setMbr(prevMbr => ({
						...prevMbr,
						...user
					}));
              
					testMbr(user);
				} catch(error) {
					console.error('Error', error);
				}
			};

			fetchMbr();
		}
// eslint-disable-next-line
	}, []);
	useEffect(() => {
		const handleResize = () => {
			setDisplayType(getScreenSize());
			setScreenSize(window.innerWidth);
			setIsPortrait(window.innerHeight > window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const testMbr = async ( user: MbrType ) => {
		try {
			const response = await TestConect(user.pseudo, user.mdp, user.mac);

			if( response === false ) {
				history.push(LOGIN_URL);
			} else {
				fetchData(user);
			}
		} catch (error) {
			console.error('Error', error);
		}
	};
	const fetchData = async ( user: MbrType ) => {
		try {
			const param = STAT_ENCART_TYPE_CONTROLEUR + ':' + user.id;
			const response = await fetchGetStatPlanActions(user.id, user.mac, param);
		
			if( response.result ) {
				var newPlans = convertToPlanActionsType(response.data);
		
				setData(newPlans);
				setIsMounted(true);
				setShowMessage(false);
			} else {
				console.error(response.data.txt);

				notify(response.data.txt, { variant: 'error' });
				setMessage(response.data.txt);
				setErreur(true);
				setShowMessage(true);
			}
		} catch(error) {
			console.error(error);

			setMessage("Une erreur s'est produite lors du traitement des données");
			setErreur(true);
			setShowMessage(true);
		}
	};

	const handelKeyPressed = ( caracter: string, val: string | number | null ) => {

	};
	const handelPlanSelected = () => {

	}

	return (
		<Box className="plan-actions-mobile-screen"
			sx={{
				height: '100%',
			}}
		>
			{ ( isPortrait ) &&
				<Grid2 className={ `plan-actions-container portrait-view ${ screenSize }-size` }
					container
					component="div"
					direction="column"
					justifyContent="flex-start"
          alignItems="center"
					sx={{
						height: '100%',
					}}
				>
					<Grid2 className="field-icon"
						component="div"
						size={2}
						offset={10}
            sx={{
              padding: '20px 20px 0 0',
            }}
					>
						<img src={ icon } alt="M" className="icon"/>
					</Grid2>

					<Grid2 className="field-resum"
						component="div"
						size={10}
						sx={{
							marginTop: '1em',
						}}
					>
						{ ViewResum(data.nb) }
					</Grid2>

					<Grid2 className="views-container"
            component="div"
						size={12}
            sx={{
              height: 'calc(100% - 150px)',
              width: '100%',
              overflow: 'hidden',
              marginTop: ( showMessage ) ? '20px' : '10px',
            }}
					>
						{ ( showMessage ) ?
                (
                  ( !erreur ) ?
                    <Box className="cont-loading-message"
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                      }}
                    >
                      <LoadingMessage/>
                    </Box>
                  :
                    <Box className="cont-message-return"
                      sx={{
                        textAlign: 'center',
                        marginTop: '5em',
                        fontWeight: 'bold',
                        color: '#45b40b',
                      }}
                    >
                      { message }
                    </Box>
                )
              :
								<Grid2 className="field-result"
									container
                  component="div"
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
									sx={{
										height: '100%',
									}}
								>
									<Grid2 className="field-search"
										component="div"
										size={12}
										sx={{
											padding: '10px',
										}}
									>
										<InputComponent
                      name="search"
                      label=""
                      value=""
                      type="search"
                      error={ false }
                      onKeyPressed={ handelKeyPressed }
                    />
									</Grid2>

									<Grid2 className="field-list"
										component="div"
										size={12}
										sx={{
											padding: '10px',
											height: 'calc(100% - 80px)',
										}}
									>
										{ ViewListPlansActions(data, handelPlanSelected) }
									</Grid2>
								</Grid2>
						}
					</Grid2>
				</Grid2>
			}
			{ ( !isPortrait ) &&
				<Grid2></Grid2>
			}
		</Box>
	);
};



/* VIEWS */
const ViewResum = (
  nb: number,
) => {
  return (
    <Box className="field-objectifs"
      sx={{
        border: '2px solid #45b40b',
        color: '#45b40b',
        borderRadius: '10px',
        width: '100%',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Typography className="objectifs-title"
        sx={{
          position: 'absolute',
          top: '-15px',
          left: '20px',
          backgroundColor: 'white',
          padding: '0 5px',
        }}
      >
        Mes plans d'actions en cours
      </Typography>

      <Typography className="objectifs-value"
        sx={{
          fontWeight: 'bold',
          fontSize: '2rem',
        }}
      >
        { nb.toString() }
      </Typography>
    </Box>
  )
};
const ViewListPlansActions = (
	data: PlanActionsStatType,
	onPlanActionSelected: ( plan: LinePlanActionsStatType ) => void,
) => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				overflowX: 'hidden',
				overflowY: 'scroll',
			}}
		>
			<TableContainer className="tbl-entete-container"
				sx={{
					height: '100%'
				}}
			>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell align="left" style={ { width: '50%' } }>Résidence</TableCell>
							<TableCell align="center" style={ { width: '25%' } }>Date</TableCell>
							<TableCell align="center" style={ { width: '25%' } }>Échéance</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{ ( data !== null ) && data.data.map(( donnee ) => {
							return ViewLinePlan(donnee, onPlanActionSelected)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
};
const ViewLinePlan = (
	data: LinePlanActionsStatType,
	onClickLine: ( plan: LinePlanActionsStatType ) => void,
) => {
	return (
		<TableRow key={data.id} onClick={ e => onClickLine(data) }>
			<TableCell align="left" style={{  fontSize: '8pt', }}>{ data.rsd.split('-- ')[1] }</TableCell>
			<TableCell align="center" style={{  fontSize: '8pt', }}>{ data.date }</TableCell>
			<TableCell align="center" style={{  fontSize: '8pt', }}>{ data.echeance }</TableCell>
		</TableRow>
	)
};

export default PlanActionsScreen;
