
/*	PROD	*/

export type ReceveidAdresseType = {
  adr: string,
  cp: string,
  ville: string,
};

export type AdresseType = {
  adr: string,
  cp: string,
  ville: string,
};

export const initialAdresse : AdresseType = {
  adr: '',
  cp: '',
  ville: '',
};

export const convertToAdresseType = ( data: ReceveidAdresseType ): AdresseType => {
  return {
    adr: data.adr,
    cp: data.cp,
    ville: data.ville,
  };
};
export const convertAdresseTypeToJson = ( data: AdresseType ) => {
  return {
    adr: data.adr,
    cp: data.cp,
    ville: data.ville,
  };
};
