
/*	PROD	*/

import React, { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";

import 'react-tooltip/dist/react-tooltip.css';

import { EntryType } from "../../../models/entry";

interface BtnEntryProps {
  entry: EntryType;
  onClicked: ( entry: EntryType ) => void;
};

const BtnEntry: FunctionComponent<BtnEntryProps> = ( { entry, onClicked } ) => {
  return (
    <Box className="btn-entry-component"
      sx={{
        textAlign: 'left',
        border: '2px solid #45b40b',
        borderRadius: '5px',
        padding: '5px',
        width: '100%',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
				cursor: "pointer",
      }}
      onClick={ e => onClicked(entry) }
    >
      <Typography className="ref-entry" sx={{ display: 'inline-block', verticalAlign: 'top', fontWeight: 'bold' }}>
        { entry.ref }
      </Typography>

      <Typography className="nom-entry" sx={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '8pt', marginLeft: '8px', }}>
        { entry.txt }
      </Typography>

      <Typography className="entree-entry" sx={{ fontSize: '8pt' }}>
        { `Entrée: ${entry.entry}` }
      </Typography>

      <Typography className="adr-btn-entry" sx={{ fontSize: '8pt' }}>
        { `${ entry.adresse.adr } ${ entry.adresse.ville }` }
      </Typography>
    </Box>
  )
};

export default BtnEntry;
