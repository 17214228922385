
/*	PROD	*/

import React, { FunctionComponent, useState, useEffect } from "react";
import { Box, Button, Grid2 } from "@mui/material";
import { useHistory } from "react-router-dom";

import './style/configurationMobile.css';

import goutte from '../../assets/logo_prop.png';
import logo from '../../assets/controlprop.png';

import { CONFIGURATION_CONTROL_PROP_ALLOWED_PATHS, CONTROL_PROP_GRILLE_URL, LOGIN_URL, SELECT_ENTRY_URL } from "../../models/consts/aplliUrls";
import { initialMbr, MbrType } from "../../models/mbr";
import { STORE_PARAM_CIBLE, STORE_PARAM_LIST_SAVES_PROP } from "../../models/consts/store";
import { ConfigPropType, convertGrillePropTypeToJson, GrillePropType, initialConfigProp, initialGrilleProp } from "../../models/ctrlProp";
import { CONFIG_PROP_TYPE_CTRL } from "../../models/consts/ctrlProp";
import useConditionalRedirect from "../../helpers/useConditionalRedirect";
import TestConect from "../../helpers/test-conect";
import getScreenSize from "../../helpers/getScreenSize";
import makeMbr from "../../helpers/make-mbr";
import { useNavigation } from "../../components/navigationContext";
import ConfigurationPropComponent from "../../components/grilleControlProp/configProp/configuration";
import TypageCtrlComponent from "../../components/grilleControlProp/configProp/typageCtrl";

const ConfigurationControlProp: FunctionComponent = () => {
  useConditionalRedirect(CONFIGURATION_CONTROL_PROP_ALLOWED_PATHS);

  const [mbr, setMbr] = useState({ ...initialMbr });
	const [grille, setGrille] = useState<GrillePropType>({ ...initialGrilleProp, grille: [...initialGrilleProp.grille] });
	const [configCtrl, setConfigCtrl] = useState({ ...initialConfigProp });
	const [typeCtrl, setTypeCtrl] = useState('');
	const [versionCtrl, setVersionCtrl] = useState('');
	const [btnIsActif, setBtnIsActif] = useState(false);
  const [displayType, setDisplayType] = useState(getScreenSize());
  const [screenSize, setScreenSize] = useState(0);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);

  useEffect(() => {
    if( !isMounted ) {
			document.title = "CONTROLprop - Configuration";

      fetchMbr();
    }
// eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setDisplayType(getScreenSize());
      setScreenSize(window.innerWidth);
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

	const handelConfigChange = ( conf: ConfigPropType ) => {
		setConfigCtrl(conf);

		openButtons();
	};
	const handelTypageChanged = ( type: string, version: string ) => {
		setTypeCtrl(type);
		setVersionCtrl(version);

		openButtons(type, version);
	};

	const handelStart = () => {
		if( btnIsActif && ( grille.rsd !== undefined ) ) {
			const newConfig = { ...initialConfigProp };

			newConfig.visite = configCtrl.visite;
			newConfig.meteo = configCtrl.meteo;
			newConfig.affichage = configCtrl.affichage;
			newConfig.produits = configCtrl.produits;

			const newGrille = { ...grille };

			newGrille.config = newConfig;
			newGrille.type = typeCtrl;
			newGrille.version = versionCtrl;

			const jsonGrille = convertGrillePropTypeToJson(newGrille, mbr);
			const strGrille = JSON.stringify(jsonGrille);

			let m = 'prop_' + grille.rsd.toString();

			localStorage.setItem(m, strGrille);

			let list = localStorage.getItem(STORE_PARAM_LIST_SAVES_PROP);

			if( list === null ) {
				localStorage.setItem(STORE_PARAM_LIST_SAVES_PROP, grille.rsd.toString());
			} else {
				let t = list.split(';');

				if( !( t.indexOf(grille.rsd.toString()) >= 0 ) ) {
					if( list !== '' ) list += ';';
					list += grille.rsd.toString();
				}

				localStorage.setItem(STORE_PARAM_LIST_SAVES_PROP, list);
			}

			history.push(CONTROL_PROP_GRILLE_URL);
		}
	};

	const fetchMbr = async () => {
		try {
			const user = await makeMbr();

			setMbr(prevMbr => ({
				...prevMbr,
				...user
			}));
					
			testMbr(user);
		} catch(error) {
			console.error('Error', error);
		}
	};
  const testMbr = async ( user: MbrType ) => {
    try {
      const response = await TestConect(user.pseudo, user.mdp, user.mac);

      if( response === false ) {
        history.push(LOGIN_URL);
      } else {
        fetchData();
      }
    } catch (error) {
      console.error('Error', error);
    }
  };
  const fetchData = async () => {
    try{
      const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

      if( encryptedDataCible !== null ) {
        const decryptedDataCible = JSON.parse(encryptedDataCible);

				if( ( decryptedDataCible.entry !== null ) && ( decryptedDataCible.entry > 0 ) ) {
					setGrille(prevState => ({
						...prevState,
						rsd: decryptedDataCible.entry,
					}));

					let m = 'prop_' + decryptedDataCible.entry.toString();

					const encryptedGrille = localStorage.getItem(m);

					if( encryptedGrille !== null ) {
						const jsonGrille = JSON.parse(encryptedGrille);

						setConfigCtrl(prevState => ({
							...prevState,
							visite: jsonGrille.config.visite,
							meteo: jsonGrille.config.meteo,
							affichage: jsonGrille.config.affichage,
							produits: jsonGrille.config.produits,
						}));
						setTypeCtrl(jsonGrille.type);
						setVersionCtrl(jsonGrille.version);
					}

					setIsMounted(true);
				} else {
					history.push(SELECT_ENTRY_URL);
				}
      }
    } catch(error) {
      console.error(error);
    }
  };

	const openButtons = ( type?: string, version?: string ) => {
		const typeTest = ( type === undefined ) ? typeCtrl : type;
		const versionTest = ( version === undefined ) ? versionCtrl : version;

		if( typeTest === '' ) {
			setBtnIsActif(false);
		} else {
			if( typeTest === CONFIG_PROP_TYPE_CTRL ) {
				if( versionTest === '' ) {
					setBtnIsActif(false);
				} else {
					setBtnIsActif(true);
				}
			} else {
				setBtnIsActif(true);
			}
		}
	};

  return (
    <Box className="config-prop-screen">
      <Grid2 className="config-prop-container"
				container
				component="div"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					height: '100%',
				}}
			>
				<Grid2 className="field-icone"
					component="div"
					size={2}
				>
					<img src={ goutte } alt="icone"/>
				</Grid2>

				<Grid2 className="field-config"
					component="div"
					size={12}
				>
					<ConfigurationPropComponent
						config={ configCtrl }
						onConfigCtrlChange={ handelConfigChange }
					/>
				</Grid2>
				
				<Grid2 className="field-type"
					component="div"
					size={12}
				>
					<TypageCtrlComponent
						curentType={ typeCtrl }
						curentVersion={ versionCtrl }
						onChangeTypage={ handelTypageChanged }
					/>
				</Grid2>

				<Grid2 className="field-btn"
					component="div"
					size={12}
					sx={{
						textAlign: 'center',
					}}
				>
					<Button
						variant={ ( btnIsActif ) ? 'contained' : 'outlined' }
						onClick={ handelStart }
					>
						Commencer
					</Button>
				</Grid2>
				
				<Grid2 className="field-vignette"
					component="div"
					size={8}
				>
					<img src={ logo } alt="vignette"/>
				</Grid2>
			</Grid2>
    </Box>
  );
};

export default ConfigurationControlProp;
